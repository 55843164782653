import { KeyIndicatorColor } from "react-migration/lib/map/useKeyIndicatorTags";
import { Belts } from "./types";

export function getGreenBeltIndicator(belt: Belts | null) {
  if (!belt) {
    return [];
  }

  const { greenBeltPercentage, greyBeltPercentage, declassifiedGreenBeltPercentage } = belt;

  if (declassifiedGreenBeltPercentage > 5 || greyBeltPercentage > 5) {
    return [
      {
        tag: `Opportunity`,
        color: KeyIndicatorColor.GREEN,
      },
    ];
  }

  if (greenBeltPercentage > 0 && greenBeltPercentage < 25) {
    return [
      {
        tag: `${greenBeltPercentage}% Green Belt`,
        color: KeyIndicatorColor.ORANGE,
      },
    ];
  }

  if (greenBeltPercentage > 0) {
    return [
      {
        tag: `${greenBeltPercentage}% Green Belt`,
        color: KeyIndicatorColor.RED,
      },
    ];
  }

  return [
    {
      tag: `Not green belt`,
      color: KeyIndicatorColor.LIGHT_GRAY,
    },
  ];
}
