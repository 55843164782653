import { isFinite } from "react-migration/lib/util/isDefined";

const travelTimeBands = [
  { display: "0-5m", min: 0, max: 5 },
  { display: "5-10m", min: 5, max: 10 },
  { display: "10-15m", min: 10, max: 15 },
  { display: "15-30m", min: 15, max: 30 },
  { display: "30-45m", min: 30, max: 45 },
  { display: "45-60m", min: 45, max: 60 },
  { display: "60-90m", min: 60, max: 90 },
  { display: "90m+", min: 90, max: Infinity },
];

export function travelTimeDisplay(time: number | null) {
  if (!isFinite(time)) {
    return "-";
  }

  const band = travelTimeBands.find(({ min, max }) => time >= min && time < max);

  return band!.display;
}
