import { SkeletonLoading } from "react-migration/components/SkeletonLoading";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { useSelectionSidebarLoading } from "../../Bundle/useSelectionSidebarLoadingState";
import { LayerTypeLayerSummaryProps, SupportedRegions } from "../../types";
import { Alert } from "react-migration/components/Alert";
import { useDesignationAreaByGeometry } from "react-migration/domains/constraints/designation/hooks/useDesignationAreaByGeometry";
import {
  KeyIndicatorColor,
  useKeyIndicatorTags,
} from "react-migration/lib/map/useKeyIndicatorTags";
import { DesignationAreaChart } from "react-migration/domains/constraints/designation/components/DesignationAreaChart";
import { HazardFloodingLayerSummarySection } from "./HazardFloodingLayerSummarySection";
import { useMemo } from "react";
import turfArea from "@turf/area";

const floodZoneSubCategories = {
  "High Risk Coastal": [
    "us_flood_zones_high_risk_coastal_1_percent_annual_chance_flood_hazard",
    "us_flood_zones_high_risk_coastal_regulatory_floodway",
  ],
  "High Risk": [
    "us_flood_zones_high_risk_1_percent_annual_chance_flood_hazard",
    "us_flood_zones_high_risk_regulatory_floodway",
    "us_flood_zones_high_risk_special_floodway",
  ],
  "Moderate Risk": ["us_flood_zones_moderate_risk_0_2_percent_annual_chance_flood_hazard"],
  "Minimal Risk": [
    "us_flood_zones_minimal_risk_future_conditions_1_percent_annual_chance_flood_hazard",
    "us_flood_zones_minimal_risk_area_with_reduced_risk_due_to_levee",
  ],
  "Unknown Risk": [
    "us_flood_zones_unknown_risk_area_with_risk_due_to_levee",
    "us_flood_zones_unknown_risk_area_of_undetermined_flood_hazard",
    "us_flood_zones_other",
  ],
};

const allFloodZoneCategories = Object.values(floodZoneSubCategories).flat();

export function HazardFloodingLayerSummary({
  selection,
  onLoadingChange,
}: LayerTypeLayerSummaryProps) {
  const { t } = useTranslation();

  const { data, loading, error } = useDesignationAreaByGeometry(
    selection?.feature?.geometry,
    allFloodZoneCategories
  );

  const unaffectedArea = useMemo(() => {
    const geometry = selection?.feature?.geometry;
    if (!geometry || !data) return;
    const totalArea = turfArea(geometry);
    const accumulativeArea = data.reduce(
      (acc, { category_coverage_area }) => acc + category_coverage_area,
      0
    );
    return totalArea - accumulativeArea;
  }, [data, selection?.feature?.geometry]);

  const unaffectedAreaPercentage = useMemo(() => {
    const accumulativePercentage =
      data?.reduce(
        (acc, { category_coverage_percentage }) => acc + category_coverage_percentage,
        0
      ) ?? 0;
    return 100 - accumulativePercentage;
  }, [data]);

  useKeyIndicatorTags(() => {
    if (!data) {
      return [];
    }

    if (!data.length) {
      return [{ tag: "No risk", color: KeyIndicatorColor.GREEN }];
    }

    const map: Record<string, KeyIndicatorColor> = {
      "High Risk Coastal": KeyIndicatorColor.RED,
      "High Risk": KeyIndicatorColor.RED,
      "Moderate Risk": KeyIndicatorColor.ORANGE,
      "Minimal Risk": KeyIndicatorColor.DEFAULT,
      "Unknown Risk": KeyIndicatorColor.DEFAULT,
    };

    let indicatorKey = "Unknown Risk";
    let percentageAffected = 100;
    if (data[0]) {
      for (const [key, value] of Object.entries(floodZoneSubCategories)) {
        if (value.includes(data[0].sub_category)) {
          indicatorKey = key;
          percentageAffected = Math.round(data[0].category_coverage_percentage);
          break;
        }
      }
    }

    return [
      {
        tag: percentageAffected + "% " + indicatorKey,
        color: map[indicatorKey] ?? KeyIndicatorColor.LIGHT_GRAY,
      },
    ];
  }, [data]);

  useSelectionSidebarLoading(onLoadingChange, loading);

  if (loading) {
    return (
      <div className="atlas-relative atlas-p-2 atlas-h-[168px]">
        <SkeletonLoading rows={2} showPadding={false} bgClassName="atlas-bg-transparent" />
      </div>
    );
  }

  if (error) {
    // caught by error boundary
    throw error;
  }

  if (!data?.length) {
    return <Alert.Info>Selection is not impacted by risk of flooding</Alert.Info>;
  }

  return (
    <div className="atlas-flex atlas-flex-col atlas-gap-y-4">
      <DesignationAreaChart
        areas={data}
        heading={t("workbench.summary.flooding.flood_zone_heading")}
        useDesignationDisplayNames
      />
      <div className="atlas-grid atlas-grid-cols-2 atlas-gap-2">
        {data[0] && <HazardFloodingLayerSummarySection designationArea={data[0]} />}
        <HazardFloodingLayerSummarySection
          designationArea={{
            category_coverage_percentage: unaffectedAreaPercentage,
            category_coverage_area: unaffectedArea,
          }}
        />
      </div>
    </div>
  );
}

HazardFloodingLayerSummary.icon = "icon-lt-drop-line";
HazardFloodingLayerSummary.supportedRegions = [SupportedRegions.us];
