interface LabelProps extends React.HTMLProps<HTMLLabelElement> {
  isRequired?: boolean;
  showOptionalText?: boolean;
}

export function Label({ isRequired, showOptionalText, children, ...props }: LabelProps) {
  return (
    <label {...props} className="atlas-flex atlas-font-normal atlas-text-sm atlas-gap-1">
      {children}
      {!isRequired && showOptionalText && (
        <span className="atlas-text-blueGrey-400">(optional)</span>
      )}
      {isRequired && <span className="atlas-text-red-600">*</span>}
    </label>
  );
}
