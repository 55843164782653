import { HTMLProps } from "react";
import { Icon } from "react-migration/components/Icon";
import { InsightStatusType } from "react-migration/components/Insight/components/Body/Body";
import { Insight } from "react-migration/components/Insight/Insight";
import { DecisionTimeTooltip } from "./utils/tooltips/DecisionTimeTooltip";

type PlanningAuthorityApprovalRateSummarySectionProps = HTMLProps<HTMLDivElement> & {
  heading: string;
  status?: InsightStatusType;
  footerDescription: string;
  footerValue: string;
  footerArrowUp?: boolean;
  tooltipKey?: string;
};

export function PlanningAuthorityApprovalRateSummarySection({
  heading,
  status,
  footerDescription,
  footerValue,
  footerArrowUp,
  tooltipKey,
  children,
}: PlanningAuthorityApprovalRateSummarySectionProps) {
  return (
    <Insight.Wrapper
      title={
        <span className="atlas-flex atlas-gap-0.5">
          {heading}
          {tooltipKey && <DecisionTimeTooltip translationKey={tooltipKey} />}
        </span>
      }
    >
      <Insight.Body status={status}>{children}</Insight.Body>
      <Insight.Footer description={footerDescription}>
        {footerValue}
        {footerArrowUp === true && (
          <Icon
            size="xs"
            icon="icon-lt-arrow-up-line"
            extraClasses="atlas-text-text-accent-green"
          />
        )}
        {footerArrowUp === false && (
          <Icon
            size="xs"
            icon="icon-lt-arrow-down-line"
            extraClasses="atlas-text-text-accent-red"
          />
        )}
      </Insight.Footer>
    </Insight.Wrapper>
  );
}
