import Feature from "src/js/stores/user/Feature";
import {
  ComparablesLayerSummaryType,
  ComparablesLayerType,
  LocalMarketValueSummaryLayerType,
} from "../layer_types/ComparablesLayerType";
import { Layer } from "../types";

export const COMPARABLES_LAYER: Layer = {
  id: "COMPARABLES",
  layerType: ComparablesLayerType,
  title: "Comparables",
  requiredFeatureAccess: [Feature.pricepaidLayer],
  toggleable: true,
  description: "Explore sales prices",
};

export const COMPARABLES_LAYER_SUMMARY_UK: Layer = {
  id: "COMPARABLES_SUMMARY",
  layerType: ComparablesLayerSummaryType,
  title: "Latest transaction",
  requiredFeatureAccess: [Feature.insightsSummary, Feature.pricepaidLayer],
  requiredBetaAccess: [Feature.insightsSummaryBeta],
  toggleable: true,
  description: "The latest transaction in the selected area",
  hideByDefault: true,
};

export const LOCAL_MARKET_VALUE_LAYER_SUMMARY_UK: Layer = {
  id: "LOCAL_MARKET_VALUE_SUMMARY",
  layerType: LocalMarketValueSummaryLayerType,
  title: "Local market value",
  requiredFeatureAccess: [Feature.insightsSummary, Feature.comparablesCalculator], // Feature tied to comparable calculator
  requiredBetaAccess: [Feature.insightsSummaryBeta],
  toggleable: true,
  description: "The latest transaction in the selected area",
  hideByDefault: true,
};

export const COMPARABLES_LAYER_SUMMARY_US: Layer = {
  id: "COMPARABLES_SUMMARY",
  layerType: ComparablesLayerSummaryType,
  title: "Latest transaction",
  requiredFeatureAccess: [Feature.insightsSummary],
  requiredBetaAccess: [Feature.usInsightsBeta],
  toggleable: true,
  description: "The latest transaction in the selected area",
  hideByDefault: true,
};
