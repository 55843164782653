import { ReactNode, useMemo } from "react";
import { Sparkline, SparklineCategory } from "react-migration/components/Sparkline/Sparkline";
import { Tooltip } from "react-migration/components/Tooltip";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { Theme } from "react-migration/lib/theme/Theme";
import { DesignationArea } from "react-migration/domains/constraints/designation/hooks/useDesignationAreaByGeometry";
import { getCategoryFillColour } from "react-migration/domains/constraints/designation/style/accessors";

interface DesignationAreaChartProps {
  heading: string;
  areas?: DesignationArea[];
  noneLabel?: string;
  tooltip?: string | ReactNode;
  useDesignationDisplayNames?: boolean;
}

export function DesignationAreaChart({
  heading,
  areas,
  noneLabel,
  tooltip,
  useDesignationDisplayNames = false,
}: DesignationAreaChartProps) {
  const translation = useTranslation();
  const categories = useMemo(
    () =>
      areas
        ?.slice(0)
        .reverse()
        .map((area) =>
          designationAreaToSparklineCategory(area, translation, { useDesignationDisplayNames })
        ) || [],
    [areas, translation, useDesignationDisplayNames]
  );

  return (
    <section className="atlas-flex atlas-flex-col atlas-gap-y-2">
      <div className="atlas-flex atlas-items-center atlas-gap-x-1">
        <h1 className="atlas-text-sm atlas-font-medium atlas-text-text-base-primary">{heading}</h1>
        {tooltip && (
          <Tooltip.Wrapper>
            <Tooltip.Content size="sm" side="bottom" align="end" theme={Theme.Light}>
              {typeof tooltip === "string" ? (
                <Tooltip.Description>{tooltip}</Tooltip.Description>
              ) : (
                tooltip
              )}
            </Tooltip.Content>
            <Tooltip.Trigger>
              <i className="icon-lt-information-line print:atlas-hidden atlas-text-text-base-secondary-a" />
            </Tooltip.Trigger>
          </Tooltip.Wrapper>
        )}
      </div>
      <Sparkline showLegend categories={categories} noneLabel={noneLabel} />
    </section>
  );
}

function getCategoryFillColourRgb(sub_category: string) {
  return `rgb(${getCategoryFillColour(sub_category).slice(0, 3).join(",")})`;
}

interface DesignationAreaTpSparklineCategoryOptions {
  useDesignationDisplayNames: boolean;
}

function designationAreaToSparklineCategory(
  {
    sub_category,
    display_name,
    designation_display_name,
    category_coverage_percentage,
  }: DesignationArea,
  { t, checkKey }: ReturnType<typeof useTranslation>,
  { useDesignationDisplayNames }: DesignationAreaTpSparklineCategoryOptions
): SparklineCategory {
  const translationKey = `workbench.summary.flooding.risk_level.${sub_category}`;

  let name = checkKey(translationKey) ? t(translationKey) : display_name;

  if (useDesignationDisplayNames && designation_display_name) {
    name = designation_display_name;
  }

  return {
    categoryKey: sub_category,
    name,
    percentage: category_coverage_percentage,
    color: getCategoryFillColourRgb(sub_category),
  };
}
