import { Tooltip } from "react-migration/components/Tooltip";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { Theme } from "react-migration/lib/theme/Theme";

export const DecisionTimeTooltip = ({ translationKey }: { translationKey: string }) => {
  const { t } = useTranslation();

  return (
    <Tooltip.Wrapper>
      <Tooltip.Content size="sm" side="top" align="center" theme={Theme.Light}>
        <Tooltip.Description>{t(translationKey)}</Tooltip.Description>
      </Tooltip.Content>
      <Tooltip.Trigger>
        <i className="icon-lt-information-line atlas-text-text-base-secondary-a print:atlas-hidden" />
      </Tooltip.Trigger>
    </Tooltip.Wrapper>
  );
};
