import React, { PropsWithChildren, useMemo, useState } from "react";
import { useLocalStorage } from "usehooks-ts";
import clsx from "clsx";
import { CountPill } from "react-migration/components/CountPill";
import { CardTitle } from "react-migration/components/CardTitle";
import { logEventV2 } from "react-migration/lib/util/logEventV2";
import { Button } from "react-migration/components/Button";
import { Icon } from "react-migration/components/Icon";
import { Tags } from "react-migration/components/Tags";
import {
  KeyIndicatorTagsProvider,
  useKeyIndicatorContext,
} from "react-migration/lib/map/useKeyIndicatorTags";
import { Notification } from "react-migration/components/Notification";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorMessage } from "./ErrorMessage";

type CollapsibleConsiderationsCardProps = PropsWithChildren<{
  heading: string | React.ReactNode;
  buttons?: React.ReactNode;
  name: string;
  defaultExpanded?: boolean;
  label?: string;
  count?: number;
  contentPadding?: 2 | 4;
}>;

export function CollapsibleConsiderationsCard(props: CollapsibleConsiderationsCardProps) {
  return (
    <KeyIndicatorTagsProvider>
      <CollapsibleConsiderationsCardInner {...props} />
    </KeyIndicatorTagsProvider>
  );
}

export function CollapsibleConsiderationsCardInner({
  heading,
  buttons,
  name,
  children,
  count,
  label,
  contentPadding,
  defaultExpanded = true,
}: CollapsibleConsiderationsCardProps) {
  const { tags } = useKeyIndicatorContext();

  const tagsToDisplay = useMemo(() => {
    if (!label) return tags;
    return [...tags, { tag: label }];
  }, [tags, label]);

  const [expandedCards, setExpandedCards] = useLocalStorage<Record<string, boolean>>(
    "expandedAssessmentCards",
    {}
  );

  const [expanded, setExpanded] = useState(
    typeof expandedCards[name] != "undefined" ? expandedCards[name] : defaultExpanded
  );

  const toggleCard = () => {
    setExpanded((oldState) => !oldState);
    logEventV2({
      name: "Workbench card expanded",
      properties: { state: !expanded, cardName: name },
    });
    setExpandedCards({
      ...expandedCards,
      [name]: !expanded,
    });
  };

  return (
    <section
      data-testid={`collapsible-considerations-card${name && "-" + name.toLowerCase()}`}
      className="collapsible-considerations-card atlas-bg-white atlas-overflow-hidden atlas-rounded-lg atlas-border atlas-border-neutral-300"
    >
      <div
        data-testid="collapsible-considerations-card-header"
        className="atlas-w-full atlas-flex atlas-justify-between atlas-items-center atlas-px-2 atlas-py-1"
        onClick={() => toggleCard()}
      >
        <div className="atlas-flex atlas-gap-1 atlas-items-center">
          {typeof heading !== "string" ? (
            heading
          ) : (
            <>
              <CardTitle>{heading}</CardTitle>
              <CountPill count={count} />
            </>
          )}
        </div>
        <div className="atlas-flex atlas-flex-row atlas-gap-1">
          <Tags tags={tagsToDisplay} size="sm" placeholder="" />
          {buttons}
          <Button
            variant="ghost"
            title={expanded ? "Collapse" : "Expand"}
            className="atlas-w-6 atlas-h-6 atlas-text-text-base-primary"
          >
            <Icon
              size="sm"
              icon="icon-lt-arrow-down-s-line"
              cursor="pointer"
              extraClasses={clsx("atlas-transition-transform", { "atlas-rotate-180": expanded })}
            />
          </Button>
        </div>
      </div>

      {expanded && (
        <div
          className={clsx({
            "atlas-p-2": contentPadding === 2,
            "atlas-p-4": contentPadding === 4,
          })}
        >
          <ErrorBoundary
            fallback={
              <Notification.Error
                title={"There was an error loading this data."}
                message={<ErrorMessage />}
              />
            }
          >
            {children}
            <div className="atlas-clear-both" />
          </ErrorBoundary>
        </div>
      )}
    </section>
  );
}
