export type HousingCategory = "detached" | "semi_detached" | "terraced" | "flat";

export const HOUSING_COLOURS: Record<HousingCategory, string> = {
  detached: "#0F41BE",
  semi_detached: "#146AFF",
  terraced: "#50ACFF",
  flat: "#B9DEFF",
};

// "Housing stock by type" data stored against demographics designations at the
// LAD (Local Aea District) level
export const HOUSING_STOCK_DESIGNATION_CATEGORY_ID = "uk_demographics_lad";

export const HOUSING_STOCK_ATTRIBUTE_KEYS_BY_TYPE: Record<HousingCategory, string> = {
  detached: "acc_detached_pc",
  semi_detached: "acc_semi_detached_pc",
  terraced: "acc_terraced_pc",
  flat: "acc_block_of_flats_pc",

  // Following attributes bundled into "other" category on FE, so not fetched:
  // "acc_converted_or_shared_house_pc",
  // "acc_converted_other_pc",
  // "acc_commercial_building_pc",
  // "acc_mobile_or_temp_pc",
};

// Market value data stored against the "market_value" category at the same LAD
// level, but have their own geometries etc.
export const MARKET_VALUE_CATEGORY_ID = "market_value";
export const MARKET_VALUE_LOCAL_AREA_DISTRICT_ATTRIBUTE_KEYS = ["lad23cd", "lad23nm"];

export const MARKET_VALUE_PPSQM_KEYS_BY_TYPE: Record<
  HousingCategory,
  { pricePsqm: string; lpaPricePsqm: string }
> = {
  detached: {
    pricePsqm: "detached_gbp_psqm",
    lpaPricePsqm: "lad_detached_gbp_psqm",
  },
  semi_detached: {
    pricePsqm: "semi_detached_gbp_psqm",
    lpaPricePsqm: "lad_semi_detached_gbp_psqm",
  },
  terraced: {
    pricePsqm: "terraced_gbp_psqm",
    lpaPricePsqm: "lad_terraced_gbp_psqm",
  },
  flat: {
    pricePsqm: "flat_gbp_psqm",
    lpaPricePsqm: "lad_flat_gbp_psqm",
  },
};

export const MARKET_VALUE_RECENT_SALES_TOTAL_ATTRIBUTE_KEY = "recent_sales_total";

export const MARKET_VALUE_RECENT_SALES_ATTRIBUTE_KEYS_BY_TYPE: Record<HousingCategory, string> = {
  detached: "recent_sales_detached",
  semi_detached: "recent_sales_semi_detached",
  terraced: "recent_sales_terraced",
  flat: "recent_sales_flat",
};
