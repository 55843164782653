import { useCallback, useEffect, useState } from "react";
import { LayerTypeLayerSummaryProps, SupportedRegions } from "../../types";
import { useMaxAreaSelectedElevationConstraint } from "./useMaxAreaSelectedElevationConstraint";
import { TagColor } from "react-migration/components/Tag";
import { useKeyIndicatorTags } from "react-migration/lib/map/useKeyIndicatorTags";
import { Notification } from "react-migration/components/Notification";
import { ASPECT_CATEGORIES } from "./constants";
import { AspectSummary } from "./AspectSummary";
import { AspectStatsStrict } from "./Aspect/types";

export const TopographyAspectSummary = ({
  selection,
  onLoadingChange,
}: LayerTypeLayerSummaryProps) => {
  const { exceedsMaximumAreaConstraint } = useMaxAreaSelectedElevationConstraint(selection);

  useEffect(() => {
    onLoadingChange(true);
  }, [onLoadingChange]);

  useEffect(() => {
    if (exceedsMaximumAreaConstraint) {
      onLoadingChange(false);
    }
  }, [exceedsMaximumAreaConstraint, onLoadingChange]);

  const handleLoadingChange = useCallback(() => {
    onLoadingChange(false);
  }, [onLoadingChange]);

  const [aspectStats, setAspectStats] = useState<AspectStatsStrict | undefined>(undefined);
  const handleStatsLoaded = useCallback((stats: AspectStatsStrict) => {
    setAspectStats(stats);
  }, []);

  useKeyIndicatorTags(() => {
    if (exceedsMaximumAreaConstraint) {
      return [
        {
          tag: "Unavailable",
          color: TagColor.LIGHT_GRAY,
        },
      ];
    }

    if (!aspectStats) {
      return [];
    }

    const largest = Object.values(aspectStats).sort(
      (a, b) => b!.percentageCoverage - a!.percentageCoverage
    )[0];
    const key = ASPECT_CATEGORIES.find((category) => category.categoryKey === largest?.category);

    return [
      {
        tag: `${Math.round(largest?.percentageCoverage ?? 0)}% ${key?.label}`,
        color: TagColor.DEFAULT,
      },
    ];

    return [];
  }, [aspectStats, exceedsMaximumAreaConstraint]);

  if (exceedsMaximumAreaConstraint) {
    return (
      <Notification.Warning
        title="Unavailable"
        message="To view this insight, please select a site located in England and under 500 hectares in size"
      />
    );
  }

  return (
    <div className="atlas-flex atlas-flex-col atlas-items-center">
      <AspectSummary
        selection={selection}
        onLoaded={handleLoadingChange}
        onStatsLoaded={handleStatsLoaded}
      />
    </div>
  );
};

TopographyAspectSummary.icon = "icon-lt-compass-3-line";
TopographyAspectSummary.hideLink = true;
TopographyAspectSummary.supportedRegions = [SupportedRegions.england];
