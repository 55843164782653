import { useCallback, useMemo } from "react";
import { keyBy } from "lodash";
import { SelectionType } from "src/js/stores/map/store";
import {
  DesignationDistance,
  useNearestDesignationsQuery,
} from "react-migration/domains/constraints/designation/hooks/useNearestDesignationQuery";
import { SkeletonLoading } from "react-migration/components/SkeletonLoading";
import { UserUnitLength } from "react-migration/components/UserUnitLength";
import { isFinite } from "react-migration/lib/util/isDefined";
import { Insight } from "react-migration/components/Insight/Insight";
import { Icon } from "react-migration/components/Icon";
import { useSelectionSidebarLoading } from "../../../Bundle/useSelectionSidebarLoadingState";
import { LayerTypeLayerSummaryProps, SupportedRegions } from "../../../types";
import { useWorkbench } from "../../../Bundle/Workbench/WorkbenchContext";
import { POWER_SUBSTATION_DISTANCE_THRESHOLDS } from "./constants";
import { useNearestSubstationKeyIndicator } from "./useNearestSubstationKeyIndicator";

export function SubstationLayerSummary({ selection, onLoadingChange }: LayerTypeLayerSummaryProps) {
  const { distances, error, loading } = useNearestDesignationsQuery(
    selection?.feature?.geometry,
    POWER_SUBSTATION_DISTANCE_THRESHOLDS
  );

  // TODO ensure it always exists
  const substationDistanceByCategory = useMemo(() => {
    const distanceLookup = keyBy(distances, "designation.sub_category_id");

    return Object.fromEntries(
      POWER_SUBSTATION_DISTANCE_THRESHOLDS.map(({ category, radiusMeters }) => [
        category,
        {
          ...distanceLookup[category],
          maxDistanceMeters: radiusMeters,
        },
      ])
    );
  }, [distances]);

  useSelectionSidebarLoading(onLoadingChange, loading);
  useNearestSubstationKeyIndicator(substationDistanceByCategory);

  if (loading) {
    return (
      <div className="atlas-relative atlas-p-2 atlas-h-[220px]">
        <SkeletonLoading cols={2} rows={2} showPadding={false} bgClassName="atlas-bg-transparent" />
      </div>
    );
  }

  if (error) {
    // caught by error boundary
    throw error;
  }

  return (
    <div className="atlas-grid atlas-grid-cols-2 atlas-gap-2">
      <SubstationDistanceInsight
        title="Grid power"
        fallbackTitle="400-132 kV"
        data-testid="grid-power-insight"
        tooltip="Substation that generally receives 275kv or 400kv from the National Grid and reduces it down to 132kv."
        {...substationDistanceByCategory.grid_power_substations}
      />
      <SubstationDistanceInsight
        title="Bulk power"
        fallbackTitle="132-33 kV"
        data-testid="bulk-power-insight"
        tooltip="Intermediate substation generally where 132kV is transformed down to 33kV."
        {...substationDistanceByCategory.bulk_power_substations}
      />
      <SubstationDistanceInsight
        title="Primary power"
        fallbackTitle="33-11 kV"
        data-testid="primary-power-insight"
        tooltip="Intermediate substation generally where 33kV is transformed down to 11kV."
        {...substationDistanceByCategory.primary_power_substations}
      />
      <SubstationDistanceInsight
        title="Secondary power"
        fallbackTitle="<11 kV"
        data-testid="secondary-power-insight"
        tooltip="Intermediate substation generally where 11kV is transformed down to low voltage."
        {...substationDistanceByCategory.hv_power_substations}
      />
    </div>
  );
}

SubstationLayerSummary.icon = "icon-lt-substation-line";
SubstationLayerSummary.title = "Nearest substation";
SubstationLayerSummary.supportedRegions = [
  SupportedRegions.england,
  SupportedRegions.scotland,
  SupportedRegions.wales,
];

type SubstationDistanceInsightProps = Partial<DesignationDistance> & {
  maxDistanceMeters: number;
} & {
  title: string;
  tooltip: string;
  fallbackTitle: string;
  "data-testid": string;
};

function SubstationDistanceInsight({
  distanceMeters,
  maxDistanceMeters,
  fallbackTitle,
  designation,
  ...props
}: SubstationDistanceInsightProps) {
  const { setDetailSelection } = useWorkbench();

  const onSelectSubstationHandler = useCallback(
    () =>
      designation &&
      setDetailSelection({
        type: SelectionType.DESIGNATION,
        id: designation.id,
      }),
    [designation, setDetailSelection]
  );

  return (
    <Insight.Wrapper {...props}>
      <Insight.Body>
        <DistanceToSubstation
          distanceMeters={distanceMeters}
          maxDistanceMeters={maxDistanceMeters}
        />
      </Insight.Body>
      <Insight.Footer>
        {designation ? (
          <span
            className="atlas-max-w-full atlas-inline-flex atlas-items-center atlas-gap-x-0.5 atlas-text-text-base-active hover:atlas-text-text-base-active-hover atlas-leading-none atlas-select-none atlas-cursor-pointer"
            onClick={onSelectSubstationHandler}
          >
            <span className="atlas-truncate" title={designation.display_name}>
              {designation.display_name}
            </span>
            <Icon icon="icon-lt-arrow-right-line" size="sm" cursor="pointer" />
          </span>
        ) : (
          fallbackTitle
        )}
      </Insight.Footer>
    </Insight.Wrapper>
  );
}

function DistanceToSubstation({
  distanceMeters,
  maxDistanceMeters,
}: {
  distanceMeters: number | undefined;
  maxDistanceMeters: number;
}) {
  const isBeyondMaximumDistance = !isFinite(distanceMeters) || distanceMeters > maxDistanceMeters;
  const distance = isBeyondMaximumDistance ? maxDistanceMeters : distanceMeters;

  if (distanceMeters === 0) {
    return "On site";
  }

  return <UserUnitLength lengthMeters={distance} prefix={isBeyondMaximumDistance ? ">" : ""} />;
}
