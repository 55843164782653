import { InsightStatusType } from "react-migration/components/Insight/components/Body/Body";
import { dateString } from "src/js/util/dates";

type NullishString = string | null | undefined;
type FormatEmergingPlanType = {
  emStatus?: NullishString;
  date?: NullishString;
  documentUrl?: string;
};
type FormatEmergingPlanReturnType = {
  body: string;
  status?: InsightStatusType;
  footerValue: string;
  documentUrl?: string;
};

export const formatEmergingPlan = ({
  emStatus,
  date,
  documentUrl,
}: FormatEmergingPlanType): FormatEmergingPlanReturnType => {
  if (date) {
    return { footerValue: dateString(new Date(date)), body: emStatus || "Unknown", documentUrl };
  }
  return { footerValue: "-", body: "Unknown", documentUrl };
};
