import { LayerTypeLayerSummaryProps, SupportedRegions } from "../../../types";
import { HousingStockSection } from "./HousingStockSection";
import { RecentSalesSection } from "./RecentSalesSection";

export const LocalMarketValueSummary = ({
  selection,
  onLoadingChange,
}: LayerTypeLayerSummaryProps) => {
  const geometry = selection?.feature?.geometry || null;

  return (
    <div className="atlas-flex atlas-flex-col atlas-gap-y-4">
      <HousingStockSection geometry={geometry} />
      <RecentSalesSection geometry={geometry} onLoadingChange={onLoadingChange} />
    </div>
  );
};

LocalMarketValueSummary.icon = "icon-lt-money-pound-circle-line";
LocalMarketValueSummary.supportedRegions = [SupportedRegions.england, SupportedRegions.wales];
