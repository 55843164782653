import { useCookies } from "react-cookie";
import { endOfToday } from "date-fns";

import { ENVIRONMENT } from "src/js/util/environment";
import { ModalV2 } from "react-migration/components/ModalV2/ModalV2";
import { Button } from "react-migration/components/Button";
import { Intercom } from "util/intercom";

import { TRIAL_ENDING_COOKIE } from "../../../util/cookies";
import { setShowTrialEndingModal } from "src/js/stores/navigation/actions";
import { logEventV2 } from "react-migration/lib/util/logEventV2";

export const TrialEndingModal = () => {
  const [, setCookie] = useCookies([TRIAL_ENDING_COOKIE]);

  function setTrialCookie() {
    setCookie(TRIAL_ENDING_COOKIE, new Date().toISOString(), {
      expires: endOfToday(),
    });
  }
  function handleClose() {
    logEventV2({ name: "Upgrade", properties: { source: "TrialEndingModal", action: "dismiss" } });
    setTrialCookie();
    setShowTrialEndingModal(false);
  }

  function handleUpgrade() {
    logEventV2({
      name: "Upgrade",
      properties: { source: "SelfServiceUpgrade", action: "upgrade" },
    }).finally(() => {
      setTrialCookie();

      // If the event fails to store for any reasons let's not loose out on the potential upgrade for the sake of metrics
      window.location.href = `${ENVIRONMENT.ACCOUNTS_SERVICE_PRIVATE_API_URL}/customer-portal?flow=upgrade`;
    });
  }

  function handleContactSupport() {
    logEventV2({
      name: "Upgrade",
      properties: { source: "ContactSupportUpgrade", action: "contact-support" },
    });
    Intercom("show");
    setTrialCookie();
    setShowTrialEndingModal(false);
  }

  return (
    <ModalV2.Root defaultOpen={true}>
      <ModalV2.Body title="Upgrade for continued access" className="!atlas-z-[1000]">
        <div className="atlas-text-sm atlas-leading-normal" data-testid="overdue-invoice-modal">
          <p>
            Your trial with LandInsight ends in <b>{3}</b> days.
          </p>
          <p>
            You will need to upgrade for continued access. Both monthly and yearly contracts are
            available.
          </p>
        </div>
        <div className="atlas-flex atlas-justify-end atlas-pt-4 atlas-gap-2">
          <Button variant="tertiary" onClick={handleClose}>
            No thanks
          </Button>
          <Button variant="secondary" onClick={handleContactSupport}>
            Contact support
          </Button>
          <Button onClick={handleUpgrade}>Upgrade for continued access</Button>
        </div>
      </ModalV2.Body>
    </ModalV2.Root>
  );
};
