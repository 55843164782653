import { TagColor as KeyIndicatorColor } from "react-migration/components/Tag";
import { useUserLengthVariants } from "react-migration/components/UserUnitLength";
import { useKeyIndicatorTags } from "react-migration/lib/map/useKeyIndicatorTags";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { isFinite } from "react-migration/lib/util/isDefined";
import { useMemo } from "react";

type SubstationDistances = {
  maxDistanceMeters: number;
  distanceMeters: number;
};

type SubstationDistancesByCategory = Record<string, SubstationDistances>;

function useDistanceDisplay(substationDistances?: SubstationDistances): string {
  const { maxDistanceMeters, distanceMeters } = substationDistances || {};
  const nearestSubstationDistanceMeters = distanceMeters ?? maxDistanceMeters;
  const [nearestDistanceInUserUnit] = useUserLengthVariants(nearestSubstationDistanceMeters);

  // A missing distanceMeters means that the distance is greater than the
  // maxDistanceMeters as it was not returned by the API
  const isGreaterThanMaxDistance =
    !substationDistances || !isFinite(distanceMeters) || distanceMeters > maxDistanceMeters!;

  if (nearestSubstationDistanceMeters === 0) return "On site";

  return isGreaterThanMaxDistance ? `>${nearestDistanceInUserUnit}` : nearestDistanceInUserUnit;
}

export function useNearestSubstationKeyIndicator(
  substationDistancesByCategory?: SubstationDistancesByCategory
) {
  const { t } = useTranslation();

  const nearestSubstation = useMemo(() => {
    if (!substationDistancesByCategory) {
      return {};
    }

    const nearest = Object.entries(substationDistancesByCategory)
      .sort(([, a], [, b]) => {
        if (a.distanceMeters === undefined && b.distanceMeters === undefined) {
          return a.maxDistanceMeters - b.maxDistanceMeters;
        } else if (a.distanceMeters === undefined) {
          return 1;
        } else if (b.distanceMeters === undefined) {
          return -1;
        }
        return a.distanceMeters - b.distanceMeters;
      })
      .shift();

    const [category, distances] = nearest || [];

    return { category, distances };
  }, [substationDistancesByCategory]);

  const distanceDisplay = useDistanceDisplay(nearestSubstation.distances);

  useKeyIndicatorTags(
    function setKeyIndicators() {
      if (!nearestSubstation) {
        return [];
      }

      return [
        {
          tag: `${distanceDisplay} (${t(
            `workbench.summary.substations.key_indicator_display.${nearestSubstation.category}`
          )})`,
          color: KeyIndicatorColor.DEFAULT,
        },
      ];
    },
    [distanceDisplay, nearestSubstation, t]
  );
}
