import { ComponentProps } from "react";
import { isFinite, keyBy } from "lodash";
import type { DesignationDistance } from "react-migration/domains/constraints/designation/hooks/useNearestDesignationQuery";
import { SkeletonLoading } from "react-migration/components/SkeletonLoading";
import { UserUnitLength } from "react-migration/components/UserUnitLength";
import { Insight } from "react-migration/components/Insight/Insight";
import { InfoTooltip } from "../../../../../components/InfoTooltip";
import {
  BUS_STOP_CATEGORY,
  MAX_TRANSPORT_RADIUS_METERS,
  SUBWAY_STATION_CATEGORY,
  TRAIN_STATION_CATEGORY,
} from "./constants";

interface TransportSectionProps {
  distances?: DesignationDistance[];
  loading: boolean;
}

export function TransportSection({ distances, loading }: TransportSectionProps) {
  const distancesByCategory = keyBy(distances, "designation.sub_category_id");

  return (
    <section className="atlas-flex atlas-flex-col atlas-gap-3">
      <div className="atlas-flex atlas-gap-x-2">
        <h1 className="atlas-text-sm atlas-text-text-base-primary atlas-font-medium atlas-leading-5">
          Proximity to transport hubs
        </h1>
        <InfoTooltip>Distance measured from site boundary</InfoTooltip>
      </div>
      {loading ? (
        <div className="atlas-relative atlas-p-2 atlas-h-[102px]">
          <SkeletonLoading
            cols={3}
            rows={1}
            showPadding={false}
            bgClassName="atlas-bg-transparent"
          />
        </div>
      ) : (
        <div className="atlas-grid atlas-grid-cols-2 md:atlas-grid-cols-3 atlas-gap-2">
          <TransportInsight
            designationDistance={distancesByCategory[SUBWAY_STATION_CATEGORY]}
            title="Tube station"
            data-testid="tube-station-insight"
          />
          <TransportInsight
            designationDistance={distancesByCategory[TRAIN_STATION_CATEGORY]}
            title="Train station"
            data-testid="train-station-insight"
          />
          <TransportInsight
            designationDistance={distancesByCategory[BUS_STOP_CATEGORY]}
            title="Bus stop"
            data-testid="bus-stop-insight"
          />
        </div>
      )}
    </section>
  );
}

type TransportInsightProps = { designationDistance: DesignationDistance } & Pick<
  ComponentProps<typeof Insight.Wrapper>,
  "title" | "tooltip" | "data-testid"
>;

function TransportInsight({ designationDistance, ...props }: TransportInsightProps) {
  const beyondMaxDistance =
    !isFinite(designationDistance?.distanceMeters) ||
    designationDistance.distanceMeters > MAX_TRANSPORT_RADIUS_METERS;
  const distanceMeters = designationDistance?.distanceMeters ?? MAX_TRANSPORT_RADIUS_METERS;

  return (
    <Insight.Wrapper {...props}>
      <Insight.Body>
        <UserUnitLength lengthMeters={distanceMeters} prefix={beyondMaxDistance ? ">" : ""} />
      </Insight.Body>
      <Insight.Footer>
        {designationDistance?.designation?.display_name ?? "None nearby"}
      </Insight.Footer>
    </Insight.Wrapper>
  );
}
