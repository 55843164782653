import { FC, useCallback } from "react";
import { Intercom } from "src/js/util/intercom";
import { logEvent } from "react-migration/lib/util/logEvent";
import { Button } from "react-migration/components/Button";

export const HelpMenu: FC = () => {
  const toggleSupport = useCallback(() => {
    if (Intercom.visible) {
      Intercom("hide");
    } else {
      Intercom("show");
      logEvent("Open Intercom Widget", {
        from: "nav-bar",
        route: window.location.pathname.match(RegExp(/^\/([^/?]+)/))?.[1],
      });
    }
  }, []);
  return (
    <Button
      variant="ghost"
      data-testid="help-button"
      leadIcon="lt-question-line"
      onClick={toggleSupport}
    >
      Help &amp; Support
    </Button>
  );
};
