import { SkeletonLoading } from "react-migration/components/SkeletonLoading";
import { InfoTooltip } from "../../../../../components/InfoTooltip";
import { Icon } from "react-migration/components/Icon";
import { travelTimeDisplay } from "./travelTimeDisplay";

interface LocalServicesSectionProps {
  tableData: { label: string; timesMinutes: (number | null)[] }[] | null;
  loading: boolean;
}

export function LocalServicesSection({ tableData, loading }: LocalServicesSectionProps) {
  return (
    <section className="atlas-flex atlas-flex-col atlas-gap-3">
      <div className="atlas-flex atlas-gap-x-2">
        <h1 className="atlas-text-sm atlas-text-text-base-primary atlas-font-medium atlas-leading-5">
          Proximity to local services
        </h1>
        <InfoTooltip>
          Times are based on local area averages (LSOA level) and reflect typical access within the
          surrounding neighbourhood, not site-specific estimates.
        </InfoTooltip>
      </div>
      <div>
        {loading ? (
          <div className="atlas-relative atlas-p-2 atlas-h-[317px]">
            <SkeletonLoading
              cols={1}
              rows={6}
              showPadding={false}
              bgClassName="atlas-bg-transparent"
            />
          </div>
        ) : (
          tableData && (
            <table className="atlas-flex atlas-flex-col atlas-gap-y-2 md:-atlas-mt-8 atlas-pointer-events-none">
              <thead>
                <tr className="atlas-flex atlas-px-3">
                  <th className="atlas-text-left atlas-grow">
                    <span className="atlas-invisible">Service</span>
                  </th>
                  <th className="atlas-text-center atlas-basis-[66px] atlas-grow-0 atlas-shrink-0 atlas-self-end atlas-pointer-events-auto">
                    <Icon
                      icon="icon-lt-walk-line"
                      extraClasses="atlas-text-icon-base-secondary"
                      title="by walking"
                      size="lg"
                    />
                  </th>
                  <th className="atlas-text-center atlas-basis-[66px] atlas-grow-0 atlas-shrink-0 atlas-self-end atlas-pointer-events-auto">
                    <Icon
                      icon="icon-lt-riding-line"
                      extraClasses="atlas-text-icon-base-secondary"
                      title="by bike"
                      size="lg"
                    />
                  </th>
                  <th className="atlas-text-center atlas-basis-[66px] atlas-grow-0 atlas-shrink-0 atlas-self-end atlas-pointer-events-auto">
                    <Icon
                      icon="icon-lt-train-line"
                      extraClasses="atlas-text-icon-base-secondary"
                      title="by public transport"
                      size="lg"
                    />
                  </th>
                </tr>
              </thead>
              <tbody className="atlas-flex atlas-flex-col atlas-gap-y-2">
                {tableData.map(({ label, timesMinutes }) => (
                  <tr
                    key={label}
                    className="atlas-flex atlas-gap-y-2 atlas-items-center atlas-p-3 atlas-rounded-xl atlas-border atlas-border-blueGrey-200"
                  >
                    <td className="atlas-text-left atlas-grow atlas-text-xs atlas-text-text-base-primary atlas-font-normal atlas-truncate">
                      {label}
                    </td>
                    {timesMinutes.map((time, i) => (
                      <td
                        key={i}
                        className="atlas-text-center atlas-basis-[66px] atlas-grow-0 atlas-shrink-0 atlas-self-end atlas-text-sm atlas-font-medium atlas-text-text-accent-blue"
                      >
                        {travelTimeDisplay(time)}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          )
        )}
      </div>
    </section>
  );
}
