import { useMemo } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { isNumber } from "lodash";
import distance from "@turf/distance";
import { Notification } from "react-migration/components/Notification";
import { SkeletonLoading } from "react-migration/components/SkeletonLoading";
import { UserUnitLength } from "react-migration/components/UserUnitLength";
import { DataPanel, GridProps } from "react-migration/components/DataPanel";
import { CollapsibleConsiderationsCard } from "../../Bundle/Workbench/AssessmentTab/CollapsibleConsiderationsCard";
import { useTopographyStatsQuery } from "./useTopographyStatsQuery";
import { useMaxAreaSelectedElevationConstraint } from "./useMaxAreaSelectedElevationConstraint";
import { useSnapshot } from "valtio";
import { userStore } from "src/js/stores/user/store";
import { getLengthVariants } from "react-migration/lib/util/getAreaAndPerimeter";
import { TopographySelectionSidebarProps } from "./types";
import { TopographyBreakdown } from "./TopographyBreakdown";
import { MAX_AREA_CONSTRAINT_HECTARES } from "./constants";
import { useBetaFeatureFlag } from "react-migration/lib/user/useFeatureFlag";
import Feature from "src/js/stores/user/Feature";
import { SelectionType } from "src/js/stores/map/store";

const MAX_AREA_CONSTRAINT_MESSAGE = `To view these insights, please select sites in England that are less than ${MAX_AREA_CONSTRAINT_HECTARES} hectares`;

export function TopographySelectionSidebarWrapper(props: TopographySelectionSidebarProps) {
  const hasInsightsSummaryBeta = useBetaFeatureFlag(Feature.insightsSummaryBeta);

  if (hasInsightsSummaryBeta && props.selection.type !== SelectionType.POINT) {
    return null;
  }

  return (
    <ErrorBoundary
      fallback={
        <CollapsibleConsiderationsCard heading="Topography" name="Topography">
          <div className="atlas-p-2">
            <Notification.Error
              title="An error occurred"
              message="We were unable to fetch the topography data for this selection. Please try again."
            />
          </div>
        </CollapsibleConsiderationsCard>
      }
    >
      <TopographySelectionSidebar {...props} />
    </ErrorBoundary>
  );
}

export function TopographySelectionSidebar(props: TopographySelectionSidebarProps) {
  const { exceedsMaximumAreaConstraint } = useMaxAreaSelectedElevationConstraint(props.selection);
  const selectionIsWithinAreaConstraint = !exceedsMaximumAreaConstraint;

  const { data, loading, error } = useTopographyStatsQuery(props.selection.feature?.geometry, {
    selectionIsWithinAreaConstraint,
  });

  if (error) {
    // Caught by boundary above
    throw error;
  }

  if (selectionIsWithinAreaConstraint && !data?.elevation && !error) {
    return null;
  }

  if (loading) {
    return (
      <CollapsibleConsiderationsCard heading="Topography" name="Topography">
        <div className="atlas-p-2">
          <div className="atlas-relative atlas-h-[46px]">
            <SkeletonLoading rows={1} showPadding={false} />
          </div>
        </div>
      </CollapsibleConsiderationsCard>
    );
  }

  const selectionGeometryType = props.selection.feature?.geometry?.type;
  const selectionSupportsSlopeAnalysis =
    selectionGeometryType === "Polygon" || selectionGeometryType === "MultiPolygon";

  return (
    <CollapsibleConsiderationsCard heading="Topography" name="Topography">
      <div className="atlas-p-2">
        {exceedsMaximumAreaConstraint && (
          <Notification.Warning title="Unavailable" message={MAX_AREA_CONSTRAINT_MESSAGE} />
        )}
        {!exceedsMaximumAreaConstraint && <ElevationSummary {...props} data={data} />}
        {!exceedsMaximumAreaConstraint && selectionSupportsSlopeAnalysis && (
          <>
            <div className="atlas-h-[2px] atlas-bg-blueGrey-200 atlas-my-2" />
            <TopographyBreakdown {...props} />
          </>
        )}
      </div>
    </CollapsibleConsiderationsCard>
  );
}

type TopographySelectionSidebarInnerProps = TopographySelectionSidebarProps & {
  data: ReturnType<typeof useTopographyStatsQuery>["data"];
  gridProps?: GridProps;
};

export function ElevationSummary({ data, gridProps }: TopographySelectionSidebarInnerProps) {
  const elevationChange = useCalculateElevationChange(data?.elevation);
  const distanceInUserUnits = useUserLengthUnits(elevationChange);

  if (!data?.elevation) {
    return null;
  }

  const { minMeters, maxMeters } = data.elevation;

  const defaultSlopeTooltip = `The percentage change in elevation between the highest and lowest points on the site, relative to the horizontal distance between them.`;
  const slopeTooltip = distanceInUserUnits
    ? `${defaultSlopeTooltip} (Over ${distanceInUserUnits})`
    : defaultSlopeTooltip;

  return (
    <DataPanel.Grid columns={3} {...gridProps}>
      <DataPanel.Cell title="Highest">
        <UserUnitLength lengthMeters={maxMeters} />
      </DataPanel.Cell>
      <DataPanel.Cell title="Lowest">
        <UserUnitLength lengthMeters={minMeters} />
      </DataPanel.Cell>
      <DataPanel.Cell title="Change" tooltip={slopeTooltip}>
        {isNumber(elevationChange?.slope) ? `${elevationChange?.slope}%` : "-"}
      </DataPanel.Cell>
    </DataPanel.Grid>
  );
}

type CalculatedElevationChange = {
  distanceDelta: number;
  elevationDelta: number;
  slope: number;
} | null;

function useUserLengthUnits(elevationChange: CalculatedElevationChange) {
  const { user } = useSnapshot(userStore);
  const distanceInUserUnits = useMemo(() => {
    if (!elevationChange) {
      return null;
    }

    return getLengthVariants(elevationChange?.distanceDelta, user.settings.unit)[0];
  }, [user.settings.unit, elevationChange]);

  return distanceInUserUnits;
}

type ElevationData = TopographySelectionSidebarInnerProps["data"]["elevation"];

function useCalculateElevationChange(elevationData: ElevationData) {
  return useMemo(() => {
    if (!elevationData) {
      return null;
    }

    const { minMeters, maxMeters, minPoint, maxPoint } = elevationData;

    const elevationDelta = maxMeters - minMeters;
    const distanceDelta = distance(minPoint, maxPoint, { units: "meters" });

    if (!distanceDelta) {
      return {
        distanceDelta: 0,
        elevationDelta: 0,
        slope: 0,
      } as CalculatedElevationChange;
    }

    return {
      distanceDelta: distanceDelta,
      elevationDelta: elevationDelta,
      slope: +((elevationDelta / distanceDelta) * 100).toFixed(2),
    } as CalculatedElevationChange;
  }, [elevationData]);
}
