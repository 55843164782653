import { landAssemblyClickResolver } from "../../selectionUtils";
import type { GenericLayer, LayerType, SelectionHandler } from "../../types";
import { OwnershipLayerTypeProvider } from "./Context";
import { OwnershipControlPage } from "./ControlPage";
import { OwnershipMapLayer } from "./MapLayer";
import { OwnershipPrintable } from "./OwnershipPrintable";
import { OwnerSelectionDetailView } from "./OwnershipSelectionDetailView/OwnerSelectionDetailView";
import { OwnershipSelectionDetailView } from "./OwnershipSelectionDetailView/OwnershipSelectionDetailView";
import {
  OwnerSelectionHeader,
  OwnerSelectionTitle,
  OwnershipParcelSelectionHeader,
  OwnershipTitleSelectionHeader,
} from "./OwnershipSelectionHeader";
import { OwnershipSelectionSidebar } from "./OwnershipSelectionSidebar/OwnershipSelectionSidebar";
import { OwnershipRightClickMenu } from "./OwnershipRightClickMenu";
import { titleClickResolver, parcelClickResolver } from "./clickResolvers";
import { OwnershipPlotSizeLayerSummary } from "./OwnershipPlotSizeLayerSummary";

export type OwnershipLayer = GenericLayer<OwnershipLayerConfig>;

export type OwnershipLayerConfig = {
  minZoom?: number;
  pickable?: boolean;
};

export const OwnershipLayerType: LayerType = {
  id: "ownership",
  Provider: OwnershipLayerTypeProvider,
  MapLayer: OwnershipMapLayer,
  SelectionSidebar: OwnershipSelectionSidebar,
  ControlPage: OwnershipControlPage,
  RightClickMenu: OwnershipRightClickMenu,
  clickResolver: titleClickResolver,
  landAssemblyClickResolver: landAssemblyClickResolver(parcelClickResolver),
  Printable: OwnershipPrintable,
};

export const OwnershipPlotSizeLayerType: LayerType = {
  id: "ownership-plot-size",
  LayerSummary: OwnershipPlotSizeLayerSummary,
};

export const OwnershipTitleSelectionHandler: SelectionHandler = {
  SelectionTitle: OwnerSelectionTitle,
  SelectionHeader: OwnershipTitleSelectionHeader,
  SelectionDetailView: OwnershipSelectionDetailView,
};

export const OwnersSelectionHandler: SelectionHandler = {
  SelectionTitle: OwnerSelectionTitle,
  SelectionHeader: OwnerSelectionHeader,
  SelectionDetailView: OwnerSelectionDetailView,
};

export const OwnershipParcelSelectionHandler: SelectionHandler = {
  SelectionTitle: OwnerSelectionTitle,
  SelectionHeader: OwnershipParcelSelectionHeader,
};
