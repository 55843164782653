import { useMemo } from "react";
import { SelectionGeometry } from "src/js/stores/map/store";
import { Nullable } from "src/js/types/Nullable";
import { useDesignationWithLargestOverlapByGeometry } from "react-migration/domains/constraints/designation/hooks/useDesignationWithLargestOverlapByGeometry";
import { SparklineCategory, Sparkline } from "react-migration/components/Sparkline/Sparkline";
import { numberAttributeAccessor } from "react-migration/domains/constraints/designation/util/numberAttributeAccessor";
import { SkeletonLoading } from "react-migration/components/SkeletonLoading";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { InfoTooltip } from "react-migration/components/InfoTooltip";
import { isDefined } from "react-migration/lib/util/isDefined";
import { useSelectionSidebarLoading } from "../../../Bundle/useSelectionSidebarLoadingState";
import { HousingPricePerSqMeterInsight } from "./HousingPricePerSqMeterInsight";
import { useAveragePpsqmKeyIndicator } from "./useAveragePpsqmKeyIndicator";
import {
  MARKET_VALUE_CATEGORY_ID,
  MARKET_VALUE_RECENT_SALES_TOTAL_ATTRIBUTE_KEY,
  MARKET_VALUE_RECENT_SALES_ATTRIBUTE_KEYS_BY_TYPE,
  MARKET_VALUE_PPSQM_KEYS_BY_TYPE,
  HOUSING_COLOURS,
  HousingCategory,
} from "./constants";

interface RecentSalesSectionProps {
  geometry: Nullable<SelectionGeometry>;
  onLoadingChange(loading: boolean): void;
}

export function RecentSalesSection({ geometry, onLoadingChange }: RecentSalesSectionProps) {
  const { t } = useTranslation();
  const {
    designation: housingSalesDesignation,
    loading,
    error,
  } = useDesignationWithLargestOverlapByGeometry({
    geometry,
    rootCategories: [MARKET_VALUE_CATEGORY_ID],
    attributeKeys: [
      MARKET_VALUE_RECENT_SALES_TOTAL_ATTRIBUTE_KEY,
      ...Object.values(MARKET_VALUE_RECENT_SALES_ATTRIBUTE_KEYS_BY_TYPE),
      ...Object.values(MARKET_VALUE_PPSQM_KEYS_BY_TYPE).flatMap((priceAttributes) =>
        Object.values(priceAttributes)
      ),
    ],
  });

  const recentSalesByTypeSparklineCategories = useMemo<SparklineCategory[]>(() => {
    if (!housingSalesDesignation) return [];

    const total = numberAttributeAccessor(
      housingSalesDesignation,
      MARKET_VALUE_RECENT_SALES_TOTAL_ATTRIBUTE_KEY
    );

    if (total === null) return [];

    return Object.entries(MARKET_VALUE_RECENT_SALES_ATTRIBUTE_KEYS_BY_TYPE)
      .map(([housingType, attributeKey]) => {
        const value = numberAttributeAccessor(housingSalesDesignation, attributeKey);

        if (value === null) return null;

        return {
          categoryKey: attributeKey,
          name: t(`workbench.summary.local_market_value.type.${housingType}`),
          color: HOUSING_COLOURS[housingType as HousingCategory],
          percentage: (value / total) * 100,
        };
      })
      .filter(isDefined);
  }, [housingSalesDesignation, t]);

  useAveragePpsqmKeyIndicator(housingSalesDesignation);
  useSelectionSidebarLoading(onLoadingChange, loading);

  if (error) {
    // caught by error boundary
    throw error;
  }

  return (
    <section className="atlas-flex atlas-flex-col atlas-gap-y-7">
      <div className="atlas-flex atlas-flex-col atlas-gap-y-2">
        <div className="atlas-flex atlas-gap-x-2">
          <h1 className="atlas-text-text-base-primary atlas-text-sm atlas-font-medium">
            Recent sales by housing type
          </h1>
          <InfoTooltip>
            This reflects the number of residential property transactions by type within the
            surrounding area (MSOA) over the past 12 months.
          </InfoTooltip>
        </div>
        {loading ? (
          <div className="atlas-relative atlas-h-[24px]">
            <SkeletonLoading
              cols={1}
              rows={1}
              showPadding={false}
              bgClassName="atlas-bg-transparent"
            />
          </div>
        ) : (
          <Sparkline
            categories={recentSalesByTypeSparklineCategories}
            noneLabel="Other"
            noneCategoryPosition="end"
            showLegend
          />
        )}
      </div>
      {loading ? (
        <div className="atlas-mt-6 atlas-relative atlas-h-[212px]">
          <SkeletonLoading
            cols={2}
            rows={2}
            showPadding={false}
            bgClassName="atlas-bg-transparent"
          />
        </div>
      ) : (
        <div className="atlas-grid atlas-grid-cols-2 atlas-gap-2">
          <HousingPricePerSqMeterInsight
            housingType="detached"
            designation={housingSalesDesignation!}
            priceAttributeKey={MARKET_VALUE_PPSQM_KEYS_BY_TYPE.detached.pricePsqm}
            lpaPriceAttributeKey={MARKET_VALUE_PPSQM_KEYS_BY_TYPE.detached.lpaPricePsqm}
          />
          <HousingPricePerSqMeterInsight
            housingType="semi_detached"
            designation={housingSalesDesignation!}
            priceAttributeKey={MARKET_VALUE_PPSQM_KEYS_BY_TYPE.semi_detached.pricePsqm}
            lpaPriceAttributeKey={MARKET_VALUE_PPSQM_KEYS_BY_TYPE.semi_detached.lpaPricePsqm}
          />
          <HousingPricePerSqMeterInsight
            housingType="terraced"
            designation={housingSalesDesignation!}
            priceAttributeKey={MARKET_VALUE_PPSQM_KEYS_BY_TYPE.terraced.pricePsqm}
            lpaPriceAttributeKey={MARKET_VALUE_PPSQM_KEYS_BY_TYPE.terraced.lpaPricePsqm}
          />
          <HousingPricePerSqMeterInsight
            housingType="flat"
            designation={housingSalesDesignation!}
            priceAttributeKey={MARKET_VALUE_PPSQM_KEYS_BY_TYPE.flat.pricePsqm}
            lpaPriceAttributeKey={MARKET_VALUE_PPSQM_KEYS_BY_TYPE.flat.lpaPricePsqm}
          />
        </div>
      )}
    </section>
  );
}
