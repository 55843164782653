import { Checkbox } from "react-migration/components/Checkbox";
import { Tooltip } from "react-migration/components/Tooltip";
import { AssessmentTab as Tab } from "./useAssessmentTabs";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { layerTranslationKey, tabTranslationKey } from "./utils";
import { useMemo, useState } from "react";
import classNames from "classnames";
import { LayerLoadingState } from "./SiteReportContent";
import clsx from "clsx";
import { TabLoadingState } from "./useReportLoadingState";
import { Theme } from "react-migration/lib/theme/Theme";

interface AssessmentTabProps {
  tab: Tab;
  loadingState: TabLoadingState;
  checkLayerLoadingState: (layerId: string) => LayerLoadingState;
  checkedState: Record<string, boolean>;
  handleCheckedChange: (layerKey?: string) => void;
}

export function AssessmentTab({
  tab,
  loadingState,
  checkLayerLoadingState,
  checkedState,
  handleCheckedChange,
}: AssessmentTabProps) {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(true);

  const isTabChecked = useMemo(() => {
    if (!checkedState) {
      return false;
    }
    const [totalLayers, selectedLayers] = Object.values(checkedState).reduce(
      ([totalLayers, selectedLayers], isSelected) => {
        return [totalLayers + 1, isSelected ? selectedLayers + 1 : selectedLayers];
      },
      [0, 0]
    );
    if (selectedLayers === 0) {
      return false;
    }
    if (selectedLayers === totalLayers) {
      return true;
    }
    return "indeterminate";
  }, [checkedState]);

  if (tab.expandable) {
    return (
      <div
        data-testid="expandable-tab-container"
        className={classNames("atlas-flex", "atlas-flex-col", "atlas-gap-4")}
      >
        <div className={classNames("atlas-flex", "atlas-flex-row")}>
          <div className={classNames("atlas-flex", "atlas-flex-row", "atlas-gap-2")}>
            <Checkbox
              id={tab.key}
              checked={isTabChecked}
              onCheckedChange={() => handleCheckedChange()}
              supportsIndeterminate
            />
            <label
              htmlFor={tab.key}
              className="atlas-text-sm atlas-text-blueGrey-950 atlas-select-none"
            >
              {t(tabTranslationKey(tab.key))}
            </label>
            {loadingState === TabLoadingState.LOADING && (
              <i
                className={clsx(
                  "atlas-text-lg",
                  "atlas-leading-5",
                  "icon-lt-loader-4-line",
                  "atlas-animate-spin"
                )}
              />
            )}
          </div>
          <button
            className={classNames("atlas-flex", "atlas-grow", "atlas-justify-end")}
            data-testid="expandable-tab-trigger"
            onClick={() => setExpanded(!expanded)}
          >
            <i
              className={classNames(
                "atlas-text-base",
                "atlas-w-5",
                "atlas-h-5",
                "atlas-px-2",
                "atlas-flex",
                "atlas-items-center",
                "atlas-transition-transform",
                expanded ? "icon-lt-arrow-down-s-line" : "icon-lt-arrow-up-s-line"
              )}
            />
          </button>
        </div>
        <div
          data-testid="expandable-tab-content"
          className={classNames(
            expanded ? "atlas-flex" : "atlas-hidden",
            "atlas-flex-col",
            "atlas-ml-3",
            "atlas-gap-3"
          )}
        >
          {tab.layers.map((layer) => {
            return (
              <div className="atlas-flex atlas-items-center atlas-gap-2" key={layer.id}>
                <Checkbox
                  id={layer.id}
                  checked={checkedState[layer.id]}
                  onCheckedChange={() => handleCheckedChange(layer.id)}
                />
                <label
                  htmlFor={layer.id}
                  className="atlas-text-sm atlas-text-blueGrey-950 atlas-select-none"
                >
                  {t(layerTranslationKey(layer.id))}
                </label>
                {checkLayerLoadingState(layer.id)?.loading && (
                  <i
                    className={clsx(
                      "atlas-text-lg",
                      "atlas-leading-5",
                      `icon-lt-loader-4-line`,
                      "atlas-animate-spin"
                    )}
                  />
                )}
                {checkLayerLoadingState(layer.id)?.error && (
                  <Tooltip.Wrapper>
                    <Tooltip.Trigger>
                      <i
                        className={clsx("atlas-text-lg", "atlas-leading-5", "icon-lt-alert-line")}
                      />
                    </Tooltip.Trigger>
                    <Tooltip.Content align="center" side="bottom" theme={Theme.Dark} size="sm">
                      <span className="atlas-whitespace-normal">
                        Unable to load. Toggle on to try again
                      </span>
                    </Tooltip.Content>
                  </Tooltip.Wrapper>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  return (
    <div className="atlas-flex atlas-items-center atlas-gap-2">
      <Checkbox
        id={tab.key}
        checked={isTabChecked}
        supportsIndeterminate
        onCheckedChange={() => handleCheckedChange()}
      />
      <label htmlFor={tab.key} className="atlas-text-sm atlas-text-blueGrey-950 atlas-select-none">
        {t(tabTranslationKey(tab.key))}
      </label>
      {loadingState !== TabLoadingState.READY &&
        (loadingState === TabLoadingState.LOADING ? (
          <i
            className={clsx(
              "atlas-text-lg",
              "atlas-leading-5",
              "icon-lt-loader-4-line",
              "atlas-animate-spin"
            )}
          />
        ) : (
          <Tooltip.Wrapper>
            <Tooltip.Trigger>
              <i className={clsx("atlas-text-lg", "atlas-leading-5", "icon-lt-alert-line")} />
            </Tooltip.Trigger>
            <Tooltip.Content align="center" side="bottom" theme={Theme.Dark} size="sm">
              <span className="atlas-whitespace-normal">{`Error loading ${t(
                tabTranslationKey(tab.key)
              )} data`}</span>
            </Tooltip.Content>
          </Tooltip.Wrapper>
        ))}
    </div>
  );
}
