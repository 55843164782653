import { LayerType, SelectionHandler } from "../../types";
import { DesignationSelectionHandler } from "../ConstraintsLayerType";
import { topographyClickResolver } from "./topographyClickResolver";
import { TopographySelectionSidebarWrapper } from "./TopographySelectionSidebar";
import { UKTopographyControlPage } from "./UK/UKTopographyControlPage";
import { USTopographyControlPage } from "./US/USTopographyControlPage";
import { TopographyPrintable } from "./TopographyPrintable";
import { USTopographyMapLayer } from "./US/USTopographyMapLayer";
import { UKTopographyMapLayer } from "./UK/UKTopographyMapLayer";
import { TopographySlopeSummary } from "./TopographySlopeSummary";
import { TopographyAspectSummary } from "./TopographyAspectSummary";

export const UKTopographyLayerType: LayerType = {
  id: "TopographyLayerType",
  MapLayer: UKTopographyMapLayer,
  ControlPage: UKTopographyControlPage,
  clickResolver: topographyClickResolver,
  SelectionSidebar: TopographySelectionSidebarWrapper,
  Printable: TopographyPrintable,
};

export const USTopographyLayerType: LayerType = {
  id: "USTopographyLayerType",
  MapLayer: USTopographyMapLayer,
  ControlPage: USTopographyControlPage,
};

/*
  - showAssessmentTabsForSelection used to control tabs for a selection handler, make it
    config on the selection type or the selection itself
  - had to duplicate a large amount of the constraints click resolver to drive
    contour selections as they use the constraints layer
*/

export const ContourSelectionHandler: SelectionHandler = {
  SelectionTitle: DesignationSelectionHandler.SelectionTitle,
  SelectionHeader: DesignationSelectionHandler.SelectionHeader,
  SelectionDetailView: DesignationSelectionHandler.SelectionDetailView,
};

export const TopographySlopeLayerType: LayerType = {
  id: "TopographySlopeLayerType",
  LayerSummary: TopographySlopeSummary,
};

export const TopographyAspectLayerType: LayerType = {
  id: "TopographyAspectLayerType",
  LayerSummary: TopographyAspectSummary,
};
