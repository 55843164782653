import { useCallback, useMemo, useRef, useState } from "react";
import { PlanningApplicationDetailMiniCard } from "react-migration/domains/planning/components/PlanningApplicationDetailMiniCard";
import { useRefElementSize } from "react-migration/lib/util/useElementSize";
import { PageControls } from "react-migration/components/PageControls";
import { logEventV2 } from "react-migration/lib/util/logEventV2";
import { CARD_NAME } from "./constants";
import { PlanningApplication } from "react-migration/domains/planning/types";

const CAROUSEL_GAP = 8;

export interface MostRecentSectionProps {
  applications: PlanningApplication[];
  onTitleClick: (application: PlanningApplication) => void;
  insideSummaryAccordion: boolean;
}

export type CarouselProps<T extends { id: string }> = {
  items: T[];
  renderItem: (item: T, index: number) => React.ReactNode;
  onIndexChange?: (index: number) => void;
};

export const Carousel = <T extends { id: string }>({
  items,
  renderItem,
  onIndexChange,
}: CarouselProps<T>) => {
  const scrollableRef = useRef<HTMLDivElement>(null);
  const scrollEndTimeoutRef = useRef<number | null>(null);
  const { width: cardWidth } = useRefElementSize(scrollableRef);
  const [currentItemIndex, setCurrentItemIndex] = useState(0);

  const scrollToCardIndex = useCallback(
    (index: number) => {
      if (!scrollableRef.current) return;

      const scrollOffset = index * (cardWidth + CAROUSEL_GAP);

      setCurrentItemIndex(index);
      scrollableRef.current.scrollTo({ left: scrollOffset, behavior: "smooth" });

      if (index !== currentItemIndex) {
        onIndexChange?.(index);
      }
    },
    [cardWidth, currentItemIndex, onIndexChange]
  );

  const onScrollHandler = useCallback(() => {
    function snapToClosestCard() {
      if (!scrollableRef.current) return;

      const { scrollLeft } = scrollableRef.current;
      const closestCardIndex = Math.round(scrollLeft / (cardWidth + CAROUSEL_GAP));

      scrollToCardIndex(closestCardIndex);
    }

    scrollEndTimeoutRef.current && clearTimeout(scrollEndTimeoutRef.current);
    scrollEndTimeoutRef.current = setTimeout(snapToClosestCard, 100) as unknown as number;
  }, [cardWidth, scrollToCardIndex]);

  return (
    <div className="atlas-flex atlas-flex-col atlas-gap-y-2">
      {items.length > 1 && (
        // negative margin used to pull pagination controls into section header
        <div className="atlas-self-end -atlas-mt-11">
          <PageControls
            onNextPage={() => scrollToCardIndex(currentItemIndex + 1)}
            onPreviousPage={() => scrollToCardIndex(currentItemIndex - 1)}
            page={currentItemIndex + 1}
            totalPages={items.length}
          />
        </div>
      )}
      <div
        className="atlas-flex atlas-flex-row atlas-gap-x-2 atlas-overflow-x-auto atlas-overflow-y-hidden"
        style={{ columnGap: `${CAROUSEL_GAP}px` }}
        onScroll={onScrollHandler}
        ref={scrollableRef}
      >
        {items.map((item, index) => (
          <div
            key={item.id}
            className="atlas-grow-0 atlas-shrink-0"
            style={{ width: `${cardWidth}px` }}
          >
            {renderItem(item, index)}
          </div>
        ))}
      </div>
    </div>
  );
};

export const MostRecentSection = ({
  applications,
  insideSummaryAccordion,
  onTitleClick,
}: MostRecentSectionProps) => {
  const applicationsOrderedByDateReceived = useMemo(
    () =>
      applications.slice().sort((a, b) => +new Date(b.date_received) - +new Date(a.date_received)),
    [applications]
  );

  const handleApplicationChange = useCallback(() => {
    // only log if scroll resulted in viewing a new application
    logEventV2({
      name: "Workbench card click",
      properties: {
        cardName: CARD_NAME,
        clickDetail: "Planning application",
        action: "scrolled",
        insightSummary: insideSummaryAccordion,
      },
    });
  }, [insideSummaryAccordion]);

  const onTitleClickHandler = useCallback(
    (application: PlanningApplication) => {
      onTitleClick(application);
      logEventV2({
        name: "Workbench card click",
        properties: {
          cardName: CARD_NAME,
          clickDetail: "Planning application",
          action: "goTo",
          planningApplicationID: application.id,
          planningApplicationClassification: application.classification,
          planningApplicationType: application.types_derived?.join(", ") || "",
          insightSummary: insideSummaryAccordion,
        },
      });
    },
    [insideSummaryAccordion, onTitleClick]
  );

  return (
    <Carousel
      items={applicationsOrderedByDateReceived}
      onIndexChange={handleApplicationChange}
      renderItem={(application) => (
        <PlanningApplicationDetailMiniCard
          application={application}
          onTitleClick={onTitleClickHandler}
        />
      )}
    />
  );
};
