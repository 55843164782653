import { SkeletonLoading } from "react-migration/components/SkeletonLoading";
import { checkKey, useTranslation } from "react-migration/lib/i18n/useTranslation";
import { useSelectionSidebarLoading } from "../../../Bundle/useSelectionSidebarLoadingState";
import { LayerTypeLayerSummaryProps, SupportedRegions } from "../../../types";
import { Insight } from "react-migration/components/Insight/Insight";
import {
  KeyIndicatorColor,
  useKeyIndicatorTags,
} from "react-migration/lib/map/useKeyIndicatorTags";
import { useDesignationAreaByGeometry } from "react-migration/domains/constraints/designation/hooks/useDesignationAreaByGeometry";
import { useMemo } from "react";
import { POLICY_FLUM_DISPLAY_NAME_MAP, POLICY_ZONING_DISPLAY_NAME_MAP } from "../constants";

const POLICY_ZONING_CATEGORIES = Object.keys(POLICY_ZONING_DISPLAY_NAME_MAP);
const POLICY_FLUM_CATEGORIES = Object.keys(POLICY_FLUM_DISPLAY_NAME_MAP);

export function ZoningLayerSummary({ selection, onLoadingChange }: LayerTypeLayerSummaryProps) {
  const geometry = selection.feature?.geometry ?? null;

  const { t } = useTranslation();

  const {
    data: zoningDesignations,
    loading: zoningLoading,
    error: zoningError,
  } = useDesignationAreaByGeometry(geometry, POLICY_ZONING_CATEGORIES);

  const {
    data: flumDesignations,
    loading: flumLoading,
    error: flumError,
  } = useDesignationAreaByGeometry(geometry, POLICY_FLUM_CATEGORIES);

  const loading = zoningLoading && flumLoading;

  const zoningItem = useMemo(() => {
    const defaultZoningItem = {
      noData: true,
      displayName: "N/A",
      groupCategory: "",
    };

    if (!zoningDesignations?.length) {
      return defaultZoningItem;
    }

    const designations = [...zoningDesignations].sort(
      (a, b) => b.category_coverage_percentage - a.category_coverage_percentage
    );

    const designation = designations[0];

    const zoningItemMapped = POLICY_ZONING_DISPLAY_NAME_MAP[designation.sub_category];

    return {
      noData: false,
      displayName: checkKey(`constraints.categories.${designation.sub_category}`)
        ? t(`constraints.categories.${designation.sub_category}`)
        : designation.display_name,
      ...zoningItemMapped,
    };
  }, [zoningDesignations, t]);

  const flumItem = useMemo(() => {
    const defaultFlumItem = {
      displayName: "N/A",
      groupCategory: "",
    };

    if (!flumDesignations?.length) {
      return defaultFlumItem;
    }

    const designations = [...flumDesignations].sort(
      (a, b) => b.category_coverage_percentage - a.category_coverage_percentage
    );

    const designation = designations[0];

    const zoningItemMapped = POLICY_FLUM_DISPLAY_NAME_MAP[designation.sub_category];

    return {
      displayName: zoningItemMapped.name,
      ...zoningItemMapped,
    };
  }, [flumDesignations]);

  useSelectionSidebarLoading(onLoadingChange, loading);
  useKeyIndicatorTags(() => {
    return [
      {
        color: zoningItem.noData ? KeyIndicatorColor.LIGHT_GRAY : KeyIndicatorColor.DEFAULT,
        tag: zoningItem.displayName,
      },
    ];
  }, [zoningItem]);

  if (loading) {
    return (
      <div className="atlas-relative atlas-p-2 atlas-h-[168px]">
        <SkeletonLoading rows={2} showPadding={false} bgClassName="atlas-bg-transparent" />
      </div>
    );
  }

  if (zoningError) {
    // caught by error boundary
    throw zoningError;
  }

  if (flumError) {
    // caught by error boundary
    throw flumError;
  }

  return (
    <div className="atlas-grid atlas-grid-cols-2 atlas-gap-2">
      <Insight.Wrapper title="Zoning">
        <Insight.Body>{zoningItem.displayName}</Insight.Body>
        <Insight.Footer description={zoningItem.groupCategory}></Insight.Footer>
      </Insight.Wrapper>
      <Insight.Wrapper title="Future land use">
        <Insight.Body>{flumItem.displayName}</Insight.Body>
        <Insight.Footer description={flumItem.groupCategory}></Insight.Footer>
      </Insight.Wrapper>
    </div>
  );
}

ZoningLayerSummary.icon = "icon-lt-collage-line";
ZoningLayerSummary.supportedRegions = [SupportedRegions.us];
