import { isFinite as _isFinite } from "lodash";

export function isDefined<T>(value: T): value is NonNullable<T> {
  return typeof value !== "undefined" && value !== null;
}

export function isNotEmpty<T>(value: T): value is NonNullable<T> {
  return isDefined(value) && value !== "";
}

export function isFinite(value: any): value is number {
  return _isFinite(value);
}
