import { includes } from "lodash";
import { TagColor as KeyIndicatorColor } from "react-migration/components/Tag";
import { useKeyIndicatorTags } from "react-migration/lib/map/useKeyIndicatorTags";
import { DesignationArea } from "react-migration/domains/constraints/designation/hooks/useDesignationAreaByGeometry";
import {
  US_DRINKING_WATER_UNKNOWN_CATEGORIES,
  US_DRINKING_WATER_KNOWN_CATEGORIES,
  US_DRINKING_WATER_LIKELY_CATEGORIES,
  US_DRINKING_WATER_CONFLICTING_CATEGORIES,
  US_WASTE_WATER_UNKNOWN_CATEGORIES,
  US_WASTE_WATER_KNOWN_CATEGORIES,
  US_WASTE_WATER_LIKELY_CATEGORIES,
  US_WASTE_WATER_CONFLICTING_CATEGORIES,
} from "./constants";

export function useUSUtilityStatusKeyIndicators(
  drinkingArea: DesignationArea | undefined,
  wasteArea: DesignationArea | undefined
) {
  useKeyIndicatorTags(
    function setKeyIndicators() {
      const drinkingWaterCategory = drinkingArea?.sub_category;
      const wasteWaterCategory = wasteArea?.sub_category;

      if (
        includes(US_DRINKING_WATER_UNKNOWN_CATEGORIES, drinkingWaterCategory) &&
        includes(US_WASTE_WATER_UNKNOWN_CATEGORIES, wasteWaterCategory)
      ) {
        return [{ tag: "Unknown", color: KeyIndicatorColor.RED }];
      } else if (
        includes(US_DRINKING_WATER_KNOWN_CATEGORIES, drinkingWaterCategory) &&
        includes(US_WASTE_WATER_KNOWN_CATEGORIES, wasteWaterCategory)
      ) {
        return [{ tag: "Known", color: KeyIndicatorColor.GREEN }];
      } else if (
        includes(US_DRINKING_WATER_LIKELY_CATEGORIES, drinkingWaterCategory) &&
        includes(US_WASTE_WATER_LIKELY_CATEGORIES, wasteWaterCategory)
      ) {
        return [{ tag: "Likely", color: KeyIndicatorColor.ORANGE }];
      } else if (
        includes(US_DRINKING_WATER_CONFLICTING_CATEGORIES, drinkingWaterCategory) &&
        includes(US_WASTE_WATER_CONFLICTING_CATEGORIES, wasteWaterCategory)
      ) {
        return [{ tag: "Conflicting", color: KeyIndicatorColor.RED }];
      } else if (
        includes(US_DRINKING_WATER_KNOWN_CATEGORIES, drinkingWaterCategory) ||
        includes(US_WASTE_WATER_KNOWN_CATEGORIES, wasteWaterCategory)
      ) {
        return [{ tag: "Partially Known", color: KeyIndicatorColor.ORANGE }];
      } else if (
        includes(US_DRINKING_WATER_LIKELY_CATEGORIES, drinkingWaterCategory) ||
        includes(US_WASTE_WATER_LIKELY_CATEGORIES, wasteWaterCategory)
      ) {
        return [{ tag: "Partially Likely", color: KeyIndicatorColor.ORANGE }];
      } else if (
        includes(US_DRINKING_WATER_CONFLICTING_CATEGORIES, drinkingWaterCategory) ||
        includes(US_WASTE_WATER_CONFLICTING_CATEGORIES, wasteWaterCategory)
      ) {
        return [{ tag: "Partially Conflicting", color: KeyIndicatorColor.RED }];
      } else {
        return [{ tag: "N/A", color: KeyIndicatorColor.LIGHT_GRAY }];
      }
    },
    [drinkingArea, wasteArea]
  );
}
