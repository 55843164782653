import { DividerV2 } from "react-migration/components/DividerV2";
import hasFeature from "src/js/stores/user/actions/hasFeature";
import Feature from "src/js/stores/user/Feature";
import { Credits } from "./components/Credits";
import { Header } from "./components/Header";
import { Usage } from "./components/Usage";

export const Topup = () => {
  return (
    <div className="atlas-flex atlas-flex-col atlas-gap-6">
      <Header />
      {!hasFeature(Feature.usAccess) && (
        <>
          <Credits />
          <DividerV2 />
        </>
      )}
      <Usage />
    </div>
  );
};
