import { SelectionFeature } from "src/js/stores/map/store";
import {
  PLANNING_TEXT_COLOR_MAP,
  PlanningColor,
  getStatusColor,
} from "react-migration/domains/planning/utils/colors";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import {
  LocalAuthority,
  useLocalAuthorities,
} from "react-migration/layouts/map/Multilayer/layer_types/PlanningApplicationsLayerType/useLocalAuthorities";
import { PlanningApplication } from "react-migration/domains/planning/types";
import { AppealDetails } from "react-migration/domains/planning/components/AppealDetails/AppealDetails";
import { DetailsList } from "react-migration/components/DetailsList";
import { Section } from "react-migration/components/Section";
import { Tag } from "react-migration/components/Tag";
import { formatClassificationValue } from "../../utils/textFormatting";
import { usePlanningTypesDisplay } from "../../utils/usePlanningTypesDisplay";
import { protectedDateFormatter } from "../../utils/protectedDateFormatter";
import { ViewApplicationButton } from "../ViewApplicationButton/ViewApplicationButton";
import { AppealTag } from "../AppealTag";
import { PlanningApplicationsWithinGeometry } from "./fetchPlanningAppsWithinGeometry";
import TitleWithLinks from "./TitleWithLinks";

export const PlanningApplicationDetail = ({
  planningApplication,
  onClose,
}: {
  planningApplication: PlanningApplicationsWithinGeometry;
  onClose: () => void;
}) => {
  return (
    <div className="atlas-space-y-2">
      <button
        title="Back to Selected Constraints"
        onClick={onClose}
        className="atlas-w-full atlas-flex atlas-text-sm atlas-font-normal atlas-bg-white atlas-p-2 atlas-text-text-base-active hover:atlas-text-text-base-active-hover focus:atlas-text-text-base-active-hover atlas-cursor-pointer atlas-items-center atlas-font-sans atlas-outline-none atlas-border-none"
      >
        <i className="icon-lt-arrow-left-s-line atlas-text-xl atlas-cursor-pointer atlas-pr-1"></i>
        <span>Back to Planning Applications</span>
      </button>
      <PlanningApplicationDetailSection planningApplication={planningApplication} />
    </div>
  );
};

export const PlanningApplicationDetailSection = ({
  planningApplication,
  setDetailSelection,
}: {
  planningApplication: PlanningApplication;
  setDetailSelection?: (selection: SelectionFeature) => void;
}) => {
  const { t } = useTranslation();
  const { localAuthorities } = useLocalAuthorities();
  const types = usePlanningTypesDisplay(planningApplication.types_derived);

  const planningAuthority =
    localAuthorities &&
    localAuthorities.find(
      (authority: LocalAuthority) => authority.gss_code === planningApplication.gss_code
    )?.name;

  const statusColor = getStatusColor(planningApplication.status_derived) || PlanningColor.DEFAULT;

  return (
    <Section data-testid="planning-details-section">
      <div className="atlas-px-2 atlas-py-2">
        <div className="atlas-space-y-2">
          <DetailsHeader
            planningApplication={planningApplication}
            planningAuthority={planningAuthority}
          />
          <DetailsList.Item label="Classification">
            {formatClassificationValue(planningApplication.classification)}
          </DetailsList.Item>
          <DetailsList.Item label="planning.planning_application_list.planning_application_detail_view.type">
            {types}
          </DetailsList.Item>
          {planningApplication.decision && planningApplication.decision_date ? (
            <DetailsList.Item label="planning.planning_application_list.planning_application_detail_view.decision">
              <span className={PLANNING_TEXT_COLOR_MAP[statusColor]}>
                {`${planningApplication.decision} (${protectedDateFormatter(
                  planningApplication.decision_date
                )})`}
              </span>
            </DetailsList.Item>
          ) : (
            <DetailsList.Item label="planning.planning_application_list.planning_application_detail_view.decision">
              Unknown
            </DetailsList.Item>
          )}
          <DetailsList.Item label="planning.planning_application_list.planning_application_detail_view.address">
            {planningApplication.address}
          </DetailsList.Item>
          <DetailsList.Item label="planning.planning_application_list.planning_application_detail_view.description">
            <TitleWithLinks
              title={planningApplication.title}
              references={planningApplication.references}
              setDetailSelection={setDetailSelection}
            />
          </DetailsList.Item>
          <DetailsList.Item label="planning.planning_application_list.planning_application_detail_view.date_received">
            {protectedDateFormatter(planningApplication.date_received)}
          </DetailsList.Item>
          <DetailsList.Item label="planning.planning_application_list.planning_application_detail_view.tags">
            <div className="atlas-flex atlas-gap-1 atlas-flex-wrap">
              {!!planningApplication.num_dwellings && (
                <Tag
                  tag={t("planning.dwelling_count", { count: planningApplication.num_dwellings })}
                />
              )}
              {planningApplication.tags?.map((tag) => (
                <Tag key={tag} tag={tag} />
              ))}
            </div>
          </DetailsList.Item>

          <DetailsList.Item label="planning.planning_application_list.planning_application_detail_view.applicant">
            {planningApplication.applicant_name}
          </DetailsList.Item>
          <DetailsList.Item label="planning.planning_application_list.planning_application_detail_view.agentName">
            {planningApplication.agent_name}
          </DetailsList.Item>
          <DetailsList.Item label="planning.planning_application_list.planning_application_detail_view.agentAddress">
            {planningApplication.agent_address}
          </DetailsList.Item>
          <DetailsFooter
            planningAuthority={planningAuthority}
            updatedDate={planningApplication.updated!}
          />
        </div>
      </div>
      {planningApplication.appeal_case_number && (
        <AppealDetails planningApplication={planningApplication} />
      )}
    </Section>
  );
};

interface DetailsHeaderProps {
  planningApplication: PlanningApplication;
  planningAuthority?: string;
}

function DetailsHeader({ planningApplication, planningAuthority }: DetailsHeaderProps) {
  return (
    <div className="atlas-flex atlas-space-between atlas-items-start">
      {planningApplication.appeal_decision && (
        <div>
          <AppealTag appealDecision={planningApplication.appeal_decision} />
        </div>
      )}
      <div className="atlas-ml-auto atlas-gap-1 atlas-flex atlas-shrink-0">
        <ViewApplicationButton
          authorityName={planningAuthority}
          authorityUrl={planningApplication.url}
          appealRef={planningApplication.appeal_case_number}
          applicationRef={planningApplication.ref}
        />
      </div>
    </div>
  );
}

interface DetailsFooterProps {
  planningAuthority?: string;
  updatedDate: string;
}

function DetailsFooter({ planningAuthority, updatedDate }: DetailsFooterProps) {
  const { t } = useTranslation();

  return (
    <div className="atlas-flex atlas-space-x-2 atlas-gap-2 atlas-text-text-base-tertiary atlas-pt-2">
      {planningAuthority && (
        <div className="atlas-text-xs atlas-font-regular">
          <span>
            {t("planning.planning_application_list.planning_application_detail_view.source")}
          </span>
          <span className="atlas-font-semibold">{planningAuthority}</span>
        </div>
      )}
      <div className="atlas-text-xs atlas-font-regular text-neutral-500">
        {t(
          "planning.planning_application_list.planning_application_detail_view.record_last_checked"
        )}
        <span className="atlas-font-semibold">{protectedDateFormatter(updatedDate)}</span>
      </div>
    </div>
  );
}
