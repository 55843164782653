import { router } from "src/js/router/react-router";
import { useCallback, useState } from "react";
import ROUTES from "src/js/router/routes";

export function useToggleList(initialValue: string[] = []) {
  const [list, setList] = useState(initialValue);

  const toggle = useCallback((value: string) => {
    setList((prev) => (prev.includes(value) ? prev.filter((v) => v !== value) : [...prev, value]));
  }, []);

  return [list, toggle] as const;
}

export type TabsState = Record<string, Record<string, boolean>>;

export function useToggleTabs(initialState: TabsState) {
  const [state, setState] = useState(initialState);

  const toggle = useCallback((tabKey: string, layerKey?: string) => {
    setState((prevState) => {
      // This should never happen, means that tabKey is wrong
      if (!prevState[tabKey]) {
        return prevState;
      }

      if (!layerKey) {
        const isTabChecked = Object.values(prevState[tabKey]).every((value) => value);
        return {
          ...prevState,
          [tabKey]: Object.fromEntries(
            Object.entries(prevState[tabKey]).map(([key]) => [key, !isTabChecked])
          ),
        };
      }

      return {
        ...prevState,
        [tabKey]: Object.fromEntries(
          Object.entries(prevState[tabKey]).map(([key, value]) => [
            key,
            key === layerKey ? !value : value,
          ])
        ),
      };
    });
  }, []);

  return [state, toggle] as const;
}

export function usePrintReport(
  siteId: string | undefined,
  assessmentState: TabsState,
  siteOptions: string[]
) {
  return useCallback(() => {
    if (!siteId) return;

    const siteReportPrintViewUrlParams = {
      isPrint: "true",
      siteId: String(siteId),
      activeAssessmentLayers: Object.values(assessmentState)
        .map((tabData) => Object.keys(tabData).filter((key) => tabData[key]))
        .flat()
        .join(","),
      ...(siteOptions?.length && { siteOptions: siteOptions?.join(",") }),
      // ...(customNotes && { notes: customNotes }),
    };
    router.push({
      name: ROUTES.SITE_REPORT,
      query: siteReportPrintViewUrlParams,
    });
  }, [siteId, assessmentState, siteOptions]);
}
