import { isString } from "lodash";
import { HTMLProps, ReactNode, useMemo } from "react";

type FooterType = HTMLProps<HTMLDivElement> & {
  description?: ReactNode;
  descriptionPosition?: "left" | "right";
};
export const Footer = ({ description, descriptionPosition = "left", children }: FooterType) => {
  const title = isString(children) ? children : "";

  const descriptionComponent = useMemo(
    () => description && <div className="">{description}</div>,
    [description]
  );

  return (
    <div className="atlas-flex atlas-gap-1 atlas-text-xs atlas-overflow-hidden">
      {descriptionPosition === "left" ? descriptionComponent : undefined}
      <div
        className="atlas-text-text-base-secondary-a atlas-flex atlas-items-center atlas-cursor-default atlas-overflow-hidden"
        title={title}
      >
        <span className="atlas-truncate atlas-block">{children}</span>
      </div>
      {descriptionPosition === "right" ? descriptionComponent : undefined}
    </div>
  );
};
