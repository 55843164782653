<template>
  <div id="site-report-router-wrapper" class="atlas-h-full atlas-w-full">
    <SiteReportRouteContainer :siteId="siteId" />
  </div>
</template>

<script>
import { SiteReportRouteContainer } from "react-migration/layouts/SiteReport/SiteReportRouteContainer";

export default {
  data() {
    return { siteId: null, activeAssessmentLayers: null, siteOptions: null };
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    this.siteId = urlParams.get("siteId");
  },
  components: {
    SiteReportRouteContainer,
  },
};
</script>

<style scoped>
#site-report-router-wrapper > div {
  height: 100%;
  width: 100%;
}
</style>
