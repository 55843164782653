import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useLocalStorage } from "usehooks-ts";
import { useSnapshot } from "valtio";

import { ENVIRONMENT } from "src/js/util/environment";
import { BundleId } from "src/js/stores/map/store";
import { updateMapSettings } from "src/js/stores/map/actions/updateMapSettings";
import { userStore } from "src/js/stores/user/store";
import { navigationStore } from "src/js/stores/navigation/store";
import {
  setShowImpersonationBanner,
  setShowMultiDeviceBlockModal,
  setShowOverdueInvoiceBanner,
  setShowOverdueInvoiceModal,
  setShowTopupModal,
  setShowTrialEndingModal,
  setShowUpgradeModal,
  setDebugModal,
  setShowAddressModal,
} from "src/js/stores/navigation/actions";

import { showTrial } from "react-migration/domains/nav/util/trial";
import { IMPERSONATION_COOKIE } from "react-migration/domains/nav/util/cookies";
import { LicenseBlockModal } from "react-migration/domains/nav/components/Modals/LicenseBlockModal";
import { OverdueInvoiceBanner } from "react-migration/domains/nav/components/Banners/OverdueInvoiceBanner";
import { ImpersonationBanner } from "react-migration/domains/nav/components/Banners/ImpersonationBanner";
import { MultiDeviceBlockModal } from "react-migration/domains/nav/components/Modals/MultiDeviceBlockModal";
import { OverdueInvoiceModal } from "react-migration/domains/nav/components/Modals/OverdueInvoiceModal";
import { TopupModal } from "react-migration/domains/nav/components/Modals/TopupModal";
import { TrialEndingModal } from "react-migration/domains/nav/components/Modals/TrialEndingModal";
import { UpgradeModal } from "react-migration/domains/nav/components/Modals/UpgradeModal";
import { DebugModal } from "react-migration/domains/nav/components/Modals/DebugModal/DebugModal";
import { BillingAddressModal } from "react-migration/domains/nav/components/Modals/BillingAddressModal";

export const NavbarMessages = () => {
  const { user } = useSnapshot(userStore);
  const {
    showTopupModal,
    showUpgradeModal,
    showTrialEndingModal,
    showOverdueInvoiceModal,
    showMultiDeviceBlockModal,
    showImpersonationBanner,
    showOverdueInvoiceBanner,
    showDebugModal,
    showBillingAddressModal,
    billingAddressModalProps,
  } = useSnapshot(navigationStore);

  const [cookies] = useCookies([IMPERSONATION_COOKIE]);
  const [overdueInvoiceNextReminder, setOverdueInvoiceNextReminder] = useLocalStorage(
    "overdueInvoiceNextReminder",
    Date.now()
  );

  function isExcluded() {
    if (!ENVIRONMENT.PUSHER_EXCLUDED_USERS) {
      return false;
    } else {
      const excludedUsers = ENVIRONMENT.PUSHER_EXCLUDED_USERS.split(",");
      return user.email && excludedUsers.includes(user.email);
    }
  }

  const closeUpgradeModal = () => {
    setShowUpgradeModal(false);
    updateMapSettings("activeSearch", BundleId.BLANK);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setShowTrialEndingModal(showTrial(user));
    }, 3_600_000); // every hour
    setShowOverdueInvoiceModal(
      user.has_overdue_invoices && overdueInvoiceNextReminder <= Date.now()
    );
    setShowMultiDeviceBlockModal(
      !!user._id && !!user._account._id && !!user.email && !isExcluded() && !showImpersonationBanner
    );
    setShowImpersonationBanner(!!cookies.impersonation);
    setShowOverdueInvoiceBanner(user.has_overdue_invoices && showOverdueInvoiceBanner);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* Modals */}
      {showOverdueInvoiceModal && (
        <OverdueInvoiceModal
          closeModal={() => setOverdueInvoiceNextReminder(Date.now() + 12096e5)}
        />
      )}
      {showTrialEndingModal && <TrialEndingModal />}
      {showTopupModal && <TopupModal setShowTopupModal={setShowTopupModal} />}
      {showBillingAddressModal && (
        <BillingAddressModal
          {...billingAddressModalProps}
          onClose={() => setShowAddressModal(false, null)}
        />
      )}
      {showDebugModal && <DebugModal setDebugModal={setDebugModal} />}
      {showUpgradeModal && (
        <UpgradeModal
          onClose={closeUpgradeModal}
          upgradeButtonToPro={user.self_upgrade_options.includes("pro")}
          upgradeButtonToStarterAndPro={user.self_upgrade_options.includes("starter")}
        />
      )}
      {showMultiDeviceBlockModal && <MultiDeviceBlockModal />}
      {user.licence_summary.includes("LT-GO-finder") && <LicenseBlockModal />}
      {/* Banners */}
      {showImpersonationBanner && <ImpersonationBanner />}
      {showOverdueInvoiceBanner && (
        <OverdueInvoiceBanner onClose={() => setShowOverdueInvoiceBanner(false)} />
      )}
    </>
  );
};
