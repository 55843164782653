import { ComponentProps, useState } from "react";

import { MapState, mapStore } from "src/js/stores/map/store";
import Feature from "src/js/stores/user/Feature";

import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { router } from "src/js/router/react-router";
import ROUTES from "src/js/router/routes";

import { Button } from "react-migration/components/Button";
import { Navbar } from "react-migration/domains/nav/components/Navbar";
import hasFeature from "src/js/stores/user/actions/hasFeature";
import { DashboardAnchor } from "react-migration/layouts/nav/components/DashboardAnchor/DashboardAnchor";
import { useSnapshot } from "valtio";
import { NavbarMessages } from "react-migration/layouts/nav/components/Messages/NavbarMessages";
import { UpgradeButton } from "react-migration/components/UpgradeButton";
import { CommunityButton } from "react-migration/domains/nav/components/Actions/CommunityButton";
import { HelpMenu } from "react-migration/domains/nav/components/Actions/HelpMenu";
import { UserMenu } from "react-migration/domains/nav/components/UserMenu";
import { updateMapSettings } from "src/js/stores/map/actions/updateMapSettings";
import hasBetaFeature from "src/js/stores/user/actions/hasBetaFeature";
import { updateSelection } from "src/js/stores/map/actions/updateSelection";
import { updateExpandedLHS } from "src/js/stores/map/actions/updateExpandedLHS";

export const NavbarLayout = ({ children }: ComponentProps<"div">) => {
  const { settings } = useSnapshot(mapStore);
  const distractionFree = settings.state === MapState.DISTRACTION_FREE;
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);

  const mapActive =
    window.location.pathname.includes("/map") ||
    window.location.pathname.includes("/search") ||
    window.location.pathname.includes("/legacy-search");

  return (
    <Navbar.Wrapper>
      <NavbarMessages />
      <Navbar.Left>
        <Navbar.Logo
          onClick={() => {
            if (!mapActive) router.push({ name: ROUTES.MAP });
            updateSelection(undefined);
            updateExpandedLHS(false);
          }}
        />
        {hasBetaFeature(Feature.mobileFullAccess) && (
          <>
            <i
              className="atlas-block md:atlas-hidden atlas-cursor-pointer icon-lt-menu-2-line atlas-text-xl atlas-pt-2.5 atlas-px-2"
              onClick={() => setOpen(!isOpen)}
            />
            {isOpen && (
              <div
                className="atlas-absolute atlas-top-12 atlas-w-full"
                data-testid="mobile-nav-items-wrapper"
              >
                <div className="atlas-w-full atlas-bg-white atlas-drop-shadow-xl atlas-rounded-b-lg">
                  <Navbar.Item
                    data-testid="navbar-dashboard"
                    active={window.location.pathname.includes("/dashboard")}
                    leadIcon="lt-dashboard-2-line"
                    onClick={() => router.push({ name: ROUTES.DASHBOARD })}
                    mobile={true}
                  >
                    {t("nav.nav.dashboard")}
                  </Navbar.Item>
                  <Navbar.Item
                    data-testid="navbar-map"
                    active={
                      window.location.pathname.includes("/map") ||
                      window.location.pathname.includes("/search") ||
                      window.location.pathname.includes("/legacy-search")
                    }
                    leadIcon="lt-map-2-line"
                    onClick={() => router.push({ name: ROUTES.MAP })}
                    mobile={true}
                  >
                    {t("nav.nav.map")}
                  </Navbar.Item>
                  <Navbar.Item
                    data-testid="navbar-sites"
                    active={window.location.pathname.includes("/sites")}
                    leadIcon="lt-community-line"
                    onClick={() => router.push({ name: ROUTES.SITES })}
                    mobile={true}
                  >
                    {t("nav.nav.sites")}
                  </Navbar.Item>
                </div>
              </div>
            )}
          </>
        )}
        {!distractionFree && (
          <>
            <DashboardAnchor />
            <Navbar.Item
              data-testid="navbar-map"
              active={
                window.location.pathname.includes("/map") ||
                window.location.pathname.includes("/search") ||
                window.location.pathname.includes("/legacy-search") ||
                !window.location.pathname
              }
              onClick={() => router.push({ name: ROUTES.MAP })}
              leadIcon="lt-map-2-line"
            >
              {t("nav.nav.map")}
            </Navbar.Item>
            <Navbar.Item
              data-testid="navbar-sites"
              active={window.location.pathname.includes("/sites")}
              onClick={() => router.push({ name: ROUTES.SITES })}
              leadIcon="lt-community-line"
            >
              {t("nav.nav.sites")}
            </Navbar.Item>
          </>
        )}
      </Navbar.Left>
      {distractionFree && <Navbar.Center>{t("nav.nav.distraction_free_mode")}</Navbar.Center>}
      {!distractionFree && <Navbar.Center>{children}</Navbar.Center>}
      <Navbar.Right>
        {!distractionFree && (
          <>
            <div className="atlas-hidden md:atlas-inline-flex">
              <UpgradeButton source="Navbar" />
            </div>
            {hasFeature(Feature.hivebriteAccess) && !hasFeature(Feature.usAccess) && (
              <CommunityButton />
            )}
            <HelpMenu />
            <UserMenu />
          </>
        )}
        {distractionFree && (
          <Button onClick={() => updateMapSettings("state", MapState.NORMAL)}>
            {t("nav.nav.exit_distraction_mode")}
          </Button>
        )}
      </Navbar.Right>
    </Navbar.Wrapper>
  );
};
