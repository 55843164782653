import { useCallback, useContext } from "react";
import { SelectFilterModal, SelectFilterPopover } from "react-migration/components/SelectFilter";
import { useSiteLocalAuthorityOptions } from "react-migration/domains/sites/hooks/useSiteLocalAuthorityOptions";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { SiteFiltersContext } from "../SiteFilters";

type SiteLocalAuthorityFilterProps = {
  className?: string;
  type: "mobile" | "desktop";
  openModal?: boolean;
  setOpenModal?: (v: boolean) => void;
};
export const SiteLocalAuthorityFilter = ({
  className,
  type,
  openModal,
  setOpenModal,
}: SiteLocalAuthorityFilterProps) => {
  const { t } = useTranslation();

  const { onFilterUpdate, useLocalAuthorities, updateLocalAuthorities } =
    useContext(SiteFiltersContext);

  const siteLocalAuthorityOptions = useSiteLocalAuthorityOptions();
  const selectedLocalAuthorityIds = useLocalAuthorities();

  const onSelectedOptionsUpdate = useCallback(
    (optionIds: string[]) => {
      onFilterUpdate("localAuthorities");
      updateLocalAuthorities(optionIds);
    },
    [onFilterUpdate, updateLocalAuthorities]
  );

  if (siteLocalAuthorityOptions.length > 0) {
    if (type === "desktop") {
      return (
        <SelectFilterPopover
          dataTestId="local-authority-select-filter"
          label={t("sites.pipeline.filters.local_authority")}
          contentTitle={t("sites.pipeline.filters.select_local_authorities")}
          options={siteLocalAuthorityOptions}
          selectedOptionIds={selectedLocalAuthorityIds}
          onSelectedOptionsUpdate={onSelectedOptionsUpdate}
          className={className}
        />
      );
    }
    if (type === "mobile" && openModal && setOpenModal) {
      return (
        <SelectFilterModal
          contentTitle={t("sites.pipeline.filters.select_local_authorities")}
          options={siteLocalAuthorityOptions}
          selectedOptionIds={selectedLocalAuthorityIds}
          onSelectedOptionsUpdate={onSelectedOptionsUpdate}
          open={openModal}
          setOpen={setOpenModal}
        />
      );
    }
  } else {
    return null;
  }
};
