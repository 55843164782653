import { useMemo } from "react";
import { useSnapshot } from "valtio/react";
import { TextWithVariants } from "../TextWithVariants";
import { userStore } from "src/js/stores/user/store";
import { getLengthVariants } from "react-migration/lib/util/getAreaAndPerimeter";

interface UserUnitLengthProps {
  lengthMeters?: number;
  prefix?: string;
}

export function useUserLengthVariants(lengthMeters: number | undefined) {
  const { user } = useSnapshot(userStore);

  return useMemo(() => {
    const isLarge = lengthMeters && lengthMeters > 1000;
    const variants = getLengthVariants(lengthMeters, user.settings.unit);

    return isLarge ? variants.slice().reverse() : variants;
  }, [lengthMeters, user.settings.unit]);
}

export function UserUnitLength({ lengthMeters, prefix }: UserUnitLengthProps) {
  const lengthMetersVariants = useUserLengthVariants(lengthMeters);

  return <TextWithVariants variants={lengthMetersVariants} prefix={prefix} />;
}
