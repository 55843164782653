import { Alert } from "react-migration/components/Alert";
import { useSelectionSidebarLoading } from "../../../Bundle/useSelectionSidebarLoadingState";
import { LayerTypeLayerSummaryProps, SupportedRegions } from "../../../types";
import { usePlanningDetailSelectionHandler } from "./usePlanningDetailSelectionHandler";
import { useRecentApplicationsBySelection } from "./useRecentApplicationsBySelection";
import { RecentApplicationsInfoTooltip } from "./RecentApplicationsInfoTooltip";
import { useRecentPlanningKeyInsights } from "./useRecentPlanningKeyInsights";
import { MostRecentSection } from "./MostRecentSection";
import { KeyStatsSection } from "./KeyStatsSection";
import { LoadingSection } from "./LoadingSection";

type RecentApplicationsLayerSummaryProps = LayerTypeLayerSummaryProps & {
  insideSummaryAccordion?: boolean;
};

const Tooltip = () => {
  return (
    <h1 className="atlas-text-sm atlas-font-medium atlas-text-text-base-primary">
      Most recent relevant applications <RecentApplicationsInfoTooltip />
    </h1>
  );
};

export const RecentApplicationsLayerSummary = ({
  selection,
  onLoadingChange,
  insideSummaryAccordion = false,
}: RecentApplicationsLayerSummaryProps) => {
  const { applications, loading, error } = useRecentApplicationsBySelection(selection);
  const planningDetailSelectionHandler = usePlanningDetailSelectionHandler();

  useSelectionSidebarLoading(onLoadingChange, loading);
  useRecentPlanningKeyInsights(applications, loading);

  if (error) {
    // caught by ErrorBoundary
    throw error;
  }

  if (loading) {
    return (
      <RecentApplicationsContainer
        tooltip={<Tooltip />}
        mostRecentSlot={<LoadingSection rows={1} height="184px" />}
        keyStatsSlot={<LoadingSection rows={2} height="164px" />}
      />
    );
  }

  if (!applications?.length) {
    return <Alert.Info>No recent applications found for this selection</Alert.Info>;
  }

  const showKeyStats = applications.length > 1;

  return (
    <RecentApplicationsContainer
      tooltip={<Tooltip />}
      mostRecentSlot={
        <MostRecentSection
          applications={applications}
          onTitleClick={planningDetailSelectionHandler}
          insideSummaryAccordion={insideSummaryAccordion}
        />
      }
      keyStatsSlot={showKeyStats && <KeyStatsSection applications={applications} />}
    />
  );
};

RecentApplicationsLayerSummary.supportedRegions = [
  SupportedRegions.england,
  SupportedRegions.scotland,
  SupportedRegions.wales,
];

// decorate with insideSummaryAccordion so the events can use this context
export const RecentApplicationsLayerSummaryAccordionContent = (
  props: LayerTypeLayerSummaryProps
) => <RecentApplicationsLayerSummary {...props} insideSummaryAccordion />;

RecentApplicationsLayerSummaryAccordionContent.icon = "icon-lt-archive-line";

interface LayoutProps {
  mostRecentSlot: JSX.Element;
  tooltip: JSX.Element;
  keyStatsSlot: false | JSX.Element;
}

export function RecentApplicationsContainer({
  mostRecentSlot,
  keyStatsSlot,
  tooltip,
}: LayoutProps) {
  return (
    <>
      <article className="atlas-flex atlas-flex-col atlas-gap-y-4">
        <section className="atlas-flex atlas-flex-col atlas-gap-y-4">
          {tooltip}
          {mostRecentSlot}
        </section>
        {keyStatsSlot && (
          <section className="atlas-flex atlas-flex-col atlas-gap-y-4">{keyStatsSlot}</section>
        )}
      </article>
      {/* <PlanningSummaryLearnMore /> */}
    </>
  );
}
