import {
  KeyIndicatorColor,
  useKeyIndicatorTags,
} from "react-migration/lib/map/useKeyIndicatorTags";
import { SkeletonLoading } from "react-migration/components/SkeletonLoading";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { LayerTypeLayerSummaryProps, SupportedRegions } from "../../../types";
import { Insight } from "react-migration/components/Insight/Insight";
import { useListedBuildingsByGeometry } from "../../SummaryLayerType/useDesignationsSnapshotByGeometry";
import { useSelectionSidebarLoading } from "../../../Bundle/useSelectionSidebarLoadingState";

export function ListedBuildingSummary({ selection, onLoadingChange }: LayerTypeLayerSummaryProps) {
  const { t } = useTranslation();
  const [listedBuildings, loading, error] = useListedBuildingsByGeometry(
    selection.feature?.geometry || null
  );

  useSelectionSidebarLoading(onLoadingChange, loading);

  useKeyIndicatorTags(() => {
    return [
      {
        color:
          listedBuildings?.isListedBuilding.length || listedBuildings?.nearbyListedBuildings.length
            ? KeyIndicatorColor.RED
            : KeyIndicatorColor.GREEN,
        tag:
          listedBuildings?.isListedBuilding.length || listedBuildings?.nearbyListedBuildings.length
            ? "Yes"
            : "No",
      },
    ];
  }, [listedBuildings?.isListedBuilding.length, listedBuildings?.nearbyListedBuildings.length]);

  if (loading) {
    return (
      <div className="atlas-relative atlas-p-2 atlas-h-[168px]">
        <SkeletonLoading rows={2} showPadding={false} bgClassName="atlas-bg-transparent" />
      </div>
    );
  }

  if (error) {
    // caught by error boundary
    throw error;
  }

  return (
    <>
      <div className="atlas-grid atlas-grid-cols-2 atlas-gap-2">
        <Insight.Wrapper title={t("workbench.summary.listed_buildings.on_site.title")}>
          <Insight.Body>{listedBuildings?.isListedBuilding.length}</Insight.Body>
        </Insight.Wrapper>
        <Insight.Wrapper title={t("workbench.summary.listed_buildings.nearby.title")}>
          <Insight.Body>{listedBuildings?.nearbyListedBuildings.length}</Insight.Body>
        </Insight.Wrapper>
      </div>
    </>
  );
}
ListedBuildingSummary.icon = "icon-lt-bank-line";
ListedBuildingSummary.supportedRegions = [
  SupportedRegions.england,
  SupportedRegions.scotland,
  SupportedRegions.wales,
];
