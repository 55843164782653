import Feature from "src/js/stores/user/Feature";
import {
  UKTopographyLayerType,
  TopographySlopeLayerType,
  TopographyAspectLayerType,
} from "../layer_types/TopographyLayerType";
import { Layer } from "../types";

export const TOPOGRAPHY_LAYER_UK: Layer = {
  id: "TopographyLayer",
  layerType: UKTopographyLayerType,
  requiredFeatureAccess: [Feature.topographyUK],
  description: "Topography",
  title: "Topography",
  hideByDefault: true,
  toggleable: true,
  collapsed: true,
  label: {
    color: "BLUE",
    translationKey: "map.explore.label.new",
  },
};

export const TOPOGRAPHY_INSIGHT_SLOPE_SUMMARY: Layer = {
  ...TOPOGRAPHY_LAYER_UK,
  title: "Slope",
  id: "TopographySlopeSummary",
  layerType: TopographySlopeLayerType,
};

export const TOPOGRAPHY_INSIGHT_ASPECT_SUMMARY: Layer = {
  ...TOPOGRAPHY_LAYER_UK,
  title: "Aspect",
  id: "TopographyAspectSummary",
  layerType: TopographyAspectLayerType,
};
