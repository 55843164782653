export const US_DRINKING_WATER_ROOT = "us_drinking_water";
export const US_WASTE_WATER_ROOT = "us_waste_water";

export const US_DRINKING_WATER_UNKNOWN_CATEGORIES = ["us_dw_unknown"];
export const US_DRINKING_WATER_KNOWN_CATEGORIES = ["us_dw_known_public", "us_dw_known_well"];
export const US_DRINKING_WATER_LIKELY_CATEGORIES = ["us_dw_likely_public", "us_dw_likely_well"];
export const US_DRINKING_WATER_CONFLICTING_CATEGORIES = ["us_dw_conflicting"];

export const US_DRINKING_WATER_CATEGORIES = [
  ...US_DRINKING_WATER_UNKNOWN_CATEGORIES,
  ...US_DRINKING_WATER_KNOWN_CATEGORIES,
  ...US_DRINKING_WATER_LIKELY_CATEGORIES,
  ...US_DRINKING_WATER_CONFLICTING_CATEGORIES,
];

export const US_WASTE_WATER_UNKNOWN_CATEGORIES = ["us_ww_unknown"];
export const US_WASTE_WATER_KNOWN_CATEGORIES = ["us_ww_known_sewer", "us_ww_known_septic"];
export const US_WASTE_WATER_LIKELY_CATEGORIES = ["us_ww_likely_sewer", "us_ww_likely_septic"];
export const US_WASTE_WATER_CONFLICTING_CATEGORIES = ["us_ww_conflicting"];

export const US_WASTE_WATER_CATEGORIES = [
  ...US_WASTE_WATER_UNKNOWN_CATEGORIES,
  ...US_WASTE_WATER_KNOWN_CATEGORIES,
  ...US_WASTE_WATER_LIKELY_CATEGORIES,
  ...US_WASTE_WATER_CONFLICTING_CATEGORIES,
];

export const ALL_US_WATER_CATEGORIES = [
  ...US_DRINKING_WATER_CATEGORIES,
  ...US_WASTE_WATER_CATEGORIES,
];
