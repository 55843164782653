import { AllGeoJSON } from "@turf/helpers";
import { MeasurementSystem } from "src/js/stores/user/store";
import { area as areaConverter, length } from "src/js/util/units";
import turfArea from "@turf/area";
import turfLength from "@turf/length";

export type MeasurementVariants = [string, string];

export function getGeometryMeasurements(
  geometry: AllGeoJSON,
  measurementSystem: MeasurementSystem
) {
  if (!geometry) return;

  const area = turfArea(geometry);
  const perimeter = turfLength(geometry, { units: "meters" });

  return {
    areaIsLarge: !!(area && area > 1000),
    areaVariants: getAreaVariants(area, measurementSystem),
    perimeterIsLarge: !!(perimeter && perimeter > 1000),
    perimeterVariants: getLengthVariants(perimeter, measurementSystem),
  } as const;
}

type GetAreaVariants = (
  areaInMeters: number | string | undefined,
  measurementSystem: MeasurementSystem
) => MeasurementVariants;

export const getAreaVariants: GetAreaVariants = (areaInMeters, measurementSystem) => [
  areaConverter.format(Number(areaInMeters), { unitType: measurementSystem, small: true }),
  areaConverter.format(Number(areaInMeters), { unitType: measurementSystem, small: false }),
];

type GetLengthVariants = (
  perimeterInMeters: number | string | undefined,
  measurementSystem: MeasurementSystem
) => MeasurementVariants;

export const getLengthVariants: GetLengthVariants = (lengthInMeters, measurementSystem) => [
  length.format(Number(lengthInMeters), { unitType: measurementSystem, small: true }),
  length.format(Number(lengthInMeters), { unitType: measurementSystem, small: false }),
];
