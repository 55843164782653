import { HTMLProps, useCallback, useEffect, useMemo, useState } from "react";
import * as Popover from "@radix-ui/react-popover";
import classNames from "classnames";

import { Button } from "react-migration/components/DeprecatedButton";
import { Icon } from "react-migration/components/Icon";
import { TextInputV2 } from "../TextInputV2";

import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { ActionBar } from "./ActionBar";
import { SelectOptionItem } from "./SelectOptionItem";
import { ModalV2 } from "../ModalV2/ModalV2";

export type SelectOption = {
  id: string;
  name: string;
  color?: string;
};

export type SelectFilterPopoverProps = {
  label: string;
  labelClassName?: string;
  contentTitle: string;
  options: SelectOption[];
  selectedOptionIds: string[];
  onSelectedOptionsUpdate: (optionIds: string[]) => void;
  className?: string;
  dataTestId?: string;
};

export const SelectFilterPopover = ({
  label,
  labelClassName,
  contentTitle,
  options,
  selectedOptionIds,
  onSelectedOptionsUpdate,
  className,
  dataTestId = "select-filter",
}: SelectFilterPopoverProps) => {
  const [open, setOpen] = useState(false);

  return (
    <div
      data-testid={dataTestId}
      className={classNames(
        "atlas-flex",
        "atlas-flex-row",
        "atlas-flex-nowrap",
        "atlas-items-center",
        className
      )}
    >
      <span
        className={classNames("atlas-text-text-base-secondary-a", "atlas-mr-2", labelClassName)}
        data-testid="select-filter-label"
      >
        {label}
      </span>
      <Popover.Root open={open} onOpenChange={setOpen}>
        <Popover.Trigger data-testid="select-filter-dropdown-trigger" asChild>
          <div>
            <SelectFilterStatus selectedOptionIds={selectedOptionIds} />
          </div>
        </Popover.Trigger>

        <Popover.Portal>
          <Popover.Content
            data-testid="select-filter-dropdown-content"
            sideOffset={5}
            side="bottom"
            align="start"
            asChild
          >
            <div className="atlas-z-10 atlas-shadow-md atlas-rounded-lg atlas-bg-white">
              <div
                data-testid="select-filter-dropdown-title"
                className="atlas-flex atlas-flex-col atlas-p-3 atlas-m-1"
              >
                <h1 className="atlas-text-sm atlas-font-semibold atlas-text-content-primary atlas-cursor-default atlas-truncate">
                  {contentTitle}
                </h1>
              </div>
              <SelectFilterContent
                options={options}
                selectedOptionIds={selectedOptionIds}
                onSelectedOptionsUpdate={onSelectedOptionsUpdate}
                setOpen={setOpen}
                className="atlas-flex atlas-flex-col atlas-overflow-hidden atlas-w-[300px] atlas-max-h-[444px]"
              />
            </div>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  );
};

export type SelectFilterModalProps = {
  contentTitle: string;
  options: SelectOption[];
  selectedOptionIds: string[];
  onSelectedOptionsUpdate: (optionIds: string[]) => void;
  open: boolean;
  setOpen: (v: boolean) => void;
};
export const SelectFilterModal = ({
  contentTitle,
  options,
  selectedOptionIds,
  onSelectedOptionsUpdate,
  open,
  setOpen,
}: SelectFilterModalProps) => {
  return (
    <ModalV2.Root open={open} onOpenChange={() => setOpen(false)}>
      <ModalV2.Body
        title={contentTitle}
        className="!atlas-z-[1000]"
        contentClassName="!atlas-max-w-[600px]"
      >
        <SelectFilterContent
          options={options}
          selectedOptionIds={selectedOptionIds}
          onSelectedOptionsUpdate={onSelectedOptionsUpdate}
          setOpen={setOpen}
          className="atlas-flex atlas-flex-col atlas-overflow-hidden atlas-max-h-[60vh]"
        />
      </ModalV2.Body>
    </ModalV2.Root>
  );
};

export const SelectFilterStatus = ({ selectedOptionIds }: { selectedOptionIds: string[] }) => {
  const { t } = useTranslation();

  const triggerText = useMemo(() => {
    if (!selectedOptionIds.length) {
      return t("sites.pipeline.filters.all");
    }
    return t("sites.pipeline.filters.number_of_filters_selected", {
      selectedFiltersCount: selectedOptionIds.length,
    });
  }, [selectedOptionIds.length, t]);

  return (
    <span
      className={classNames(
        "atlas-font-semibold",
        "atlas-px-2",
        "atlas-py-1",
        "atlas-rounded",
        "atlas-text-content-primary",
        "atlas-flex",
        "atlas-items-center",
        "atlas-justify-center",
        "hover:atlas-bg-background-button-secondary-hover"
      )}
    >
      {triggerText}
      <Icon
        cursor="pointer"
        data-testid="site-options-trigger-icon"
        icon="icon-lt-arrow-down-s-fill"
        size="sm"
        extraClasses="atlas-ml-1"
      />
    </span>
  );
};

export type SelectFilterContentProps = HTMLProps<HTMLDivElement> & {
  options: SelectOption[];
  selectedOptionIds: string[];
  onSelectedOptionsUpdate: (optionIds: string[]) => void;
  setOpen?: (v: boolean) => void;
};

export const SelectFilterContent = ({
  options,
  selectedOptionIds,
  onSelectedOptionsUpdate,
  setOpen,
  ...rest
}: SelectFilterContentProps) => {
  const { t } = useTranslation();

  const [filterValue, setFilterValue] = useState("");
  const [activeOptionIds, setActiveOptionIds] = useState<string[]>(selectedOptionIds);

  useEffect(() => {
    setActiveOptionIds(selectedOptionIds);
  }, [selectedOptionIds]);

  const onCancel = () => {
    setActiveOptionIds(selectedOptionIds);
    setOpen && setOpen(false);
  };

  const onApply = () => {
    onSelectedOptionsUpdate(
      options.map(({ id }) => id).filter((id) => activeOptionIds.includes(id))
    );
    setOpen && setOpen(false);
  };

  const onClearFilterClick = () => {
    setActiveOptionIds([]);
  };

  const onSelectAllToggle = useCallback(() => {
    if (activeOptionIds.length !== options.length) {
      setActiveOptionIds(options.map(({ id }) => id));
    } else {
      setActiveOptionIds([]);
    }
  }, [activeOptionIds.length, options]);

  const onOptionToggle = useCallback(
    (optionId: string) => {
      if (activeOptionIds.includes(optionId)) {
        setActiveOptionIds(activeOptionIds.filter((id) => id !== optionId));
      } else {
        setActiveOptionIds([...activeOptionIds, optionId]);
      }
    },
    [activeOptionIds]
  );

  return (
    <div {...rest}>
      <div data-testid="select-filter-search" className="atlas-pb-2 atlas-pt-0 atlas-px-3">
        <TextInputV2
          size="medium"
          dataTestId="select-filter-search-input"
          placeholder={t("sites.pipeline.filters.search")}
          IconLeft={<i className="search-icon atlas-text-base icon-lt-search-line" />}
          value={filterValue}
          onChange={(e) => setFilterValue(e.target.value)}
        />
      </div>

      <div className="atlas-justify-start">
        <Button.Borderless
          data-testid="select-filter-clear-button"
          disabled={activeOptionIds.length === 0}
          className={classNames("atlas-text-content-action", "atlas-my-2")}
          onClick={onClearFilterClick}
        >
          {t("sites.pipeline.filters.clear_filter")}
        </Button.Borderless>
      </div>

      <SelectOptionItem
        id="all"
        name={t("sites.pipeline.filters.select_all")}
        isSelected={activeOptionIds.length === options.length}
        onToggle={onSelectAllToggle}
      />

      <div
        className={classNames(
          "atlas-pl-4",
          "atlas-flex",
          "atlas-overflow-scroll",
          "atlas-flex-col"
        )}
        data-testid="select-filter-option-list"
      >
        {options
          .filter(({ name }) => {
            const filter = new RegExp(filterValue, "i");
            return filter.test(name);
          })
          .sort(({ name: a }, { name: b }) => {
            if (a < b) return -1;
            if (a > b) return 1;
            return 0;
          })
          .map(({ id, name, color }) => (
            <SelectOptionItem
              key={id}
              id={id}
              name={name}
              color={color}
              isSelected={activeOptionIds.includes(id)}
              onToggle={onOptionToggle}
            />
          ))}
      </div>
      <ActionBar onApply={onApply} onCancel={onCancel} />
    </div>
  );
};
