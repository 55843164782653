import { useMemo } from "react";
import { QueryHookOptions, useQuery } from "@apollo/client";
import buffer from "@turf/buffer";
import { SelectionGeometry } from "src/js/stores/map/store";
import { LandTechEndpoints, routedClient } from "react-migration/lib/persistence/apollo";
import DESIGNATION_BY_AREA_QUERY from "../../apollo/designationAreaByGeometry.gql";

export interface DesignationArea {
  display_name: string;
  sub_category: string;
  category_coverage_percentage: number;
  category_coverage_area: number;
  category_geometry: string;
  designation_display_name: string;
}

interface DesignationAreaByGeometryResult {
  designationsAreaByGeometry: DesignationArea[];
}

interface DesignationAreaByGeometryVariables {
  geometry: SelectionGeometry;
  subCategories: string[];
}

export function useDesignationAreaByGeometry(
  geometry: SelectionGeometry | null | undefined,
  subCategories: string[],
  opts?: Partial<
    QueryHookOptions<DesignationAreaByGeometryResult, DesignationAreaByGeometryVariables>
  >
) {
  const bufferedGeometry = useMemo(() => {
    return geometry && bufferGeometry(geometry);
  }, [geometry]);

  const { data, ...rest } = useQuery<
    DesignationAreaByGeometryResult,
    DesignationAreaByGeometryVariables
  >(DESIGNATION_BY_AREA_QUERY, {
    variables: {
      geometry: bufferedGeometry!,
      subCategories: subCategories,
    },
    client: routedClient,
    context: {
      endpoint: LandTechEndpoints.Constraints,
    },
    ...opts,
    skip: !bufferedGeometry || opts?.skip,
  });

  return {
    ...rest,
    data: data?.designationsAreaByGeometry,
  };
}

function bufferGeometry(geometry: SelectionGeometry): SelectionGeometry {
  if (["Polygon", "MultiPolygon"].includes(geometry.type)) return geometry;

  return buffer(geometry, 1, { units: "meters" }).geometry;
}
