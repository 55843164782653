import { useContext, useCallback } from "react";
import { SelectFilterModal, SelectFilterPopover } from "react-migration/components/SelectFilter";
import { useSiteAssigneeOptions } from "react-migration/domains/sites/hooks/useSiteAssigneeOptions";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { SiteFiltersContext } from "../SiteFilters";

type AssigneesFilterProps = {
  className?: string;
  type: "mobile" | "desktop";
  openModal?: boolean;
  setOpenModal?: (v: boolean) => void;
};
export const AssigneesFilter = ({
  className,
  type,
  openModal,
  setOpenModal,
}: AssigneesFilterProps) => {
  const { t } = useTranslation();
  const { onFilterUpdate, useAssignees, updateAssignees } = useContext(SiteFiltersContext);

  const siteAssigneeOptions = useSiteAssigneeOptions();
  const selectedAssigneeIds = useAssignees();

  const onSelectedOptionsUpdate = useCallback(
    (optionIds: string[]) => {
      onFilterUpdate("assignees");
      updateAssignees(optionIds);
    },
    [onFilterUpdate, updateAssignees]
  );

  if (siteAssigneeOptions.length > 0) {
    if (type === "desktop") {
      return (
        <SelectFilterPopover
          dataTestId="assignee-select-filter"
          label={t("sites.pipeline.filters.assignee")}
          contentTitle={t("sites.pipeline.filters.select_assignees")}
          options={siteAssigneeOptions}
          selectedOptionIds={selectedAssigneeIds}
          onSelectedOptionsUpdate={onSelectedOptionsUpdate}
          className={className}
        />
      );
    }
    if (type === "mobile" && openModal && setOpenModal) {
      return (
        <SelectFilterModal
          contentTitle={t("sites.pipeline.filters.select_assignees")}
          options={siteAssigneeOptions}
          selectedOptionIds={selectedAssigneeIds}
          onSelectedOptionsUpdate={onSelectedOptionsUpdate}
          open={openModal}
          setOpen={setOpenModal}
        />
      );
    }
  } else {
    return null;
  }
};
