import { useEffect, useMemo, useState } from "react";
import { Accordion } from "react-migration/components/Accordion";
import { PlanningApplication } from "react-migration/domains/planning/types";
import { applicationsByAddress } from "../SelectionSidebar/applicationsByAddress";
import { Nullable } from "src/js/types/Nullable";
import { PlanningApplicationList } from "react-migration/domains/planning/components/PlanningApplicationList";
import cn from "classnames";
import { ExpandibleList } from "react-migration/components/ExpandibleList";
import { PlanningApplicationClassification } from "react-migration/domains/planning/components/PlanningApplicationClassification";

function usePlanningApplicationsByAddress<T extends PlanningApplication>(
  planningApplications: Nullable<T[]>
) {
  return useMemo(() => {
    if (planningApplications?.length)
      return applicationsByAddress(planningApplications as unknown as PlanningApplication[]);
  }, [planningApplications]);
}

interface PlanningApplicationsByAddressProps<T extends PlanningApplication = PlanningApplication> {
  planningApplications: T[];
  onApplicationClick?: (application: T) => void;
}

export function PlanningApplicationsByAddress({
  planningApplications,
  onApplicationClick,
}: PlanningApplicationsByAddressProps) {
  const applicationsByAddress = usePlanningApplicationsByAddress(planningApplications);

  const [expandedAccordionItems, setExpandedAccordionItems] = useState<string[]>([]);

  useEffect(() => {
    if (applicationsByAddress?.length === 1) {
      setExpandedAccordionItems([applicationsByAddress[0].address]);
    } else {
      setExpandedAccordionItems([]);
    }
  }, [applicationsByAddress]);

  if (!applicationsByAddress) return null;

  return (
    <Accordion
      value={expandedAccordionItems}
      onValueChange={setExpandedAccordionItems}
      type="multiple"
    >
      <ExpandibleList limit={8} itemsName="addresses">
        {applicationsByAddress.map(({ address, applications, classifications }) => (
          <Accordion.Item key={address} value={address} className="atlas-group">
            <Accordion.Trigger asChild>
              <div
                className="atlas-flex atlas-gap-1 atlas-items-center atlas-px-2 atlas-py-0.5"
                data-testid="planning-selection-sidebar-accordion-trigger"
              >
                <div className="atlas-grow atlas-text-left atlas-truncate atlas-text-ellipsis atlas-text-xs atlas-font-medium">
                  {address}
                </div>
                <div className="atlas-shrink-0 atlas-flex atlas-gap-1 atlas-items-center">
                  <>
                    {[...classifications]
                      .sort((a, b) => a.localeCompare(b))
                      .map((classification) => (
                        <PlanningApplicationClassification
                          key={classification}
                          classification={classification}
                        />
                      ))}
                    <i
                      className={cn("atlas-text-2xl", {
                        "icon-lt-arrow-up-s-line": expandedAccordionItems.includes(address),
                        "icon-lt-arrow-down-s-line": !expandedAccordionItems.includes(address),
                      })}
                    />
                  </>
                </div>
              </div>
            </Accordion.Trigger>
            <Accordion.Content className="atlas-border-t atlas-border-neutral-200">
              <PlanningApplicationList
                onApplicationClick={onApplicationClick}
                planningApps={applications}
              />
            </Accordion.Content>
          </Accordion.Item>
        ))}
      </ExpandibleList>
    </Accordion>
  );
}
