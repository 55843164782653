import { useMemo } from "react";
import { useDesignationAreaByGeometry } from "react-migration/domains/constraints/designation/hooks/useDesignationAreaByGeometry";
import { SkeletonLoading } from "react-migration/components/SkeletonLoading";
import { Insight } from "react-migration/components/Insight/Insight";
import { useSelectionSidebarLoading } from "../../../Bundle/useSelectionSidebarLoadingState";
import { LayerTypeLayerSummaryProps, SupportedRegions } from "../../../types";
import { useUSUtilityStatusKeyIndicators } from "./useKeyIndicators";
import { areaWithLargestCoverage } from "./areaWithLargestCoverage";
import {
  ALL_US_WATER_CATEGORIES,
  US_DRINKING_WATER_CATEGORIES,
  US_WASTE_WATER_CATEGORIES,
} from "./constants";

export function USUtilityStatusSummary({ selection, onLoadingChange }: LayerTypeLayerSummaryProps) {
  const {
    data: areas,
    error,
    loading,
  } = useDesignationAreaByGeometry(selection?.feature?.geometry, ALL_US_WATER_CATEGORIES);

  const { drinkingArea, wasteArea } = useMemo(() => {
    const drinkingAreas = areas?.filter((area) =>
      US_DRINKING_WATER_CATEGORIES.includes(area.sub_category)
    );

    const wasteAreas = areas?.filter((area) =>
      US_WASTE_WATER_CATEGORIES.includes(area.sub_category)
    );

    return {
      drinkingArea: areaWithLargestCoverage(drinkingAreas),
      wasteArea: areaWithLargestCoverage(wasteAreas),
    };
  }, [areas]);

  useSelectionSidebarLoading(onLoadingChange, loading);
  useUSUtilityStatusKeyIndicators(drinkingArea, wasteArea);

  if (loading) {
    return (
      <div className="atlas-h-[86px] atlas-grid atlas-grid-cols-2 atlas-gap-x-2">
        <div className="atlas-relative">
          <SkeletonLoading
            rows={1}
            roundedSize="xl"
            showPadding={false}
            bgClassName="atlas-bg-transparent"
          />
        </div>
        <div className="atlas-relative">
          <SkeletonLoading
            rows={1}
            roundedSize="xl"
            showPadding={false}
            bgClassName="atlas-bg-transparent"
          />
        </div>
      </div>
    );
  }

  if (error) {
    // caught by error boundary
    throw error;
  }

  return (
    <div className="atlas-flex atlas-flex-col atlas-gap-y-4">
      <div className="atlas-grid atlas-grid-cols-2 atlas-gap-x-2">
        <Insight.Wrapper title="Drinking water">
          <Insight.Body>{drinkingArea?.display_name ?? "N/A"}</Insight.Body>
        </Insight.Wrapper>
        <Insight.Wrapper title="Waste water">
          <Insight.Body>{wasteArea?.display_name ?? "N/A"}</Insight.Body>
        </Insight.Wrapper>
      </div>
    </div>
  );
}

USUtilityStatusSummary.icon = "icon-lt-flashlight-line";
USUtilityStatusSummary.supportedRegions = [SupportedRegions.us];
