import { InsightSelectedAreaWithTooltip } from "react-migration/components/InsightSelectedAreaWithTooltip";
import { useKeyIndicatorTags } from "react-migration/lib/map/useKeyIndicatorTags";
import { SkeletonLoading } from "react-migration/components/SkeletonLoading";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { useSelectionSidebarLoading } from "../../../Bundle/useSelectionSidebarLoadingState";
import { LayerTypeLayerSummaryProps, SupportedRegions } from "../../../types";
import { approvalRateTag } from "./utils/approvalRateTag";
import { formatApprovalRate } from "./utils/formatApprovalRate";
import { PlanningAuthorityApprovalRateSummarySection } from "./PlanningAuthorityApprovalRateSummarySection";
import { useLargestDesignationByGeometry } from "react-migration/domains/constraints/designation/hooks/useLargestDesignationByGeometry";
import { useDesignationsByCategory } from "react-migration/domains/constraints/designation/hooks/useDesignationsByCategory";
import { SingleDesignation } from "react-migration/domains/constraints/typings/applicationTypes/SingleDesignation";
import { LpaComparisonFormatter } from "./utils/types";
import { Tooltip } from "react-migration/components/Tooltip";
import { Theme } from "react-migration/lib/theme/Theme";

const LPA_KEYS = [
  "major_dwelling_granted",
  "major_dwelling_refused",
  "minor_dwelling_granted",
  "minor_dwelling_refused",
  "minor_dwelling_grant_rate",
  "major_dwelling_grant_rate",
  "major_appeal_overturn_rate",
  "non-major_appeal_overturn_rate",
  "region",
  "percentage_agreed_within_8_weeks",
  "percentage_agreed_within_13_weeks",
];

function getRawAttributeValue(designation: SingleDesignation | null | undefined, key: string) {
  if (!designation) {
    return null;
  }
  const attribute = designation.designation_attributes.find((attr) => attr.key === key)?.value;

  if (attribute == null) {
    return attribute;
  }

  return Number(Number(attribute.replace("%", "")).toFixed(0));
}

function getTotalRegionalAttributeValue(
  regionalDesignations: SingleDesignation[] | undefined,
  key: string
) {
  if (!regionalDesignations || regionalDesignations.length === 0) {
    return 0;
  }

  const sum = regionalDesignations.reduce((acc, curr) => {
    const attributeValue = getRawAttributeValue(curr, key);
    if (attributeValue) {
      return acc + attributeValue;
    }
    return acc;
  }, 0);

  return sum;
}

function getAverageRegionalAttributeValue(
  regionalDesignations: SingleDesignation[] | undefined,
  key: string
) {
  if (!regionalDesignations || regionalDesignations.length === 0) {
    return 0;
  }

  const sum = getTotalRegionalAttributeValue(regionalDesignations, key);

  const avg = sum / regionalDesignations.length;

  return Number(avg.toFixed(0));
}

function getAttributeComparison(
  attribute: string,
  selectedDesignation: SingleDesignation | null | undefined,
  regionalDesignations: SingleDesignation[] | undefined,
  formatter: LpaComparisonFormatter
) {
  const selectedAttr = getRawAttributeValue(selectedDesignation, attribute);

  const regionalAttrAverage = getAverageRegionalAttributeValue(regionalDesignations, attribute);

  return formatter(selectedAttr, regionalAttrAverage);
}

export function PlanningAuthorityLayerApprovalRateSummary({
  selection,
  onLoadingChange,
}: LayerTypeLayerSummaryProps) {
  const { t } = useTranslation();
  const { designation, loading, error } = useLargestDesignationByGeometry({
    geometry: selection.feature?.geometry || null,
    bufferMeters: 0,
    rootCategories: ["local_planning_authority"],
    attributeKeys: LPA_KEYS,
  });

  useSelectionSidebarLoading(onLoadingChange, loading);

  const region = designation?.designation_attributes.find((attr) => attr.key === "region")?.value;

  const { designations: regionalDesignations, loading: loadingRegionalDesignations } =
    useDesignationsByCategory("local_planning_authority", {
      skip: !region,
      requiredAttributes: [{ key: "region", value: region! }],
      keys: LPA_KEYS,
    });

  const approvalRateMinor = getAttributeComparison(
    "minor_dwelling_grant_rate",
    designation,
    regionalDesignations,
    formatApprovalRate
  );

  const approvalRateMajor = getAttributeComparison(
    "major_dwelling_grant_rate",
    designation,
    regionalDesignations,
    formatApprovalRate
  );

  const appealApprovalRateMinor = getAttributeComparison(
    "non-major_appeal_overturn_rate",
    designation,
    regionalDesignations,
    formatApprovalRate
  );

  const appealApprovalRateMajor = getAttributeComparison(
    "major_appeal_overturn_rate",
    designation,
    regionalDesignations,
    formatApprovalRate
  );

  const decisionWithinTimeMinor = getAttributeComparison(
    "percentage_agreed_within_8_weeks",
    designation,
    regionalDesignations,
    formatApprovalRate
  );

  const decisionWithinTimeMajor = getAttributeComparison(
    "percentage_agreed_within_13_weeks",
    designation,
    regionalDesignations,
    formatApprovalRate
  );

  useKeyIndicatorTags(() => {
    if (loading || loadingRegionalDesignations) {
      return [];
    }

    // Get the total applications
    function getTotalApplications() {
      if (!designation) {
        return 0;
      }

      const totalApplicationsGranted =
        (getRawAttributeValue(designation, "major_dwelling_granted") ?? 0) +
        (getRawAttributeValue(designation, "minor_dwelling_granted") ?? 0);

      const totalApplications =
        totalApplicationsGranted +
        (getRawAttributeValue(designation, "major_dwelling_refused") ?? 0) +
        (getRawAttributeValue(designation, "minor_dwelling_refused") ?? 0);

      const totalApplicationGrantedPc = (
        (totalApplicationsGranted / totalApplications) *
        100
      ).toFixed(0);

      return Number(totalApplicationGrantedPc);
    }

    function getTotalRegionalApplications() {
      if (!regionalDesignations || regionalDesignations.length === 0) {
        return 0;
      }

      const totalRegionalApplicationsGranted =
        getTotalRegionalAttributeValue(regionalDesignations, "major_dwelling_granted") +
        getTotalRegionalAttributeValue(regionalDesignations, "minor_dwelling_granted");

      const totalRegionalApplications =
        totalRegionalApplicationsGranted +
        getTotalRegionalAttributeValue(regionalDesignations, "major_dwelling_refused") +
        getTotalRegionalAttributeValue(regionalDesignations, "minor_dwelling_refused");

      const totalRegionalApplicationGrantedPc = (
        (totalRegionalApplicationsGranted / totalRegionalApplications) *
        100
      ).toFixed(0);

      return Number(totalRegionalApplicationGrantedPc);
    }

    return [
      approvalRateTag({
        totalValue: getTotalApplications(),
        totalRegionalValue: getTotalRegionalApplications(),
      }),
    ];
  }, [designation, regionalDesignations, loading, loadingRegionalDesignations]);

  if (loading) {
    return (
      <div className="atlas-relative atlas-p-2 atlas-h-[168px]">
        <SkeletonLoading rows={3} showPadding={false} bgClassName="atlas-bg-transparent" />
      </div>
    );
  }

  if (error) {
    // caught by error boundary
    throw error;
  }

  return (
    <>
      <div className="atlas-grid atlas-grid-cols-2 atlas-gap-2">
        <div className="atlas-text-sm atlas-inline-flex atlas-max-w-full atlas-gap-x-1">
          <span
            className="atlas-text-text-base-primary atlas-cursor-default atlas-truncate"
            title={t("workbench.summary.local_planning_authority.approval_rate.minor_applications")}
          >
            {t("workbench.summary.local_planning_authority.approval_rate.minor_applications")}
          </span>
          <Tooltip.Wrapper>
            <Tooltip.Content size="sm" side="top" align="center" theme={Theme.Light}>
              <Tooltip.Description>
                {t(
                  "workbench.summary.local_planning_authority.approval_rate.minor_applications.tooltip"
                )}
              </Tooltip.Description>
            </Tooltip.Content>
            <Tooltip.Trigger>
              <i className="icon-lt-information-line atlas-text-text-base-secondary-a atlas-cursor-pointer print:atlas-hidden" />
            </Tooltip.Trigger>
          </Tooltip.Wrapper>
        </div>
        <div className="atlas-text-sm atlas-inline-flex atlas-max-w-full atlas-gap-x-1">
          <span
            className="atlas-text-text-base-primary atlas-cursor-default atlas-truncate"
            title={t("workbench.summary.local_planning_authority.approval_rate.major_applications")}
          >
            {t("workbench.summary.local_planning_authority.approval_rate.major_applications")}
          </span>
          <Tooltip.Wrapper>
            <Tooltip.Content size="sm" side="top" align="center" theme={Theme.Light}>
              <Tooltip.Description>
                {t(
                  "workbench.summary.local_planning_authority.approval_rate.major_applications.tooltip"
                )}
              </Tooltip.Description>
            </Tooltip.Content>
            <Tooltip.Trigger>
              <i className="icon-lt-information-line atlas-text-text-base-secondary-a atlas-cursor-pointer print:atlas-hidden" />
            </Tooltip.Trigger>
          </Tooltip.Wrapper>
        </div>

        <PlanningAuthorityApprovalRateSummarySection
          heading={t(
            "workbench.summary.local_planning_authority.approval_rate.approval_rate.heading"
          )}
          status={approvalRateMinor.status}
          footerDescription={t(
            "workbench.summary.local_planning_authority.approval_rate.approval_rate.footer_desc"
          )}
          footerValue={approvalRateMinor.footerValue}
        >
          {approvalRateMinor.body}
        </PlanningAuthorityApprovalRateSummarySection>
        <PlanningAuthorityApprovalRateSummarySection
          heading={t(
            "workbench.summary.local_planning_authority.approval_rate.approval_rate.heading"
          )}
          status={approvalRateMajor.status}
          footerDescription={t(
            "workbench.summary.local_planning_authority.approval_rate.approval_rate.footer_desc"
          )}
          footerValue={approvalRateMajor.footerValue}
        >
          {approvalRateMajor.body}
        </PlanningAuthorityApprovalRateSummarySection>

        <PlanningAuthorityApprovalRateSummarySection
          heading={t(
            "workbench.summary.local_planning_authority.approval_rate.appeal_approval_rate.heading"
          )}
          status={appealApprovalRateMinor.status}
          footerDescription={t(
            "workbench.summary.local_planning_authority.approval_rate.appeal_approval_rate.footer_desc"
          )}
          footerValue={appealApprovalRateMinor.footerValue}
        >
          {appealApprovalRateMinor.body}
        </PlanningAuthorityApprovalRateSummarySection>
        <PlanningAuthorityApprovalRateSummarySection
          heading={t(
            "workbench.summary.local_planning_authority.approval_rate.appeal_approval_rate.heading"
          )}
          status={appealApprovalRateMajor.status}
          footerDescription={t(
            "workbench.summary.local_planning_authority.approval_rate.appeal_approval_rate.footer_desc"
          )}
          footerValue={appealApprovalRateMajor.footerValue}
        >
          {appealApprovalRateMajor.body}
        </PlanningAuthorityApprovalRateSummarySection>

        <PlanningAuthorityApprovalRateSummarySection
          heading={t(
            "workbench.summary.local_planning_authority.approval_rate.decision_time_minor.heading"
          )}
          status={decisionWithinTimeMinor.status}
          footerDescription={t(
            "workbench.summary.local_planning_authority.approval_rate.decision_time.footer_desc"
          )}
          footerValue={decisionWithinTimeMinor.footerValue}
          tooltipKey="workbench.summary.local_planning_authority.approval_rate.decision_time_minor.tooltip"
        >
          {decisionWithinTimeMinor.body}
        </PlanningAuthorityApprovalRateSummarySection>

        <PlanningAuthorityApprovalRateSummarySection
          heading={t(
            "workbench.summary.local_planning_authority.approval_rate.decision_time_major.heading"
          )}
          status={decisionWithinTimeMajor.status}
          footerDescription={t(
            "workbench.summary.local_planning_authority.approval_rate.decision_time.footer_desc"
          )}
          footerValue={decisionWithinTimeMajor.footerValue}
          tooltipKey="workbench.summary.local_planning_authority.approval_rate.decision_time_major.tooltip"
        >
          {decisionWithinTimeMajor.body}
        </PlanningAuthorityApprovalRateSummarySection>
      </div>
      <InsightSelectedAreaWithTooltip>{designation?.display_name}</InsightSelectedAreaWithTooltip>
    </>
  );
}

PlanningAuthorityLayerApprovalRateSummary.icon = "icon-lt-scales-3-line";
PlanningAuthorityLayerApprovalRateSummary.supportedRegions = [SupportedRegions.england];
