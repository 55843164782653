import { PlanningApplicationListItem } from "./PlanningApplicationListItem";
import { PlanningApplication } from "../../types";
import { SelectionFeature } from "src/js/stores/map/store";

interface PlanningApplicationListProps {
  planningApps: PlanningApplication[];
  setDetailSelection?: (selection: SelectionFeature) => void;
  onApplicationClick?: (application: PlanningApplication) => void;
}

export const PlanningApplicationList = ({
  planningApps,
  onApplicationClick,
}: PlanningApplicationListProps) => (
  <div className="atlas-divide-y atlas-divide-neutral-200">
    {planningApps.map((planningApplication) => (
      <PlanningApplicationListItem
        key={planningApplication.id}
        planningApplication={planningApplication}
        onClick={onApplicationClick}
      />
    ))}
  </div>
);
