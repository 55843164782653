import Feature from "src/js/stores/user/Feature";
import { ConstraintsCategory } from "../../Constraints/types";
import {
  PlanningAuthorityLayerApprovalRateSummaryType,
  PlanningAuthorityLayerPifSummaryType,
  PlanningAuthorityLayerType,
} from "../layer_types/PlanningAuthorityLayerType/PlanningAuthorityLayerType";
import { Layer } from "../types";

export const LPA_LAYER: Layer = {
  id: ConstraintsCategory.LOCAL_PLANNING_AUTHORITY,
  layerType: PlanningAuthorityLayerType,
  title: "Local Planning Authority",
  requiredFeatureAccess: [Feature.planningPolicyLayer],
  toggleable: true,
  description:
    "This dataset is from multiple sources showing housing delivery test, 5 year land supply and status of emerging plans.",
  hideByDefault: true,
  layerConfig: {
    rootCategory: ConstraintsCategory.LOCAL_PLANNING_AUTHORITY,
    categorySchema: [
      { key: "local_planning_authority" },
      { key: "local_planning_authority_former" },
      { key: "local_planning_authority_national_park" },
    ],
  },
};

export const LPA_LAYER_APPROVAL_RATE_SUMMARY: Layer = {
  id: `${ConstraintsCategory.LOCAL_PLANNING_AUTHORITY}--approval-rate-summary`,
  layerType: PlanningAuthorityLayerApprovalRateSummaryType,
  title: "LPA approval rate",
  requiredFeatureAccess: [Feature.insightsSummary, Feature.planningPolicyLayer],
  requiredBetaAccess: [Feature.insightsSummaryBeta],
  toggleable: true,
  description:
    "This dataset is from multiple sources showing housing delivery test, 5 year land supply and status of emerging plans.",
  hideByDefault: true,
};

export const LPA_LAYER_PIF_SUMMARY: Layer = {
  id: `${ConstraintsCategory.LOCAL_PLANNING_AUTHORITY}_summary`,
  layerType: PlanningAuthorityLayerPifSummaryType,
  title: "Presumption in favour",
  requiredFeatureAccess: [Feature.insightsSummary, Feature.planningPolicyLayer],
  requiredBetaAccess: [Feature.insightsSummaryBeta],
  toggleable: true,
  description:
    "This dataset is from multiple sources showing housing delivery test, 5 year land supply and status of emerging plans.",
  hideByDefault: true,
};
