import { numberAttributeAccessor } from "react-migration/domains/constraints/designation/util/numberAttributeAccessor";
import { SingleDesignation } from "react-migration/domains/constraints/typings/applicationTypes/SingleDesignation";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { Insight } from "react-migration/components/Insight/Insight";
import { HousingCategory, HOUSING_COLOURS } from "./constants";
import { GbpPerArea } from "./GbpPerArea";

interface HousingPricePerSqMeterInsightProps {
  designation: SingleDesignation;
  housingType: HousingCategory;
  priceAttributeKey: string;
  lpaPriceAttributeKey: string;
}

export function HousingPricePerSqMeterInsight({
  designation,
  housingType,
  priceAttributeKey,
  lpaPriceAttributeKey,
}: HousingPricePerSqMeterInsightProps) {
  const { t } = useTranslation();
  const price = numberAttributeAccessor(designation, priceAttributeKey);
  const lpaPrice = numberAttributeAccessor(designation, lpaPriceAttributeKey);

  return (
    <Insight.Wrapper
      title={
        <div className="atlas-flex atlas-gap-x-1 atlas-items-center">
          <div
            className="atlas-w-3 atlas-h-3 atlas-rounded-full"
            style={{ background: HOUSING_COLOURS[housingType as HousingCategory] }}
          />
          {t(`workbench.summary.local_market_value.type.${housingType}`)}
        </div>
      }
    >
      <Insight.Body>
        <GbpPerArea
          gbpPerSqMeter={price}
          unitClassNames="atlas-ml-1 atlas-self-start atlas-leading-6 atlas-text-sm atlas-font-medium atlas-text-text-base-secondary-a"
        />
      </Insight.Body>
      <Insight.Footer description={<GbpPerArea gbpPerSqMeter={lpaPrice} />}>
        LPA rate
      </Insight.Footer>
    </Insight.Wrapper>
  );
}
