import clsx from "clsx";
import { PropsWithChildren } from "react";

enum Size {
  small = "atlas-w-6 atlas-h-6",
  large = "atlas-w-8 atlas-h-8",
}
export interface IconButtonProps {
  action?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  dataTestId?: string;
  extraClasses?: string;
  rounded?: boolean;
  borderless?: boolean;
  isDisabled?: boolean;
  hoverEffect?: boolean;
  size?: keyof typeof Size;
}

export const IconButton = ({
  children,
  action,
  dataTestId,
  extraClasses = "",
  rounded = false,
  borderless = false,
  isDisabled = false,
  hoverEffect = false,
  size = "small",
}: PropsWithChildren<IconButtonProps>) => {
  return (
    <button
      data-testid={dataTestId ?? "icon-button"}
      disabled={isDisabled}
      onClick={action}
      className={clsx(
        rounded ? "atlas-rounded-full" : "atlas-rounded",
        {
          "atlas-border-0": borderless,
          "atlas-cursor-pointer": !isDisabled,
          "hover:atlas-bg-neutral-200": hoverEffect,
          "atlas-text-neutral-300": isDisabled,
          "atlas-text-text-base-primary": !isDisabled,
        },
        [
          "atlas-color-dark",
          "atlas-leading-none",
          "atlas-flex",
          "atlas-items-center",
          "atlas-justify-center",
        ],
        Size[size],
        extraClasses
      )}
    >
      {children}
    </button>
  );
};

const SrOnlyText = ({ children }: PropsWithChildren) => {
  return <span className="atlas-sr-only">{children}</span>;
};

const Icon = ({ children, ...props }: PropsWithChildren) => {
  return <i className={`${children} atlas-text-sm`} {...props} />;
};

IconButton.SrOnlyText = SrOnlyText;
IconButton.Icon = Icon;

export default IconButton;
