import { PropsWithChildren } from "react";
import { Tooltip } from "react-migration/components/Tooltip";
import { Theme } from "react-migration/lib/theme/Theme";

/**
 * Specifically for use to render a "Learn more" tooltip in the bottom right of
 * a `LayerType`'s `LayerSummary`. This allows the element to be rendered inline
 * with the "Go to _ tab >" link to it's left.
 *
 * TODO find a less fragile way of achieivng this layout
 */

type InsightSummaryLearnMoreTooltipProps = PropsWithChildren<{ triggerText?: string }>;

export const InsightSummaryLearnMoreTooltip = ({
  triggerText,
  children,
}: InsightSummaryLearnMoreTooltipProps) => (
  <div className="atlas-pt-4 atlas-pb-1 atlas-float-right">
    <Tooltip.Wrapper>
      <Tooltip.Content size="sm" side="bottom" align="end" theme={Theme.Light}>
        <Tooltip.Description>{children}</Tooltip.Description>
      </Tooltip.Content>
      <Tooltip.Trigger>
        <div className="atlas-group/tooltip atlas-flex atlas-text-text-base-active atlas-gap-x-1 atlas-cursor-pointer print:atlas-hidden">
          <i className="icon-lt-information-line group-hover/tooltip:atlas-text-text-base-active-hover" />
          <span className="atlas-underline">{triggerText ?? "Learn more"}</span>
        </div>
      </Tooltip.Trigger>
    </Tooltip.Wrapper>
  </div>
);
