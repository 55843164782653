import { sum } from "lodash";
import { useMemo } from "react";
import { TagColor as KeyIndicatorColor } from "react-migration/components/Tag";
import { numberAttributeAccessor } from "react-migration/domains/constraints/designation/util/numberAttributeAccessor";
import { SingleDesignation } from "react-migration/domains/constraints/typings/applicationTypes/SingleDesignation";
import { useKeyIndicatorTags } from "react-migration/lib/map/useKeyIndicatorTags";
import { MARKET_VALUE_PPSQM_KEYS_BY_TYPE } from "./constants";
import { useGbpPerAreaDisplay } from "./GbpPerArea";

export function useAveragePpsqmKeyIndicator(designation: SingleDesignation | null) {
  const averagePpsqm = useMemo(() => {
    if (!designation) return null;

    const prices = [
      numberAttributeAccessor(designation, MARKET_VALUE_PPSQM_KEYS_BY_TYPE.detached.pricePsqm),
      numberAttributeAccessor(designation, MARKET_VALUE_PPSQM_KEYS_BY_TYPE.semi_detached.pricePsqm),
      numberAttributeAccessor(designation, MARKET_VALUE_PPSQM_KEYS_BY_TYPE.terraced.pricePsqm),
      numberAttributeAccessor(designation, MARKET_VALUE_PPSQM_KEYS_BY_TYPE.flat.pricePsqm),
    ].filter((value) => !!value);

    return sum(prices) / prices.length;
  }, [designation]);

  const priceDisplay = useGbpPerAreaDisplay(averagePpsqm);

  useKeyIndicatorTags(() => {
    if (!averagePpsqm) return [];

    return [{ tag: priceDisplay, color: KeyIndicatorColor.DEFAULT }];
  }, [averagePpsqm, priceDisplay]);
}
