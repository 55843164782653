/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import type { ShaderModule } from "@luma.gl/shadertools";
import { project, fp64LowPart } from "@deck.gl/core";
import type { ProjectUniforms, ProjectProps } from "@deck.gl/core";

import type { Texture } from "@luma.gl/core";
import { glsl } from "./syntax-tags";

const uniformBlock = glsl`\
uniform fillUniforms {
  vec2 patternTextureSize;
  bool patternEnabled;
  vec2 cameraCenter;           // center of viewport in common space
  bool fixPatternToViewport;   // when true pattern doesn't pan with world
  bool patternMask;
  vec2 uvCoordinateOrigin;
  vec2 uvCoordinateOrigin64Low;
} fill;
`;

/*
 * fill pattern shader module
 */
const patternVs = glsl`
in vec4 fillPatternFrames;
in float fillPatternScales;
in vec2 fillPatternOffsets;

out vec2 fill_uv;
out vec4 fill_patternBounds;
out vec4 fill_patternPlacement;
out vec2 fill_patternSizeClip;        // width/height of pattern in clipspace
out vec2 fill_patternWorldOffsetClip; // pattern offset in clipspace
`;

const vs = `
${uniformBlock}
${patternVs}
`;

const patternFs = glsl`
uniform sampler2D fill_patternTexture;

in vec4 fill_patternBounds;
in vec4 fill_patternPlacement;
in vec2 fill_uv;
in vec2 fill_patternSizeClip;        // width/height of pattern in clipspace
in vec2 fill_patternWorldOffsetClip; // pattern offset in clipspace

const float FILL_UV_SCALE = 512.0 / 40000000.0;
`;

const fs = `
${uniformBlock}
${patternFs}
`;

const inject = {
  "vs:DECKGL_FILTER_GL_POSITION": glsl`
    fill_uv = project_common_position_to_clipspace(geometry.position).xy;
  `,

  "vs:DECKGL_FILTER_COLOR": glsl`
    if (fill.patternEnabled) {
      fill_patternBounds = fillPatternFrames / vec4(fill.patternTextureSize, fill.patternTextureSize);
      fill_patternPlacement.xy = fillPatternOffsets;
      fill_patternPlacement.zw = fillPatternScales * fillPatternFrames.zw;
      fill_patternSizeClip = project_pixel_size_to_clipspace(fill_patternPlacement.zw);

      fill_patternSizeClip.x = 1.0 - fill_patternSizeClip.x;
      
      if (fill.fixPatternToViewport) {
        fill_patternWorldOffsetClip = vec2(0.0);
      } else {
        vec2 fill_patternSizeCommon = project_pixel_size(fill_patternPlacement.zw);
        fill_patternWorldOffsetClip = project_common_position_to_clipspace(vec4(mod(fill.cameraCenter, fill_patternSizeCommon), 0.0, 0.0)).xy;
      }
    }
  `,

  "fs:DECKGL_FILTER_COLOR": glsl`
    bool hasDefinedPattern = fill_patternBounds != vec4(0.0);
    
    if (fill.patternEnabled && hasDefinedPattern) {
      vec2 scale = FILL_UV_SCALE * fill_patternPlacement.zw;
      vec2 patternUV = mod(fill_patternWorldOffsetClip + fill_uv, fill_patternSizeClip) / fill_patternSizeClip;

      vec2 texCoords = fill_patternBounds.xy + fill_patternBounds.zw * patternUV;

      vec4 patternColor = texture(fill_patternTexture, texCoords);
      color.a *= patternColor.a;
      if (!fill.patternMask) {
        color.rgb = patternColor.rgb;
      }
    }
  `,
};

export type FillStyleModuleProps = {
  project: ProjectProps;
  fillPatternEnabled?: boolean;
  fillPatternMask?: boolean;
  fillPatternTexture: Texture;
};

type FillStyleModuleUniforms = {
  patternTextureSize?: [number, number];
  patternEnabled?: boolean;
  patternLoading?: boolean;
  patternMask?: boolean;
  uvCoordinateOrigin?: [number, number];
  uvCoordinateOrigin64Low?: [number, number];
  cameraCenter?: [number, number];
  fixPatternToViewport?: boolean;
};

type FillStyleModuleBindings = {
  fill_patternTexture?: Texture;
};

/* eslint-disable camelcase */
function getPatternUniforms(
  opts?: FillStyleModuleProps | {}
): FillStyleModuleBindings & FillStyleModuleUniforms {
  if (!opts) {
    return {};
  }
  const uniforms: FillStyleModuleBindings & FillStyleModuleUniforms = {};
  if ("fillPatternTexture" in opts) {
    const { fillPatternTexture } = opts;
    uniforms.fill_patternTexture = fillPatternTexture;
    uniforms.patternTextureSize = [fillPatternTexture.width, fillPatternTexture.height];
  }
  if ("project" in opts) {
    const { fillPatternMask = true, fillPatternEnabled = true } = opts;
    const projectUniforms = project.getUniforms(opts.project) as ProjectUniforms;
    const { commonOrigin: coordinateOriginCommon } = projectUniforms;
    const nearestIntegerZoom = Math.floor(opts.project.viewport.zoom);

    const coordinateOriginCommon64Low: [number, number] = [
      fp64LowPart(coordinateOriginCommon[0]),
      fp64LowPart(coordinateOriginCommon[1]),
    ];

    uniforms.uvCoordinateOrigin = coordinateOriginCommon.slice(0, 2) as [number, number];
    uniforms.uvCoordinateOrigin64Low = coordinateOriginCommon64Low;
    uniforms.patternMask = fillPatternMask;
    uniforms.patternEnabled = fillPatternEnabled;
    uniforms.fixPatternToViewport = nearestIntegerZoom > 16;
    uniforms.cameraCenter = opts.project.viewport.center.slice(0, 2) as [number, number];
  }
  return uniforms;
}

export const patternShaders: ShaderModule<
  FillStyleModuleProps,
  FillStyleModuleUniforms,
  FillStyleModuleBindings
> = {
  name: "fill",
  vs,
  fs,
  inject,
  dependencies: [project],
  getUniforms: getPatternUniforms,
  uniformTypes: {
    patternTextureSize: "vec2<f32>",
    patternEnabled: "i32",
    patternLoading: "i32",
    cameraCenter: "vec2<f32>",
    fixPatternToViewport: "i32",
    patternMask: "i32",
    uvCoordinateOrigin: "vec2<f32>",
    uvCoordinateOrigin64Low: "vec2<f32>",
  },
};
