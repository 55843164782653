import { LayerTypeLayerSummaryProps, SupportedRegions } from "../../types";
import { useWorkbench } from "../../Bundle/Workbench/WorkbenchContext";
import {
  UKPropertyInformationSelectionContent,
  usePropertiesBySelection,
} from "./UKPropertyInformationSelectionSidebar/UKPropertyInformationSelectionSidebar";
import { SkeletonLoading } from "react-migration/components/SkeletonLoading";
import { useSelectionSidebarLoading } from "../../Bundle/useSelectionSidebarLoadingState";

export const UKPropertyInformationSummary = ({
  selection,
  onLoadingChange,
}: LayerTypeLayerSummaryProps) => {
  const { setDetailSelection } = useWorkbench();

  const { properties, loading } = usePropertiesBySelection(selection);

  useSelectionSidebarLoading(onLoadingChange, loading);

  if (loading) {
    return (
      <div className="atlas-flex atlas-flex-col atlas-gap-3">
        <div className="atlas-relative atlas-p-2 atlas-h-[96px]">
          <SkeletonLoading
            rows={2}
            cols={2}
            showPadding={false}
            bgClassName="atlas-bg-transparent"
          />
        </div>

        <div className="atlas-relative atlas-h-[36px]">
          <SkeletonLoading rows={1} showPadding={false} bgClassName="atlas-bg-transparent" />
        </div>
      </div>
    );
  }

  return (
    <div className="atlas-flex atlas-flex-col atlas-divide-y atlas-divide-solid atlas-divide-neutral-200">
      <UKPropertyInformationSelectionContent
        properties={properties}
        setDetailSelection={setDetailSelection}
      />
    </div>
  );
};

UKPropertyInformationSummary.icon = "icon-lt-home-line";
UKPropertyInformationSummary.title = "Properties on site";
UKPropertyInformationSummary.hideLink = true;
UKPropertyInformationSummary.supportedRegions = [
  SupportedRegions.england,
  SupportedRegions.scotland,
  SupportedRegions.wales,
];
