import { useMemo } from "react";
import { useSnapshot } from "valtio";
import { DataPanel } from "react-migration/components/DataPanel";
import { useVariants } from "react-migration/components/TextWithVariants";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import {
  KeyIndicatorColor,
  useKeyIndicatorTags,
} from "react-migration/lib/map/useKeyIndicatorTags";
import { getGeometryMeasurements } from "react-migration/lib/util/getAreaAndPerimeter";
import { userStore } from "src/js/stores/user/store";
import { LayerTypeLayerSummaryProps, SupportedRegions } from "../../types";

export function OwnershipPlotSizeLayerSummary({ selection }: LayerTypeLayerSummaryProps) {
  const { user } = useSnapshot(userStore);
  const { t } = useTranslation();

  const geometry = selection.feature?.geometry ?? null;

  const measurements = useMemo(() => {
    return geometry && getGeometryMeasurements(geometry, user.settings.unit);
  }, [geometry, user.settings.unit]);

  const [areaVariant, toggleAreaVariant] = useVariants(
    measurements?.areaVariants,
    measurements?.areaIsLarge
  );
  const [perimeterVariant, togglePerimeterVariant] = useVariants(
    measurements?.perimeterVariants,
    measurements?.perimeterIsLarge
  );

  useKeyIndicatorTags(() => {
    return [
      {
        tag: areaVariant,
        color: KeyIndicatorColor.DEFAULT,
      },
    ];
  }, [areaVariant]);

  return (
    <DataPanel.Grid>
      <DataPanel.Cell title={t("components.measure2D.area")}>
        <DataPanel.Button onClick={toggleAreaVariant}>{areaVariant}</DataPanel.Button>
      </DataPanel.Cell>
      <DataPanel.Cell title={t("components.measure2D.perimeter")}>
        <DataPanel.Button onClick={togglePerimeterVariant}>{perimeterVariant}</DataPanel.Button>
      </DataPanel.Cell>
    </DataPanel.Grid>
  );
}

OwnershipPlotSizeLayerSummary.icon = "icon-lt-collage-line";
OwnershipPlotSizeLayerSummary.hideLink = true;
OwnershipPlotSizeLayerSummary.supportedRegions = [
  SupportedRegions.england,
  SupportedRegions.scotland,
  SupportedRegions.wales,
];
