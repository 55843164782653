import { SelectionFeature } from "src/js/stores/map/store";
import { usePlanningApplicationListBySelection } from "../SelectionSidebar/usePlanningApplicationListBySelection";
import { defaultPlanningFilterState } from "../state/usePlanningFilterState";
import { PlanningFilters, useClassAndTypeFilters } from "../Context/hooks";
import { RECENT_APPLICATIONS_YEAR_FILTER } from "./constants";

const RECENT_SUMMARY_FILTERS: PlanningFilters = {
  ...defaultPlanningFilterState,
  maxYear: RECENT_APPLICATIONS_YEAR_FILTER,
  minSize: 0,
  enabledTypes: [
    "fullApplication",
    "outline",
    "gpdr",
    "reservedMatters",
    "eia",
    "changeOfUse",
    "uncategorised",
  ],
};

export function useRecentApplicationsBySelection(selection: SelectionFeature) {
  const [, layerFilters] = useClassAndTypeFilters(RECENT_SUMMARY_FILTERS);
  const {
    filteredPlanningApplications: applications,
    loading,
    error,
  } = usePlanningApplicationListBySelection(selection, {
    useLayerFilters: true,
    filterSelectionFromList: true,
    layerFilters,
  });

  return { applications, loading, error };
}
