import { LayerType } from "../../types";
import { ConstraintsLayerType } from "../ConstraintsLayerType";
import { HazardFloodingLayerSummary } from "./HazardFloodingLayerSummary";
import { HazardWetlandsLayerSummary } from "./HazardWetlandsLayerSummary";

export const HazardFloodingLayerType: LayerType = {
  ...ConstraintsLayerType,
  id: ConstraintsLayerType.id,
  LayerSummary: HazardFloodingLayerSummary,
};

export const HazardWetlandsLayerType: LayerType = {
  ...ConstraintsLayerType,
  id: ConstraintsLayerType.id,
  LayerSummary: HazardWetlandsLayerSummary,
};
