import { useQuery } from "@apollo/client";
import { SelectionGeometry } from "src/js/stores/map/store";
import { LandTechEndpoints, routedClient } from "react-migration/lib/persistence/apollo";
import { Designation } from "react-migration/domains/constraints/typings/baseTypes/Designation";
import NEAREST_DESIGNATIONS_QUERY from "./NearestDesignationsQuery.gql";

export interface DesignationDistance {
  distanceMeters: number;
  designation: Pick<Designation, "id" | "sub_category_id" | "display_name">;
}

export interface CategoryRadius {
  category: string;
  radiusMeters: number;
}

export interface NearestDesignationsQueryResult {
  nearestDesignationsByCategory: DesignationDistance[];
}

export interface NearestDesignationsQueryVariables {
  geometry: SelectionGeometry;
  categoryRadiuses: CategoryRadius[];
}

export function useNearestDesignationsQuery(
  geometry: SelectionGeometry | null | undefined,
  categoryRadiuses: CategoryRadius[]
) {
  const { data, ...rest } = useQuery<
    NearestDesignationsQueryResult,
    NearestDesignationsQueryVariables
  >(NEAREST_DESIGNATIONS_QUERY, {
    variables: {
      geometry: geometry!,
      categoryRadiuses,
    },
    skip: !geometry,
    client: routedClient,
    context: {
      endpoint: LandTechEndpoints.Constraints,
    },
  });

  return { distances: data?.nearestDesignationsByCategory, ...rest };
}
