<template>
  <div id="app" class="atlas-text-sm atlas-h-full atlas-w-full atlas-flex">
    <router-view />
  </div>
</template>

<script>
import { bootIntercomDeferred } from "util/intercom";
import { ENVIRONMENT } from "util/environment";
import { userStore } from "./stores/user/store";
import hasBetaFeature from "./stores/user/actions/hasBetaFeature";
import Feature from "./stores/user/Feature";
import { setupMonitoring } from "./util/monitoring/window";
import { getDeviceSpecifications } from "./util/device_specifications";

/* eslint-disable no-undef */
export default {
  data() {
    return {
      user: userStore.user,
    };
  },
  mounted() {
    if (!this.user) {
      return;
    }

    const {
      _id,
      intercom_hash,
      firstname,
      surname,
      email,
      telephone,
      licence_summary,
      organisation_name,
    } = this.user;

    intercom_hash &&
      !window.Cypress &&
      bootIntercomDeferred({
        app_id: ENVIRONMENT.INTERCOM_APP_ID,
        hide_default_launcher: true,
        user_id: _id,
        user_hash: intercom_hash,
        name: [firstname, surname].join(" "),
        email,
        phone: telephone,
        licence_summary,
        org: organisation_name,
      });

    // Datadog RUM might be related to performance problems we've been having again
    // https://landtech.slack.com/archives/C042BH9RFFU/p1678897186767419
    if (!hasBetaFeature(Feature.disableDatadogRum)) {
      setupMonitoring({
        applicationId: ENVIRONMENT.DATADOG_APPLICATION_ID,
        clientToken: ENVIRONMENT.DATADOG_CLIENT_TOKEN,
        service: ENVIRONMENT.DATADOG_SERVICE_NAME,
        site: ENVIRONMENT.DATADOG_SITE,
        release: ENVIRONMENT.APP_VERSION,
        environment:
          ENVIRONMENT.APP_ENV === "staging" && /preview/.test(ENVIRONMENT.API_URL || "")
            ? "pr"
            : ENVIRONMENT.APP_ENV,
        deviceSpecifications: getDeviceSpecifications(),
      });
    }
  },
  pusher: {
    "client-logout"(eventData) {
      if (this.user && eventData.userId === this.user._id) {
        const redirect = new URL(ENVIRONMENT.LOGOUT_URL);
        redirect.searchParams.set(
          error,
          `You were logged out by a LandTech admin. Please check your emails for details.`
        );
        window.location.href = redirect.toString();
      }
    },
  },
};
</script>

<style>
@import "@landtechnologies/icons/font/lt-icons.css";
</style>

<style lang="scss">
@import "app.scss";
</style>
