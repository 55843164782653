import clsx from "clsx";

export type SkeletonLoadingProps = {
  bgClassName?: string;
  rows?: number;
  cols?: number;
  showPadding?: boolean;
  roundedSize?: "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "full";
};

export const SkeletonLoading = ({
  rows = 1,
  cols = 1,
  showPadding = true,
  bgClassName,
  roundedSize = "md",
}: SkeletonLoadingProps) => {
  return (
    <div
      className={clsx(
        "atlas-absolute",
        "atlas-z-50",
        "atlas-inset-0",
        "atlas-flex",
        "atlas-flex-col",
        "atlas-content-between",
        "atlas-gap-2",
        bgClassName ? bgClassName : "atlas-bg-white",
        showPadding && "atlas-p-4"
      )}
    >
      {[...Array(rows).keys()].map((row) => (
        <div
          className="atlas-flex atlas-h-full atlas-gap-2"
          key={`skeleton-loading-row-${row}`}
          data-testid="skeleton-loading-row"
        >
          {[...Array(cols).keys()].map((col) => (
            <div
              key={`skeleton-loading-col-${col}`}
              data-testid="skeleton-loading-col"
              className={clsx(
                "atlas-bg-background-surface-neutral-subtle",
                "atlas-animate-pulse",
                "atlas-w-full",
                {
                  "atlas-rounded-sm": roundedSize === "sm",
                  "atlas-rounded-md": roundedSize === "md",
                  "atlas-rounded-lg": roundedSize === "lg",
                  "atlas-rounded-xl": roundedSize === "xl",
                  "atlas-rounded-2xl": roundedSize === "2xl",
                  "atlas-rounded-3xl": roundedSize === "3xl",
                  "atlas-rounded-full": roundedSize === "full",
                }
              )}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

SkeletonLoading.displayName = "SkeletonLoading";
