import { LayerType } from "../../types";
import { ComparablesSelectionSidebar } from "./ComparablesSelectionSidebar";
import { ComparablesMapLayer } from "./ComparablesMapLayer";
import { ComparablesControlPage } from "./ComparablesControlPage";
import { ComparablesLayerContextProvider } from "./ComparableLayerContext/ComparableLayerContext";
import { comparablesClickResolver } from "./comparablesClickResolver";
import { ComparablesPrintable } from "./ComparablesPrintable";
import { COMPARABLES_LAYER_SUMMARY_TYPE_ID, COMPARABLES_LAYER_TYPE_ID } from "./constants";
import { ComparablesLayerSummary } from "./ComparablesLayerSummary/ComparablesLayerSummary";
import { LocalMarketValueSummary } from "./LocalMarketValueSummary";

export const ComparablesLayerType: LayerType = {
  id: COMPARABLES_LAYER_TYPE_ID,
  MapLayer: ComparablesMapLayer,
  SelectionSidebar: ComparablesSelectionSidebar,
  ControlPage: ComparablesControlPage,
  Provider: ComparablesLayerContextProvider,
  clickResolver: comparablesClickResolver,
  Printable: ComparablesPrintable,
};

export const ComparablesLayerSummaryType: LayerType = {
  id: COMPARABLES_LAYER_SUMMARY_TYPE_ID,
  LayerSummary: ComparablesLayerSummary,
};

export const LocalMarketValueSummaryLayerType: LayerType = {
  id: "LOCAL_MARKET_VALUE_SUMMARY_LAYER_TYPE",
  LayerSummary: LocalMarketValueSummary,
};
