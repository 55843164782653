export enum AssessmentLayerTabUK {
  DETAILS = "details",
  PLANNING_HISTORY = "planningHistory",
  POLICIES = "policies",
  ENVIRONMENTAL = "environmental",
  COMPS_AND_LOCAL = "compsAndLocal",
  POWER_AND_UTILITIES = "powerAndUtilities",
  TOPOGRAPHY = "topography",
}

export enum AssessmentLayerTabUS {
  DETAILS = "details",
  PERMITS = "permits",
  POLICIES = "policies",
  ENVIRONMENTAL = "environmental",
  COMPS_AND_LOCAL = "compsAndLocal",
  UTILITIES = "utilities",
}
