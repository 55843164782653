import { Elements, AddressElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ENVIRONMENT } from "src/js/util/environment";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import hasFeature from "src/js/stores/user/actions/hasFeature";
import Feature from "src/js/stores/user/Feature";
import { userStore } from "src/js/stores/user/store";
import { Button } from "react-migration/components/Button";
import React, { useState } from "react";
import { Notification } from "react-migration/components/Notification";
import axios from "axios";

type StripeBillingAddressProps = {
  onComplete?: () => void;
};

const StripeBillingAddressForm = ({ onComplete }: StripeBillingAddressProps) => {
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<unknown>(null);

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();

    try {
      setSubmitting(true);

      if (!stripe || !elements) {
        return;
      }

      const { complete, value } = await elements.getElement("address")!.getValue();

      if (complete) {
        await axios.put(`${ENVIRONMENT.ACCOUNTS_SERVICE_PRIVATE_API_URL}/customer`, {
          name: value.name,
          address: value.address,
        });

        onComplete?.();
      }
    } catch (e) {
      console.error(e);
      setError(e);
      setSubmitting(false);
    }
  };

  return (
    <>
      {!!error && (
        <Notification.Error
          additionalClassName="atlas-mb-4"
          title="Update failed"
          message="An error occurred while updating your billing address."
          icon="info"
        />
      )}
      <form onSubmit={handleSubmit} autoComplete="off">
        <AddressElement
          options={{
            allowedCountries: ["US"],
            display: { name: "split" },
            defaultValues: {
              firstName: userStore.user.firstname,
              lastName: userStore.user.surname,
            },
            mode: "billing",
            autocomplete: { mode: "google_maps_api", apiKey: ENVIRONMENT.GOOGLE_MAPS_API_KEY },
          }}
        />
        <Button disabled={!stripe || submitting} type="submit" className="atlas-w-full atlas-my-4">
          Submit
        </Button>
      </form>
    </>
  );
};

export const StripeBillingAddress = (props: StripeBillingAddressProps) => {
  const stripeKey = hasFeature(Feature.usAccess)
    ? ENVIRONMENT.STRIPE_US_PUBLIC_KEY
    : ENVIRONMENT.STRIPE_PUBLIC_KEY;
  const stripePromise = loadStripe(stripeKey ?? "");

  return (
    <Elements stripe={stripePromise}>
      <StripeBillingAddressForm {...props} />
    </Elements>
  );
};

export default StripeBillingAddress;
