import { useQuery } from "@apollo/client";
import { routedClient } from "react-migration/lib/persistence/apollo/routedClient";
import propertyInformationByTitleNumberQuery from "./propertyInformationByTitleNumberQuery.gql";
import propertyInformationByGeometryQuery from "./propertyInformationByGeometryQuery.gql";

export type EpcEnergyRating = "A" | "B" | "C" | "D" | "E" | "F" | "G";
export type PropertyInformation = {
  address: {
    full_address: string;
    uprn: string;
    use_class_sep_2020: string;
    use_class_description_2020: string;

    location: {
      coordinates: [number, number];
    };
  };

  building_height: {
    abs_h_max: number;
    rel_h_max: number;
  };
  epc?: {
    appraisal_date: string;
    current_energy_rating: EpcEnergyRating;
    current_energy_efficiency: number;
    potential_energy_rating: EpcEnergyRating;
    potential_energy_efficiency: number;

    property_type: string;
    built_form: string;
    total_floor_area: number;
    tenure: string;
    number_habitable_rooms: number;
    roof_type: string;
    construction_age_band: string;
  };
};

interface ByGeometryResponse {
  propertyInformationByGeometry: PropertyInformation[];
}

interface ByGeometryVariables {
  geometry: GeoJSON.Geometry;
}

export function usePropertiesByGeometry(geometry: GeoJSON.Geometry | null) {
  const query = useQuery<ByGeometryResponse, ByGeometryVariables>(
    propertyInformationByGeometryQuery,
    {
      variables: { geometry: geometry! },
      skip: !geometry,
      client: routedClient,
    }
  );

  return {
    properties: query.data?.propertyInformationByGeometry,
    ...query,
  };
}

interface ByTitleNumberResponse {
  propertyInformationByTitleNumber: PropertyInformation[];
}

interface ByTitleNumberVariables {
  titleNumber: string;
}

export function usePropertiesByTitleNumber(titleNumber?: string) {
  const query = useQuery<ByTitleNumberResponse, ByTitleNumberVariables>(
    propertyInformationByTitleNumberQuery,
    {
      variables: { titleNumber: titleNumber! },
      skip: !titleNumber,
      client: routedClient,
    }
  );

  return {
    properties: query.data?.propertyInformationByTitleNumber,
    ...query,
  };
}
