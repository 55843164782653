import { SelectionFeature } from "src/js/stores/map/store";
import Feature from "src/js/stores/user/Feature";
import { useConstraintsLayerTypeContext } from "react-migration/layouts/map/Multilayer/layer_types/ConstraintsLayerType/ConstraintsContext";
import { LayerTypeSelectionSidebarProps } from "react-migration/layouts/map/Multilayer/types";
import { CollapsibleConsiderationsCard } from "react-migration/layouts/map/Multilayer/Bundle/Workbench/AssessmentTab/CollapsibleConsiderationsCard";
import { useSelectionSidebarLoading } from "react-migration/layouts/map/Multilayer/Bundle/useSelectionSidebarLoadingState";
import { apiDesignationIsVisible } from "react-migration/layouts/map/Multilayer/layer_types/ConstraintsLayerType/ConstraintsContext/utils";
import { ConstraintLayerConfig } from "react-migration/layouts/map/Multilayer/layer_types/ConstraintsLayerType";
import { useBetaFeatureFlag } from "react-migration/lib/user/useFeatureFlag";
import { useLayerZoomLimit } from "react-migration/layouts/map/Multilayer/Bundle/useLayerZoomLimit";
import { ConstraintsStore } from "react-migration/layouts/map/Multilayer/layer_types/ConstraintsLayerType/ConstraintsContext/store";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { useDesignationsByGeometry } from "../../hooks/useDesignationsByGeometry";
import { useDesignationsSummaryByGeometry } from "../../hooks/useDesignationsSummaryByGeometry";
import { OutsideZoomLimitMessage } from "./OutsideZoomLimitMessage";
import { DesignationsList } from "./DesignationsList";

type DesignationsSelectionSidebarProps = Pick<
  LayerTypeSelectionSidebarProps,
  "selection" | "setDetailSelection" | "layer" | "onLoadingChange" | "cardExpanded"
> & { constraintsStore: ConstraintsStore };

export function DesignationsSelectionSidebar({
  selection,
  cardExpanded,
  layer,
  setDetailSelection,
  onLoadingChange,
  constraintsStore,
}: DesignationsSelectionSidebarProps) {
  const { t } = useTranslation();
  const { consideration: considerationZoomLimit } = useLayerZoomLimit(layer);
  const layerConfig = layer.layerConfig as ConstraintLayerConfig;
  const rootCategory = layerConfig.rootCategory;
  const intersectionBuffer = layerConfig.intersectionThresholdMeters;
  const hasProtectedAreasAiSummaryBeta = useBetaFeatureFlag(Feature.protectedAreasAiSummaryBeta);

  const { designations, loading } = useDesignationsByGeometry({
    geometry: selection.feature?.geometry || null,
    bufferMeters: intersectionBuffer,
    rootCategories: [rootCategory],
  });

  useSelectionSidebarLoading(onLoadingChange, loading);

  const layerStore = constraintsStore.layers[layer.id];

  const visibleDesignations = designations.filter((designation) =>
    apiDesignationIsVisible(designation, layerStore)
  );

  if (!visibleDesignations.length) return null;

  const isOutsideZoomLimit = !!considerationZoomLimit;
  const labelTranslation = layer.label ? t(layer.label.translationKey) : undefined;

  return (
    <CollapsibleConsiderationsCard
      heading={layer.title}
      name={layer.title}
      label={labelTranslation}
      count={!isOutsideZoomLimit ? visibleDesignations.length : undefined}
      defaultExpanded={cardExpanded}
    >
      {isOutsideZoomLimit ? (
        <div className="atlas-px-2 atlas-py-3">
          <OutsideZoomLimitMessage layer={layer} />
        </div>
      ) : (
        <>
          {hasProtectedAreasAiSummaryBeta && (
            <DesignationSummary
              selection={selection}
              intersectionBuffer={intersectionBuffer}
              rootCategory={rootCategory}
            />
          )}
          <DesignationsList
            designations={visibleDesignations}
            setDetailSelection={setDetailSelection}
            cardName={layer.title}
          />
        </>
      )}
    </CollapsibleConsiderationsCard>
  );
}

type DesignationSummaryProps = {
  selection: SelectionFeature;
  intersectionBuffer: number | undefined;
  rootCategory: string;
};

function DesignationSummary({
  selection,
  intersectionBuffer,
  rootCategory,
}: DesignationSummaryProps) {
  const { summary, summaryLoading } = useDesignationsSummaryByGeometry({
    geometry: selection.feature?.geometry || null,
    bufferMeters: intersectionBuffer,
    rootCategories: [rootCategory],
  });

  return (
    <div className="atlas-p-2">
      {summaryLoading ? (
        <p>Summary Loading</p>
      ) : (
        summary && (
          <div>
            <p>{summary}</p>
          </div>
        )
      )}{" "}
    </div>
  );
}

/**
 * Connects the `DesignationsSelectionSidebar` component with the constraints
 * provider. Note that this behavior may not always be desired.
 */
export function DesignationsSelectionSidebarConnected(
  props: Omit<DesignationsSelectionSidebarProps, "constraintsStore">
) {
  const { constraintsStore } = useConstraintsLayerTypeContext();

  return <DesignationsSelectionSidebar {...props} constraintsStore={constraintsStore} />;
}
