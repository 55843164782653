import { OutputAreaAttribute } from "./types";
import {
  formatGBP,
  formatNumber,
  formatPerHa,
  formatPerSqKm,
  formatPercentNormal,
  formatSignedPercentNormal,
} from "./utils";

export const AGE_ATTRS: OutputAreaAttribute[] = [
  {
    attributeKey: "median_age",
    label: "Median age",
  },
  //Is this needed?
  // {
  //   attribute: "modal_age_bracket",
  //   label: "Most common age bracket",
  // },
  {
    attributeKey: "age_>65pc",
    label: "Population 65 and over",
    format: formatPercentNormal,
  },
  {
    attributeKey: "age_<5pc",
    label: "Under 5",
    format: formatPercentNormal,
  },
  {
    attributeKey: "age_5-9pc",
    label: "5 to 9",
    format: formatPercentNormal,
  },
  {
    attributeKey: "age_10-14pc",
    label: "10 to 14",
    format: formatPercentNormal,
  },
  {
    attributeKey: "age_15-19pc",
    label: "15 to 19",
    format: formatPercentNormal,
  },
  {
    attributeKey: "age_20-24pc",
    label: "20 to 24",
    format: formatPercentNormal,
  },
  {
    attributeKey: "age_25-29pc",
    label: "25 to 29",
    format: formatPercentNormal,
  },
  {
    attributeKey: "age_30-34pc",
    label: "30 to 34",
    format: formatPercentNormal,
  },
  {
    attributeKey: "age_35-39pc",
    label: "35 to 39",
    format: formatPercentNormal,
  },
  {
    attributeKey: "age_40-44pc",
    label: "40 to 44",
    format: formatPercentNormal,
  },
  {
    attributeKey: "age_45-49pc",
    label: "45 to 49",
    format: formatPercentNormal,
  },
  {
    attributeKey: "age_50-54pc",
    label: "50 to 54",
    format: formatPercentNormal,
  },
  {
    attributeKey: "age_55-59pc",
    label: "55 to 59",
    format: formatPercentNormal,
  },
  {
    attributeKey: "age_60-64pc",
    label: "60 to 64",
    format: formatPercentNormal,
  },
  {
    attributeKey: "age_65-69pc",
    label: "65 to 69",
    format: formatPercentNormal,
  },
  {
    attributeKey: "age_70-74pc",
    label: "70 to 74",
    format: formatPercentNormal,
  },
  {
    attributeKey: "age_75-79pc",
    label: "75 to 79",
    format: formatPercentNormal,
  },
  {
    attributeKey: "age_80-84pc",
    label: "80 to 84",
    format: formatPercentNormal,
  },
  {
    attributeKey: "age_>84pc",
    label: "85+",
    format: formatPercentNormal,
  },
];

export const DEPRIVATION_ATTRS = [
  {
    attributeKey: "imd_rank_2019",
    label: "Deprivation rank 2019",
    format: formatNumber,
    xValue: 2019,
  },
  // {
  //   attribute: "imd_decile_2019",
  //   label: "Deprivation decile 2019",
  // },
  {
    attributeKey: "imd_rank_2015",
    label: "Deprivation rank 2015",
    format: formatNumber,
    xValue: 2015,
  },
  {
    attributeKey: "imd_rank_2010",
    label: "Deprivation rank 2010",
    format: formatNumber,
    xValue: 2010,
  },
  {
    attributeKey: "imd_rank_2007",
    label: "Deprivation rank 2007",
    format: formatNumber,
    xValue: 2007,
  },
  {
    attributeKey: "imd_rank_2004",
    label: "Deprivation rank 2004",
    format: formatNumber,
    xValue: 2004,
  },
];

export const OCCUPATION_ATTRS: OutputAreaAttribute[] = [
  {
    attributeKey: "modal_occupation",
    label: "Most common occupation",
  },
  {
    attributeKey: "modal_occupation_pc",
    format: formatPercentNormal,
    label: "Most common occupation (%)",
  },
  {
    attributeKey: "occ_l1_to_l3_pc",
    format: formatPercentNormal,
    label: "Higher managerial, administrative and professional occupations",
  },
  {
    attributeKey: "occ_l4_to_l6_pc",
    format: formatPercentNormal,
    label: "Lower managerial, administrative and professional occupations",
  },
  {
    attributeKey: "occ_l7_pc",
    format: formatPercentNormal,
    label: "Intermediate occupations",
  },
  {
    attributeKey: "occ_l8_to_l9_pc",
    format: formatPercentNormal,
    label: "Small employers and own account workers",
  },
  {
    attributeKey: "occ_l10_to_l11_pc",
    format: formatPercentNormal,
    label: "Lower supervisory and technical occupations",
  },
  {
    attributeKey: "occ_l12_pc",
    format: formatPercentNormal,
    label: "Semi-routine occupations",
  },
  {
    attributeKey: "occ_l13_pc",
    format: formatPercentNormal,
    label: "Routine occupations",
  },
  {
    attributeKey: "occ_l14_pc",
    format: formatPercentNormal,
    label: "Never worked or long-term unemployed",
  },
  {
    attributeKey: "occ_l15_pc",
    format: formatPercentNormal,
    label: "Full-time students",
  },
];

export const DWELLING_TYPES_ATTRS: OutputAreaAttribute[] = [
  {
    attributeKey: "acc_detached_pc",
    format: formatPercentNormal,
    label: "Detached",
  },
  {
    attributeKey: "acc_semi_detached_pc",
    format: formatPercentNormal,
    label: "Semi-detached",
  },
  {
    attributeKey: "acc_terraced_pc",
    format: formatPercentNormal,
    label: "Terraced",
  },
  {
    attributeKey: "acc_block_of_flats_pc",
    format: formatPercentNormal,
    label: "Purpose-built flats",
  },
  {
    attributeKey: "acc_converted_or_shared_house_pc",
    format: formatPercentNormal,
    label: "Converted or shared house",
  },
  {
    attributeKey: "acc_converted_other_pc",
    format: formatPercentNormal,
    label: "Converted other building",
  },
  {
    attributeKey: "acc_commercial_building_pc",
    format: formatPercentNormal,
    label: "Within commercial premises",
  },
  {
    attributeKey: "acc_mobile_or_temp_pc",
    format: formatPercentNormal,
    label: "Caravans or temporary structures",
  },
];

export const OCCUPANCY_ATTRS: OutputAreaAttribute[] = [
  {
    attributeKey: "average_occupancy",
    label: "Average occupancy",
  },
  {
    attributeKey: "household_1_pc",
    format: formatPercentNormal,
    label: "1",
  },
  {
    attributeKey: "household_2_pc",
    format: formatPercentNormal,
    label: "2",
  },
  {
    attributeKey: "household_3_pc",
    format: formatPercentNormal,
    label: "3",
  },
  {
    attributeKey: "household_4_pc",
    format: formatPercentNormal,
    label: "4",
  },
  {
    attributeKey: "household_5_pc",
    format: formatPercentNormal,
    label: "5",
  },
  {
    attributeKey: "household_6_pc",
    format: formatPercentNormal,
    label: "6",
  },
  {
    attributeKey: "household_7_pc",
    format: formatPercentNormal,
    label: "7",
  },
  {
    attributeKey: "household_>7_pc",
    format: formatPercentNormal,
    label: "8+",
  },
];

export const PROPERTY_TENURE_ATTRS: OutputAreaAttribute[] = [
  {
    attributeKey: "tenure_owned_outright_pc",
    format: formatPercentNormal,
    label: "Owned outright",
  },
  {
    attributeKey: "tenure_owned_with_mortgage_pc",
    format: formatPercentNormal,
    label: "Owned with mortgage",
  },
  {
    attributeKey: "tenure_shared_ownership_pc",
    format: formatPercentNormal,
    label: "Shared ownership",
  },
  {
    attributeKey: "tenure_social_rented_pc",
    format: formatPercentNormal,
    label: "Social rent",
  },
  {
    attributeKey: "tenure_private_rented_pc",
    format: formatPercentNormal,
    label: "Private rent",
  },
  {
    attributeKey: "tenure_lives_rent_free_pc",
    format: formatPercentNormal,
    label: "Rent free",
  },
];

export const DWELLING_AGE_ATTRS: OutputAreaAttribute[] = [
  {
    attributeKey: "dwe_modal_age_band",
    label: "Most common dwelling age band",
  },
  {
    attributeKey: "dwe_post45pc",
    format: formatPercentNormal,
    label: "Percentage of dwellings constructed post-1945",
  },
  {
    attributeKey: "dwe_post16pc",
    format: formatPercentNormal,
    label: "Percentage of dwellings constructed post-2016",
  },
  {
    attributeKey: "dwe_modal_age_band_base",
    label: "Most common dwelling age band",
  },
  {
    attributeKey: "dwe_median_age_band",
    label: "Median dwelling age band",
  },
];

export const INCOME_ATTRS: OutputAreaAttribute[] = [
  // What do we do with this?
  // {
  //   attribute: "msoa_net_annual_income",
  //   label: "Net household income",
  //   format: formatGBP,
  // },
  {
    attributeKey: "net_annual_income",
    label: "Net household income",
    format: formatGBP,
  },
];

export const AFFORDABILITY_ATTRS = [
  {
    attributeKey: "median_affordability_2024",
    label: "Affordability ratio",
    xValue: 2024,
  },
  {
    attributeKey: "median_affordability_2023",
    label: "2023",
    xValue: 2023,
  },
  {
    attributeKey: "median_affordability_2022",
    label: "2022",
    xValue: 2022,
  },
  {
    attributeKey: "median_affordability_2021",
    label: "2021",
    xValue: 2021,
  },
  {
    attributeKey: "median_affordability_2020",
    label: "2020",
    xValue: 2020,
  },
  {
    attributeKey: "median_affordability_2019",
    label: "2019",
    xValue: 2019,
  },
  {
    attributeKey: "median_affordability_2018",
    label: "2018",
    xValue: 2018,
  },
  {
    attributeKey: "median_affordability_2017",
    label: "2017",
    xValue: 2017,
  },
  {
    attributeKey: "median_affordability_2016",
    label: "2016",
    xValue: 2016,
  },
  {
    attributeKey: "median_affordability_2015",
    label: "2015",
    xValue: 2015,
  },
  {
    attributeKey: "median_affordability_2014",
    label: "2014",
    xValue: 2014,
  },
  {
    attributeKey: "median_affordability_2013",
    label: "2013",
    xValue: 2013,
  },
  {
    attributeKey: "median_affordability_2012",
    label: "2012",
    xValue: 2012,
  },
  {
    attributeKey: "median_affordability_2011",
    label: "2011",
    xValue: 2011,
  },
  {
    attributeKey: "median_affordability_2010",
    label: "2010",
    xValue: 2010,
  },
  {
    attributeKey: "median_affordability_2009",
    label: "2009",
    xValue: 2009,
  },
  {
    attributeKey: "median_affordability_2008",
    label: "2008",
    xValue: 2008,
  },
  {
    attributeKey: "median_affordability_2007",
    label: "2007",
    xValue: 2007,
  },
  {
    attributeKey: "median_affordability_2006",
    label: "2006",
    xValue: 2006,
  },
  {
    attributeKey: "median_affordability_2005",
    label: "2005",
    xValue: 2005,
  },
  {
    attributeKey: "median_affordability_2004",
    label: "2004",
    xValue: 2004,
  },
  {
    attributeKey: "median_affordability_2003",
    label: "2003",
    xValue: 2003,
  },
  {
    attributeKey: "median_affordability_2002",
    label: "2002",
    xValue: 2002,
  },
];

export const POPULATION_CHANGE_5YR = {
  attributeKey: "pop_change_5yr",
  xValue: 2029,
  label: "5 year projection",
  format: formatSignedPercentNormal,
};

export const POPULATION_CHANGE_10YR = {
  attributeKey: "pop_change_10yr",
  xValue: 2034,
  label: "10 year projection",
  format: formatSignedPercentNormal,
};

export const POPULATION_CHANGE_65_5YR = {
  attributeKey: "pop_change_over_65_5yr",
  label: "5 year projection (aged 65+)",
  xValue: 2029,
  format: formatSignedPercentNormal,
};

export const POPULATION_CHANGE_65_10YR = {
  attributeKey: "pop_change_over_65_10yr",
  label: "10 year projection (aged 65+)",
  xValue: 2034,
  format: formatSignedPercentNormal,
};

export const POPULATION_CHANGE_ATTRS: OutputAreaAttribute[] = [
  POPULATION_CHANGE_5YR,
  POPULATION_CHANGE_10YR,
  POPULATION_CHANGE_65_5YR,
  POPULATION_CHANGE_65_10YR,
];

export const POPULATION_DENSITY_ATTRS: OutputAreaAttribute[] = [
  {
    attributeKey: "population_pskm",
    label: "Population density",
    format: formatPerSqKm,
  },
  {
    attributeKey: "rural_town_population_pskm",
    label: "Rural town",
    format: formatPerSqKm,
  },
  {
    attributeKey: "rural_areas_and_village_population_pskm",
    label: "Rural area or village",
    format: formatPerSqKm,
  },
  {
    attributeKey: "other_city_population_pskm",
    label: "City",
    format: formatPerSqKm,
  },
  {
    attributeKey: "london_population_pskm",
    label: "London",
    format: formatPerSqKm,
  },
];

export const EMPLOYMENT_ATTRS: OutputAreaAttribute[] = [
  {
    attributeKey: "full_time_employed_pc",
    format: formatPercentNormal,
    label: "Full-time employed",
  },
  {
    attributeKey: "part_time_employed_pc",
    format: formatPercentNormal,
    label: "Part-time employed",
  },
  {
    attributeKey: "unemployed_economically_active_pc",
    format: formatPercentNormal,
    label: "Unemployed",
  },
  {
    attributeKey: "retired_pc",
    format: formatPercentNormal,
    label: "Retired",
  },
  {
    attributeKey: "student_pc",
    format: formatPercentNormal,
    label: "Students",
  },
  {
    attributeKey: "non_working_other_pc",
    format: formatPercentNormal,
    label: "Economically inactive",
  },
];

export const DWELLING_DENSITY_ATTRS: OutputAreaAttribute[] = [
  {
    attributeKey: "dwelling_count",
    label: "Number of dwellings",
    format: formatNumber,
  },
  {
    attributeKey: "dwellings_per_ha",
    label: "Dwelling density",
    format: formatPerHa,
  },
  { attributeKey: "london_dwellings_per_ha", label: "London", format: formatPerHa },
  { attributeKey: "other_city_dwellings_per_ha", label: "City", format: formatPerHa },
  { attributeKey: "rural_town_dwellings_per_ha", label: "Rural town", format: formatPerHa },
  {
    attributeKey: "rural_areas_and_village_dwellings_per_ha",
    label: "Rural area or village",
    format: formatPerHa,
  },
];
