import { useEffect, useMemo, useState } from "react";
import { SkeletonLoading } from "react-migration/components/SkeletonLoading";
import { Column, Table } from "react-migration/components/Table";
import { Hatch } from "react-migration/domains/constraints/components/Hatch/Hatch";
import { useAspectStatsQuery } from "./Aspect/useAspectStatsQuery";
import { AspectCircularBarPlot } from "./AspectCircularBarPlot";
import { ASPECT_CATEGORIES } from "./constants";
import type {
  AspectCategoryKey,
  EnrichedAspectCategory,
  TopographySelectionSidebarInnerProps,
} from "./types";
import { AspectStatsStrict } from "./Aspect/types";

const ASPECT_COLUMNS = [
  {
    label: "Category",
    grow: false,
    render: ({ color, label }) => (
      <div className="atlas-flex atlas-gap-2">
        <Hatch borderColor={color} fillColor={color} />
        <span>{label}</span>
      </div>
    ),
  },
  {
    label: "Coverage",
    render: ({ percentage }) => <span>{percentage}%</span>,
  },
] as Column<EnrichedAspectCategory>[];

export function AspectSummary({
  selection,
  onLoaded,
  onStatsLoaded,
  hideChart,
}: TopographySelectionSidebarInnerProps<AspectStatsStrict>) {
  const [hoveredCategory, setHoveredCategory] = useState<AspectCategoryKey | undefined>();

  const onRowHoverHandler = (row: AspectCategoryKey | undefined) => {
    setHoveredCategory(row);
  };

  const {
    data: aspectStats,
    loading: loadingAspectStats,
    error: aspectError,
  } = useAspectStatsQuery(selection.feature?.geometry, {
    onLoaded,
  });

  useEffect(() => {
    if (aspectStats) {
      onStatsLoaded?.(aspectStats);
    }
  }, [aspectStats, onStatsLoaded]);

  const mappedAspectStats = useMemo<EnrichedAspectCategory[]>(
    () =>
      // remove flat from aspect table for now
      ASPECT_CATEGORIES.slice(1).map((category) => {
        const aspectCategoryResult = aspectStats?.find(
          (aspectResult) => aspectResult?.category === category.categoryKey
        );

        return {
          ...category,
          percentage: aspectCategoryResult?.percentageCoverage ?? 0,
        };
      }),
    [aspectStats]
  );

  const aspectPercentages = useMemo(
    () =>
      mappedAspectStats &&
      (Object.fromEntries(
        mappedAspectStats.map(({ percentage, categoryKey }) => [categoryKey, percentage])
      ) as Record<AspectCategoryKey, number>),
    [mappedAspectStats]
  );

  if (loadingAspectStats) {
    return (
      <div className="atlas-relative atlas-h-[628px] atlas-w-full">
        <SkeletonLoading rows={2} showPadding={false} />
      </div>
    );
  }

  if (aspectError) {
    // Caught by boundary above
    throw aspectError;
  }

  return (
    <>
      {!hideChart && (
        <AspectCircularBarPlot
          categoryPercentages={aspectPercentages}
          hoveredCategory={hoveredCategory}
          setHoveredCategory={setHoveredCategory}
        />
      )}

      <Table
        primaryKey="categoryKey"
        columns={ASPECT_COLUMNS}
        rows={mappedAspectStats}
        size="small"
        onRowHover={(row) => onRowHoverHandler(row?.categoryKey)}
      />
    </>
  );
}
