import {
  KeyIndicatorColor,
  useKeyIndicatorTags,
} from "react-migration/lib/map/useKeyIndicatorTags";
import { SkeletonLoading } from "react-migration/components/SkeletonLoading";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { useSelectionSidebarLoading } from "../../../Bundle/useSelectionSidebarLoadingState";
import { LayerTypeLayerSummaryProps, SupportedRegions } from "../../../types";
import { useTransactionsAtSelection } from "../useTransactionsAtSelection";
import { useWorkbench } from "../../../Bundle/Workbench/WorkbenchContext";
import { formatToCurrency } from "src/js/util/currency";
import { Currency } from "react-migration/lib/util/numberFormat";
import { Insight } from "react-migration/components/Insight/Insight";
import { dateString, formattedDateRange, subtractTime } from "src/js/util/dates";
import hasFeature from "src/js/stores/user/actions/hasFeature";
import Feature from "src/js/stores/user/Feature";
import { i18n } from "src/js/i18n";
import { defaultFilters } from "react-migration/domains/comparables/util/defaultFilters";

export function ComparablesLayerSummary({
  selection,
  onLoadingChange,
}: LayerTypeLayerSummaryProps) {
  const { t } = useTranslation();
  const { setDetailSelection } = useWorkbench();
  const { includedResults, loading, error } = useTransactionsAtSelection(
    selection,
    setDetailSelection,
    { ...defaultFilters, dateFrom: formattedDateRange(subtractTime(new Date(), "years", 35)) }
  );
  useSelectionSidebarLoading(onLoadingChange, loading);

  const latestResult = {
    currency: includedResults[0]?.currency || (hasFeature(Feature.usAccess) ? "USD" : "GBP"),
    date_of_transfer: includedResults[0]?.date_of_transfer || null,
    price: includedResults[0]?.price || null,
  };
  const { currency, date_of_transfer, price } = latestResult;

  useKeyIndicatorTags(() => {
    return [
      {
        color: KeyIndicatorColor.DEFAULT,
        tag: price
          ? formatToCurrency(price, currency as Currency, i18n.locale, 0)
          : t("workbench.summary.comparables.last_sold.empty"),
      },
    ];
  }, [currency, price, t]);

  ComparablesLayerSummary.icon = hasFeature(Feature.usAccess)
    ? "icon-lt-refund-2-line"
    : "icon-lt-money-pound-circle-line";

  if (loading) {
    return (
      <div className="atlas-relative atlas-p-2 atlas-h-[168px]">
        <SkeletonLoading rows={2} showPadding={false} bgClassName="atlas-bg-transparent" />
      </div>
    );
  }

  if (error) {
    // caught by error boundary
    throw error;
  }

  return (
    <>
      <div className="atlas-grid atlas-grid-cols-1 atlas-gap-2">
        <Insight.Wrapper title={t("workbench.summary.comparables.last_sold.title")}>
          <Insight.Body>
            {price
              ? formatToCurrency(price, currency as Currency, i18n.locale, 0)
              : t("workbench.summary.comparables.last_sold.empty")}
          </Insight.Body>
          <Insight.Footer description={t("workbench.summary.comparables.last_sold.description")}>
            {date_of_transfer
              ? dateString(date_of_transfer, hasFeature(Feature.usAccess) ? "en-US" : "en-GB")
              : "-"}
          </Insight.Footer>
        </Insight.Wrapper>
      </div>
    </>
  );
}
// @ts-expect-error icon defined in function
ComparablesLayerSummary.icon = ComparablesLayerSummary.icon as string;
ComparablesLayerSummary.supportedRegions = [SupportedRegions.england];
