import { createPortal } from "react-dom";
import { Icon } from "react-migration/components/Icon";
import { DropdownMenu, DropdownMenuSize } from "react-migration/components/DropdownMenu";

import Feature from "src/js/stores/user/Feature";
import { Intercom } from "src/js/util/intercom";
import { ENVIRONMENT } from "src/js/util/environment";
import { refreshUser } from "src/js/util/user";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { useAccountQuery } from "react-migration/domains/dashboard/topup/hooks/useAccountQuery";
import { userStore } from "src/js/stores/user/store";
import hasFeature from "src/js/stores/user/actions/hasFeature";

import { formatToCurrency } from "react-migration/lib/util/numberFormat";
import { logEvent } from "react-migration/lib/util/logEvent";

import {
  BalanceUKIcon,
  BalanceUSIcon,
  BillingIcon,
  ChatIcon,
  ContactIcon,
  LogoutIcon,
  PersonIcon,
} from "../Icons";
import {
  setShowTopupModal,
  setDebugModal,
  setShowAddressModal,
} from "src/js/stores/navigation/actions";
import hasBetaFeature from "src/js/stores/user/actions/hasBetaFeature";
import { Avatar, AvatarSize } from "react-migration/components/Avatar";
import { ReactPortal } from "react";

export const UserMenu = () => {
  const { t } = useTranslation();
  const { user } = useSnapshot(userStore);
  const { data: accountData } = useAccountQuery();
  const isUsUser = hasFeature(Feature.usAccess);

  const openTopupModal = () => {
    setShowTopupModal(true);
    logEvent("Top Up Started", { location: "modal" });
  };

  const openDebugModal = () => {
    setDebugModal(true);
    logEvent("Open Debug Modal");
  };

  const openAddressModal = () => {
    setShowAddressModal(true, {
      onComplete: () => {
        window.location.replace(`${ENVIRONMENT.ACCOUNTS_SERVICE_PRIVATE_API_URL}/customer-portal`);
      },
    });
    logEvent("Open Billing Address Modal");
  };

  const openBilling = () => {
    logEvent("Anchor Link Selected", { link: "customer-portal" });
    if (isUsUser && !accountData?.accountWithViews.billing_address?.length) {
      openAddressModal();
    } else {
      window.location.replace(`${ENVIRONMENT.ACCOUNTS_SERVICE_PRIVATE_API_URL}/customer-portal`);
    }
  };

  return (
    <DropdownMenu.Provider size={DropdownMenuSize.AUTO}>
      <DropdownMenu.Trigger
        data-testid="usermenu-trigger-element"
        align={DropdownMenu.TriggerAlignment["BOTTOM"]}
      >
        <Avatar
          firstName={user?.firstname ?? ""}
          lastName={user?.surname ?? ""}
          size={AvatarSize.MD}
          className="atlas-relative"
        />
      </DropdownMenu.Trigger>
      {
        createPortal(
          <DropdownMenu.Content className="atlas-min-w-[242px]">
            <DropdownMenu.ItemText>
              <div
                data-testid="your-account"
                className="atlas-flex atlas-flex-row atlas-items-center atlas-flex-1"
              >
                <Avatar
                  firstName={user?.firstname ?? ""}
                  lastName={user?.surname ?? ""}
                  size={AvatarSize.MD}
                  className="atlas-mr-2"
                />
                <div className="atlas-flex atlas-grow">
                  <div className="atlas-flex atlas-flex-col">
                    <span className="atlas-text-sm">{`${user.firstname} ${user.surname}`}</span>
                    <span className="atlas-text-xs atlas-capitalize atlas-text-neutral-400">
                      {`${user.plan_name} Plan`}
                    </span>
                  </div>
                </div>
                <div className="atlas-flex-none">
                  <PersonIcon />
                </div>
              </div>
            </DropdownMenu.ItemText>
            <DropdownMenu.Item
              onClick={() => openBilling()}
              data-testid="logout-element"
              className="atlas-flex atlas-items-center atlas-gap-2"
            >
              <BillingIcon />
              <span className="atlas-text-sm">{t("nav.nav.billing")}</span>
            </DropdownMenu.Item>
            {!hasFeature(Feature.usAccess) && (
              <DropdownMenu.Item
                data-testid="logout-element"
                className="atlas-flex atlas-items-center atlas-gap-2"
                disabled={!hasFeature(Feature.documentPurchase)}
                onClick={() => openTopupModal()}
              >
                {hasFeature(Feature.usAccess) ? <BalanceUSIcon /> : <BalanceUKIcon />}
                <span className="atlas-text-sm">{t("nav.nav.balance")}</span>
                <span className="atlas-text-sm">
                  {(accountData?.accountWithViews?.land_reg_credit_pounds || 0) < 0 && "-"}
                  {`${formatToCurrency({
                    amount: accountData?.accountWithViews.land_reg_credit_pounds || 0,
                    currency: hasFeature(Feature.usAccess) ? "USD" : "GBP",
                  })}`}
                </span>
              </DropdownMenu.Item>
            )}
            <DropdownMenu.Separator className="atlas-bg-neutral-200 atlas-mx-3" />
            <DropdownMenu.ItemText>
              <div
                data-testid="your-account"
                className="atlas-flex atlas-flex-row atlas-items-center atlas-flex-1"
              >
                <div className="atlas-flex atlas-grow">
                  <div className="atlas-flex atlas-flex-col">
                    <span className="atlas-text-sm">{t("nav.nav.support")}</span>
                    <span className="atlas-text-sm atlas-capitalize atlas-text-neutral-400">
                      {hasFeature(Feature.usAccess) ? "calum@land.tech" : "0203 086 7855"}
                    </span>
                  </div>
                </div>
                <div className="atlas-flex-none">
                  <ContactIcon />
                </div>
              </div>
            </DropdownMenu.ItemText>
            <DropdownMenu.Item
              data-testid="logout-element"
              className="atlas-flex atlas-items-center atlas-gap-2"
              onClick={() => Intercom("show")}
            >
              <ChatIcon />
              <span className="atlas-text-sm">{t("nav.nav.chat")}</span>
            </DropdownMenu.Item>
            {(ENVIRONMENT.APP_ENV === "local" || hasBetaFeature(Feature.debugMenu)) && (
              <DropdownMenu.Item
                data-testid="clear-cache"
                className="atlas-flex atlas-items-center atlas-gap-2"
                onClick={() => openDebugModal()}
              >
                <Icon icon="icon-lt-alert-line" extraClasses="atlas-opacity-60" />
                <span className="atlas-text-sm">{t("nav.nav.debug")}</span>
              </DropdownMenu.Item>
            )}
            <DropdownMenu.Separator />
            <DropdownMenu.ItemLink
              href={ENVIRONMENT.LOGOUT_URL}
              data-testid="logout-element"
              className="atlas-flex atlas-items-center atlas-gap-2"
            >
              <LogoutIcon />
              <span className="atlas-text-sm">{t("nav.nav.logout")}</span>
            </DropdownMenu.ItemLink>
            {ENVIRONMENT.APP_ENV === "local" && (
              <DropdownMenu.ItemLink
                data-testid="refresh-user"
                className="atlas-flex atlas-items-center atlas-gap-2 atlas-cursor-pointer"
                onClick={() => refreshUser()}
              >
                <PersonIcon />
                <span className="atlas-text-sm">Refresh User</span>
              </DropdownMenu.ItemLink>
            )}
          </DropdownMenu.Content>,
          document.body
        ) as ReactPortal
      }
    </DropdownMenu.Provider>
  );
};
