import type { CategoryRadius } from "react-migration/domains/constraints/designation/hooks/useNearestDesignationQuery";

export const MAX_TRANSPORT_RADIUS_METERS = 5000;

export const TRAIN_STATION_CATEGORY = "train_station";
export const SUBWAY_STATION_CATEGORY = "metro_station";
export const BUS_STOP_CATEGORY = "bus_stop";

export const TRANSPORT_RADIUSES: CategoryRadius[] = [
  { category: TRAIN_STATION_CATEGORY, radiusMeters: MAX_TRANSPORT_RADIUS_METERS },
  { category: SUBWAY_STATION_CATEGORY, radiusMeters: MAX_TRANSPORT_RADIUS_METERS },
  { category: BUS_STOP_CATEGORY, radiusMeters: MAX_TRANSPORT_RADIUS_METERS },
];

export const TRAVEL_TIMES_CATEGORY_ID = "travel_times";
export const TRAVEL_TILE_MEDIAN_SCORE_ATTRIBUTE_KEY = "amenity_tt_median_score";
export const PUBLIC_TRANSPORT_ACCESSIBILITY_RATING_ATTRIBUTE_KEY = "ptal_lt_rating";

export const TOWN_CENTER_ATTRIBUTE_KEYS = ["townwalkt", "towncyct", "townptt"];
export const EMPLOYMENT_ATTRIBUTE_KEYS = ["emp5000walkt", "emp5000cyct", "emp5000ptt"];
export const FOOD_ATTRIBUTE_KEYS = ["foodwalkt", "foodcyct", "foodptt"];
export const GP_ATTRIBUTE_KEYS = ["gpwalkt", "gpcyct", "gpptt"];
export const PRIMARY_SCHOOL_ATTRIBUTE_KEYS = ["pswalkt", "pscyct", "psptt"];
export const SECONDARY_SCHOOL_ATTRIBUTE_KEYS = ["sswalkt", "sscyct", "ssptt"];

export const TRAVEL_TIME_TABLE: [string, string[]][] = [
  ["Town centre", TOWN_CENTER_ATTRIBUTE_KEYS],
  ["Employment centre", EMPLOYMENT_ATTRIBUTE_KEYS],
  ["Food shops", FOOD_ATTRIBUTE_KEYS],
  ["GP", GP_ATTRIBUTE_KEYS],
  ["Primary school", PRIMARY_SCHOOL_ATTRIBUTE_KEYS],
  ["Secondary school", SECONDARY_SCHOOL_ATTRIBUTE_KEYS],
];
