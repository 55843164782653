export const ZONING_LAYER_TYPE_ID = "ZONING_LAYER_TYPE_ID";
export const ZONING_LAYER_SUMMARY_TYPE_ID = "ZONING_LAYER_SUMMARY_TYPE_ID";

export const POLICY_ZONING_DISPLAY_NAME_MAP: Record<string, { groupCategory: string }> = {
  zoning_agriculture_outer: {
    groupCategory: "",
  },
  zoning_planned_outer: {
    groupCategory: "",
  },
  zoning_special_outer: {
    groupCategory: "",
  },
  zoning_mixed_outer: {
    groupCategory: "",
  },
  zoning_single_family: {
    groupCategory: "Residential",
  },
  zoning_two_family: {
    groupCategory: "Residential",
  },
  zoning_multi_family: {
    groupCategory: "Residential",
  },
  zoning_neighborhood_commercial: {
    groupCategory: "Commercial",
  },
  zoning_general_commercial: {
    groupCategory: "Commercial",
  },
  zoning_core_commercial: {
    groupCategory: "Commercial",
  },
  zoning_retail_commercial: {
    groupCategory: "Commercial",
  },
  zoning_special_commercial: {
    groupCategory: "Commercial",
  },
  zoning_office: {
    groupCategory: "Commercial",
  },
  zoning_light_industrial: {
    groupCategory: "Industrial",
  },
  zoning_industrial: {
    groupCategory: "Industrial",
  },
};

export const POLICY_FLUM_DISPLAY_NAME_MAP: Record<string, { groupCategory: string; name: string }> =
  {
    flum_planned_outer: { name: "Planned", groupCategory: "" },
    flum_special: { name: "Special", groupCategory: "Special" },
    flum_federal: { name: "Federal", groupCategory: "Special" },
    flum_institutional: { name: "Institution", groupCategory: "Special" },
    flum_recreation: { name: "Recreation", groupCategory: "Special" },
    flum_unknown: { name: "Unknown", groupCategory: "Special" },
    flum_native_american_lands: { name: "Native American Lands", groupCategory: "Special" },
    flum_water: { name: "Water", groupCategory: "Special" },
    flum_conservation: { name: "Conservation", groupCategory: "Special" },
    flum_transportation_utilities: { name: "Transportation/Utilities", groupCategory: "Special" },
    flum_mixed_use: { name: "Mixed use", groupCategory: "Mixed use" },
    flum_neighborhood_activity_center: {
      name: "Neighborhood Activity Center",
      groupCategory: "Mixed use",
    },
    flum_downtown_urban_core: { name: "Downtown Urban Core", groupCategory: "Mixed use" },
    flum_mixed_use_general: { name: "General", groupCategory: "Mixed use" },
    flum_special_planning_area: { name: "Special Planning Area", groupCategory: "Mixed use" },
    flum_mixed_use_rural: { name: "Rural", groupCategory: "Mixed use" },
    flum_single_family: { name: "Single Family", groupCategory: "Residential" },
    flum_two_family: { name: "Two Family", groupCategory: "Residential" },
    flum_multi_family: { name: "Multi Family", groupCategory: "Residential" },
    flum_neighborhood_commercial: { name: "Neighborhood", groupCategory: "Commercial" },
    flum_general_commercial: { name: "General", groupCategory: "Commercial" },
    flum_core_commercial: { name: "Core", groupCategory: "Commercial" },
    flum_retail_commercial: { name: "Retail", groupCategory: "Commercial" },
    flum_special_commercial: { name: "Special", groupCategory: "Commercial" },
    flum_office: { name: "Office", groupCategory: "Commercial" },
    flum_light_industrial: { name: "Light Industrial", groupCategory: "Industrial" },
    flum_industrial: { name: "Industrial", groupCategory: "Industrial" },
  };
