import { useCallback, useEffect, useState } from "react";
import { LayerTypeLayerSummaryProps, SupportedRegions } from "../../types";
import { SlopeSummary } from "./SlopeSummary";
import { useMaxAreaSelectedElevationConstraint } from "./useMaxAreaSelectedElevationConstraint";
import { useTopographyStatsQuery } from "./useTopographyStatsQuery";
import { SkeletonLoading } from "react-migration/components/SkeletonLoading";
import { ElevationSummary } from "./TopographySelectionSidebar";
import { TagColor } from "react-migration/components/Tag";
import { useKeyIndicatorTags } from "react-migration/lib/map/useKeyIndicatorTags";
import { Notification } from "react-migration/components/Notification";
import { SlopeStatsStrict } from "./Slope/types";
import { SLOPE_CATEGORIES } from "./constants";

export const TopographySlopeSummary = ({
  selection,
  onLoadingChange,
}: LayerTypeLayerSummaryProps) => {
  const { exceedsMaximumAreaConstraint } = useMaxAreaSelectedElevationConstraint(selection);
  const selectionIsWithinAreaConstraint = !exceedsMaximumAreaConstraint;

  const { data, loading, error } = useTopographyStatsQuery(selection.feature?.geometry, {
    selectionIsWithinAreaConstraint,
  });

  useEffect(() => {
    onLoadingChange(true);
  }, [onLoadingChange]);

  useEffect(() => {
    if (exceedsMaximumAreaConstraint) {
      onLoadingChange(false);
    }
  }, [exceedsMaximumAreaConstraint, onLoadingChange]);

  const handleLoadingChange = useCallback(() => {
    onLoadingChange(false);
  }, [onLoadingChange]);

  const [slopeStats, setSlopeStats] = useState<SlopeStatsStrict | undefined>(undefined);
  const handleStatsLoaded = useCallback((stats: SlopeStatsStrict) => {
    setSlopeStats(stats);
  }, []);

  useKeyIndicatorTags(() => {
    if (exceedsMaximumAreaConstraint) {
      return [
        {
          tag: "Unavailable",
          color: TagColor.LIGHT_GRAY,
        },
      ];
    }

    if (!slopeStats) {
      return [];
    }

    const largest = Object.values(slopeStats).sort((a, b) => b!.percentage - a!.percentage)[0];
    const key = SLOPE_CATEGORIES.find((category) => category.categoryKey === largest?.category);

    return [
      {
        tag: `${Math.round(largest?.percentage ?? 0)}% ${key?.name}`,
        color: TagColor.DEFAULT,
      },
    ];

    return [];
  }, [slopeStats, exceedsMaximumAreaConstraint]);

  if (error) {
    // Caught by boundary above
    throw error;
  }

  if (selectionIsWithinAreaConstraint && !data?.elevation && !error) {
    return null;
  }

  if (exceedsMaximumAreaConstraint) {
    return (
      <Notification.Warning
        title="Unavailable"
        message="To view this insight, please select a site located in England and under 500 hectares in size"
      />
    );
  }

  if (loading) {
    return (
      <div className="atlas-relative atlas-h-[46px]">
        <SkeletonLoading rows={1} showPadding={false} />
      </div>
    );
  }

  return (
    <div className="atlas-flex atlas-flex-col atlas-gap-3">
      <ElevationSummary selection={selection} data={data} />
      <SlopeSummary
        selection={selection}
        onLoaded={handleLoadingChange}
        onStatsLoaded={handleStatsLoaded}
      />
    </div>
  );
};

TopographySlopeSummary.icon = "icon-lt-landscape-line";
TopographySlopeSummary.hideLink = true;
TopographySlopeSummary.supportedRegions = [SupportedRegions.england];
