// We assume all inputs to format() are in metric-small format,
// and all outputs of parse() will be given in metric-small.
// unitType can be "metric", "imperial", or "walking", where walking is at 3 miles/hour;

// All unit types should adhere to the same conventions, where they actually provide functionality.

// The measurement-display, conversions, range-to-text, and unitSwitcher should all be conisdered deprecated.

import numeral from "numeral";
import { dateString } from "src/js/util/dates";

const defaultFormatting = {
  small: "0,0",
  large: "0,0[.]00",
  walking: "0,0.0",
};

function numberRange(from, to, unt, { noneText = "--" }) {
  if (from == undefined && to == undefined) {
    return noneText;
  } else if (to == undefined) {
    return "Over " + from + unt;
  } else if (from == undefined) {
    return "Below " + to + unt;
  } else {
    return from + " to " + to + unt;
  }
}

export const area = {
  converter: ({ unitType, small = true }) => {
    if (unitType === "metric") {
      if (small) {
        return (x) => x;
      } else {
        return (x) => x / 10000;
      }
    } else {
      if (small) {
        return (x) => x * 10.7639;
      } else {
        return (x) => (x * 78125) / 316160658;
      }
    }
  },

  format: (
    x,
    { unitType, small = true, range = false, unit = true, rangeOpts = {}, formatting = {} }
  ) => {
    const format = { ...defaultFormatting, ...formatting };

    const fmt = small ? format.small : format.large;
    const unt = " " + area.unitString({ unitType, small });
    const convert = area.converter({ unitType, small });

    if (range) {
      return numberRange(
        x.from && numeral(convert(x.from)).format(fmt),
        x.to && numeral(convert(x.to)).format(fmt),
        unit ? unt : "",
        rangeOpts
      );
    } else {
      return numeral(convert(x)).format(fmt) + (unit ? unt : "");
    }
  },

  unitString: ({ unitType, small = true, longForm = false }) => {
    if (unitType === "metric") {
      if (small) {
        return longForm ? "square meters" : "m²";
      } else {
        return longForm ? "hectares" : "ha";
      }
    } else {
      if (small) {
        return longForm ? "square feet" : "ft²";
      } else {
        return "acres";
      }
    }
  },

  parse: (x, { unitType, small = true }) => {
    // note that we cant support small='auto' here
    x = Math.abs(parseFloat(x));
    if (unitType === "metric") {
      if (small) {
        return x; // m
      } else {
        return x * 10000; // ha
      }
    } else {
      if (small) {
        return x * 10.7639; // sqft
      } else {
        return (x * 316160658) / 78125; // acres
      }
    }
  },
};

export const height = {
  format: (x, { unitType }) => {
    if (unitType === "metric") {
      return numeral(x).format("0,0[.]00") + " m";
    } else {
      return numeral(x * 3.28084).format("0,0[.]00") + " ft";
    }
  },
};

export const date = {
  format: (x, { locale = "en-GB", range = false, rangeOpts = { noneText: "--" } }) => {
    if (range) {
      const { from, to } = x;
      if (from == undefined && to == undefined) {
        return rangeOpts.noneText;
      } else if (to == undefined) {
        return "After " + dateString(from, locale);
      } else if (from == undefined) {
        return "Before " + dateString(to, locale);
      } else {
        return dateString(from, locale) + " - " + dateString(to, locale);
      }
    } else {
      return dateString(x, locale);
    }
  },
};

export const price = {
  format: (x, { range = false, unit = true, rangeOpts = { noneText: "--" } }) => {
    const fmt = "0,0[.]00";
    const unt = unit ? "£" : "";

    if (range) {
      const { from, to } = x;
      if (from == undefined && to == undefined) {
        return rangeOpts.noneText;
      } else if (to == undefined) {
        return "Over " + unt + numeral(from).format(fmt);
      } else if (from == undefined) {
        return "Below " + unt + numeral(to).format(fmt);
      } else {
        return unt + numeral(from).format(fmt) + " - " + unt + numeral(to).format(fmt);
      }
    } else {
      return unt + numeral(x).format(fmt);
    }
  },
};

export const homes = {
  format: (x, { range = false, unit = true, rangeOpts = {} }) => {
    const fmt = "0";
    const unt = unit ? " homes" : "";

    if (range) {
      return numberRange(
        x.from && numeral(x.from).format(fmt),
        x.to && numeral(x.to).format(fmt),
        unt,
        rangeOpts
      );
    } else {
      return numeral(x).format(fmt) + unt;
    }
  },
};

export const length = {
  periodForZoom: (zoom, { unitType, small = true }) => {
    // currently this is intended for the ruler...not sure if anything else might want it too

    zoom = Math.min(22, zoom);
    let period = (1 << (22 - zoom)) * (unitType === "metric" ? 1 : 2);
    period = length.converter({ unitType, small })(period);
    return Math.pow(10, Math.floor(Math.log10(period))); // round up to power of ten
  },

  useSmall: (x, { unitType }) => {
    // for use with small='auto'
    if (unitType === "metric") {
      return x < 1000;
    } else if (unitType === "imperial") {
      return x < 1609.34 / 2;
    } else {
      return false;
    }
  },

  converter: ({ unitType, small = true }) => {
    if (unitType === "metric") {
      return (x) => {
        small = small === "auto" ? length.useSmall(x, { unitType }) : small;
        if (small) {
          return x;
        } else {
          return x / 1000;
        }
      };
    } else if (unitType === "walking") {
      return (x) => x / 80.476;
    } else {
      return (x) => {
        small = small === "auto" ? length.useSmall(x, { unitType }) : small;
        if (small) {
          return x * 3.28084;
        } else {
          return x / 1609.34;
        }
      };
    }
  },

  format: (
    x,
    { unitType, small = true, units = true, converted = false, space = " ", formatting = {} }
  ) => {
    const format = { ...defaultFormatting, ...formatting };

    // eslint-disable-next-line no-shadow
    const convert = converted ? (x) => x : length.converter({ unitType, small });
    small = small === "auto" ? length.useSmall(x, { unitType }) : small;

    x = convert(x);
    if (unitType === "metric") {
      if (small) {
        return numeral(x).format(format.small) + (units ? space + "m" : "");
      } else {
        return numeral(x).format(format.large) + (units ? space + "km" : "");
      }
    } else if (unitType === "walking") {
      return numeral(x).format(format.walking) + (units ? space + "mins" : "");
    } else {
      if (small) {
        return numeral(x).format(format.small) + (units ? space + "ft" : "");
      } else {
        return numeral(x).format(format.large) + (units ? space + "miles" : "");
      }
    }
  },

  parse: (x, { unitType, small = true }) => {
    // note that we cant support small='auto' here
    x = Math.abs(parseFloat(x));
    if (unitType === "metric") {
      if (small) {
        return x; // m
      } else {
        return x * 1000; // km
      }
    } else if (unitType === "walking") {
      return x * 80.467; //mins
    } else {
      if (small) {
        return x / 3.28084; // ft
      } else {
        return x * 1609.34; // miles
      }
    }
  },
};
