import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { noop } from "lodash";
import clsx from "clsx";

import { SelectionFeature, SelectionType } from "src/js/stores/map/store";
import hasFeature from "src/js/stores/user/actions/hasFeature";
import Feature from "src/js/stores/user/Feature";
import { useTrackedApplicationSelectionHandler } from "react-migration/domains/planning/utils/useTrackedApplicationSelectionHandler";
import { PlanningApplication, RawApplication } from "react-migration/domains/planning/types";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { TextInputV2 } from "react-migration/components/TextInputV2";
import { logEventV2 } from "react-migration/lib/util/logEventV2";
import { DividerV2 } from "react-migration/components/DividerV2";
import { Button } from "react-migration/components/Button";
import { Icon } from "react-migration/components/Icon";

import {
  LayerTypeSelectionSidebarHeaderProps,
  LayerTypeSelectionSidebarProps,
} from "../../../types";
import { CollapsibleConsiderationsCard } from "../../../Bundle/Workbench/AssessmentTab/CollapsibleConsiderationsCard";
import { useSelectionSidebarLoading } from "../../../Bundle/useSelectionSidebarLoadingState";
import { PlanningApplicationsByAddress } from "../PlanningApplicationsByAddress";
import { SummaryGenerate } from "../PlanningApplicationsSummary/components/SummaryGenerate";
import { PlanningApplicationsFilters } from "../components/PlanningApplicationsFilters";
import {
  PlanningState,
  PlanningStateDispatch,
  usePlanningFilterState,
} from "../state/usePlanningFilterState";
import { useClassAndTypeFilters } from "../Context/hooks";
import { ClassificationToggles } from "../components/ClassificationToggles";
import { PlanningLayerConfig } from "../types";
import { PlanningApplicationsSummary } from "../PlanningApplicationsSummary";
import { usePlanningApplicationListBySelection } from "./usePlanningApplicationListBySelection";
import { usePlanningTracking } from "./usePlanningTracking";

export type FilterableApplication = RawApplication & {
  _id: string;
};

export function PlanningApplicationSelectionSidebar({
  layer,
  selection,
  setDetailSelection,
  onLoadingChange,
  cardExpanded,
}: LayerTypeSelectionSidebarProps) {
  const planningLayerConfig = (layer?.layerConfig ?? {}) as PlanningLayerConfig;
  const [state, stateDispatch, { hasFilters }] = usePlanningFilterState(
    planningLayerConfig.localStorageKey,
    planningLayerConfig.initialFilterState
  );

  const [, planningApplicationLayerFilters] = useClassAndTypeFilters(state);
  const { filteredPlanningApplications, initialApplications, loading, error } =
    usePlanningApplicationListBySelection(selection, {
      useLayerFilters: true,
      layerFilters: planningApplicationLayerFilters,
    });

  useSelectionSidebarLoading(onLoadingChange, loading);

  if (error) {
    // If this errors then the planning summary card will already render an
    // error to represent the error to the user.
    console.error(error);
    return null;
  }

  if (!initialApplications.length) return null;

  return (
    <div key={selection.id} className="atlas-flex atlas-flex-col atlas-gap-y-2">
      {hasFeature(Feature.planningHistorySummary) && (
        <AISummaryCard
          selection={selection}
          title={layer.title}
          referenceApplications={initialApplications}
          setDetailSelection={setDetailSelection}
        />
      )}
      <PlanningApplicationsCard
        layer={layer}
        state={state}
        stateDispatch={stateDispatch}
        applications={filteredPlanningApplications}
        hasFilters={hasFilters}
        setDetailSelection={setDetailSelection}
        onLoadingChange={onLoadingChange}
        cardExpanded={cardExpanded}
        onSelection={noop}
      />
    </div>
  );
}

const AISummaryCard = ({
  selection,
  title,
  referenceApplications,
  setDetailSelection,
}: {
  selection: SelectionFeature;
  title: string;
  referenceApplications: PlanningApplication[];
  setDetailSelection: (selection: SelectionFeature) => void;
}) => {
  const { t } = useTranslation();
  const [showSummary, setShowSummary] = useState(false);

  const trackedSetApplicationDetailSelectionHandler = useCallback(
    (selection: SelectionFeature) => {
      setDetailSelection(selection);

      logEventV2({
        name: "Workbench card click",
        properties: {
          cardName: t("planning.ai.title"),
          clickDetail: "Planning application",
          action: "goTo",
          planningApplicationID: selection.id,
        },
      });
    },
    [setDetailSelection, t]
  );

  return (
    <CollapsibleConsiderationsCard
      heading={
        <>
          <Icon size="md" icon="icon-lt-sparkling-line atlas-text-platform-500" />
          <h1 className="atlas-text-sm atlas-font-semibold atlas-text-text-base-primary">
            {t("planning.ai.title")}
          </h1>
        </>
      }
      name={title}
      defaultExpanded={true}
    >
      {showSummary ? (
        <PlanningApplicationsSummary
          selection={selection}
          referenceApplications={referenceApplications}
          setDetailSelection={trackedSetApplicationDetailSelectionHandler}
        />
      ) : (
        <SummaryGenerate setShowSummary={setShowSummary} cardName={title} />
      )}
    </CollapsibleConsiderationsCard>
  );
};

type PlanningApplicationsCardProps = Omit<LayerTypeSelectionSidebarProps, "selection"> & {
  state: PlanningState;
  stateDispatch: PlanningStateDispatch;
  applications: PlanningApplication[];
  hasFilters: boolean;
};

const PlanningApplicationsCard = ({
  layer,
  state,
  stateDispatch,
  applications,
  hasFilters,
  setDetailSelection,
  cardExpanded,
}: PlanningApplicationsCardProps) => {
  const { debouncedTrackControlChange } = usePlanningTracking(layer.title);

  const [search, setSearch] = useState("");
  const [showFilter, setShowFilter] = useState(false);

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value),
    []
  );

  const filteredByTitleApplications = useMemo(() => {
    return applications.filter((application) =>
      application.title.toLowerCase().includes(search.toLowerCase())
    );
  }, [applications, search]);

  const handleShowFilter = useCallback(() => {
    logEventV2({
      name: "Workbench filter click",
      properties: {
        cardName: layer.title,
        filterName: "Show",
        filterState: !showFilter,
      },
    });
    setShowFilter(!showFilter);
  }, [showFilter, layer.title]);

  const trackedSetApplicationDetailSelectionHandler = useTrackedApplicationSelectionHandler(
    setDetailSelection,
    {
      cardName: layer.title,
      clickDetail: "Planning application",
    }
  );

  return (
    <CollapsibleConsiderationsCard
      heading={layer.title}
      name={layer.title}
      count={filteredByTitleApplications?.length}
      defaultExpanded={cardExpanded}
    >
      <div className="atlas-flex atlas-p-2 atlas-items-center atlas-space-x-2">
        <div className="atlas-flex-grow">
          <TextInputV2
            onChange={handleInputChange}
            dataTestId="labels-search-input"
            value={search}
            placeholder="Description search..."
            IconLeft={
              <div className="atlas-w-5 atlas-h-5 atlas-text-blueGrey-800">
                <SearchIcon />
              </div>
            }
          />
        </div>
        <Button data-testid="filter-planning" variant="tertiary" onClick={handleShowFilter}>
          <Icon
            icon="icon-lt-filter-line"
            extraClasses="atlas-text-neutral-500 atlas-pointer-events-none"
          />
          {showFilter && <span className="atlas-text-sm atlas-font-medium">Close</span>}
          {hasFilters && !showFilter && (
            <div className="atlas-absolute atlas-bg-red-500 atlas-h-2 atlas-right-0 atlas-rounded-full atlas-top-0 atlas-w-2" />
          )}
        </Button>
      </div>

      {showFilter && (
        <div className="atlas-p-2 atlas-gap-4">
          <div className="atlas-flex atlas-justify-between atlas-items-start atlas-pb-4">
            <div className="atlas-text-black atlas-text-sm atlas-font-bold atlas-leading-tight">
              Type:
            </div>
            <button
              disabled={!hasFilters}
              className="atlas-h-5 atlas-rounded atlas-justify-end atlas-items-center atlas-gap-1 atlas-flex"
              onClick={() => stateDispatch({ type: "RESET_STATE" })}
            >
              <span
                className={clsx(
                  "atlas-text-center atlas-text-sm atlas-font-medium atlas-underline atlas-text-platformBlue-600 atlas-leading-tight",
                  { "atlas-opacity-50": !hasFilters }
                )}
              >
                Reset filters
              </span>
            </button>
          </div>
          <div className="atlas-space-y-2 atlas-pb-4">
            <ClassificationToggles
              state={state}
              prefix="planning-selection"
              stateDispatch={stateDispatch}
              debouncedTrackControlChange={debouncedTrackControlChange}
            />
          </div>
          <DividerV2 />
          <PlanningApplicationsFilters
            columns={true}
            state={state}
            stateDispatch={stateDispatch}
            debouncedTrackControlChange={debouncedTrackControlChange}
          />
        </div>
      )}

      {hasFilters && (
        <div className="atlas-p-2">
          <div className="atlas-p-3 atlas-h-12 atlas-rounded-xl atlas-border atlas-border-[#e3e7eb] atlas-justify-start atlas-items-center atlas-inline-flex atlas-w-full">
            <div className="atlas-pr-2 atlas-justify-start atlas-items-start atlas-flex">
              <Icon
                icon="icon-lt-filter-line"
                extraClasses="atlas-text-amber-600 atlas-pointer-events-none"
              />
            </div>
            <span className="atlas-flex atlas-gap-4 atlas-text-base atlas-font-medium atlas-leading-normal">
              Filters are applied
            </span>
            <Button
              data-testid="filter-planning"
              variant="ghost"
              className="atlas-ml-auto"
              onClick={() => stateDispatch({ type: "RESET_STATE" })}
            >
              <Icon
                icon="icon-lt-close-line"
                extraClasses="atlas-text-neutral-500 atlas-pointer-events-none"
              />
            </Button>
          </div>
        </div>
      )}

      <div className="atlas-p-2">
        {filteredByTitleApplications.length > 0 ? (
          <PlanningApplicationsByAddress
            planningApplications={filteredByTitleApplications}
            onApplicationClick={trackedSetApplicationDetailSelectionHandler}
          />
        ) : (
          <span className="atlas-text-sm atlas-text-text-base-primary">
            No results match your specified criteria.
          </span>
        )}
      </div>
    </CollapsibleConsiderationsCard>
  );
};

function PlanningApplicationSelectionSidebarHeader({
  selection,
  defaultTitle,
}: LayerTypeSelectionSidebarHeaderProps) {
  const title =
    selection.type === SelectionType.PLANNING_APPLICATION ? `Other ${defaultTitle}` : defaultTitle;
  return <>{title}</>;
}

PlanningApplicationSelectionSidebar.Header = PlanningApplicationSelectionSidebarHeader;

const SearchIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16">
      <g clipPath="url(#clip0_3421_2488)">
        <path
          fill="currentColor"
          d="M12.021 11.078l2.856 2.854-.944.944-2.854-2.856a6.002 6.002 0 01-9.745-4.687c0-3.312 2.688-6 6-6a6.002 6.002 0 014.687 9.745zm-1.337-.495a4.665 4.665 0 00-3.35-7.917 4.665 4.665 0 00-4.667 4.667 4.665 4.665 0 007.917 3.35l.1-.1z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_3421_2488">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
