import { InsightStatusType } from "react-migration/components/Insight/components/Body/Body";
import { LpaComparisonFormatter } from "./types";

export const formatApprovalRate: LpaComparisonFormatter = (value, regionalValue) => {
  if (!value) {
    return {
      footerValue: "N/A",
      status: "grey",
      body: "N/A",
    };
  }

  const formattedValue = Number(value ?? 0);
  const formattedRegionalValue = Number(regionalValue ?? 0);

  let status: InsightStatusType = formattedValue >= formattedRegionalValue ? "green" : "amber";
  if (formattedRegionalValue - formattedValue > 5) {
    status = "red";
  }

  return {
    footerValue: regionalValue + "%",
    body: value + "%",
    status,
  };
};
