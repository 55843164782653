import Feature from "src/js/stores/user/Feature";
import { ConstraintsCategory } from "../../Constraints/types";
import {
  DemographicsLayerConfig,
  DemographicsLayerType,
  DemographicsSummaryLayerType,
} from "../layer_types/DemographicsLayerType/DemographicsLayerType";
import { GenericLayer, Layer } from "../types";

export type DemographicsLayer = GenericLayer<DemographicsLayerConfig>;

const densityRangeConditions = (style: string) => [
  { categoryId: null, alert: `Zoom in to view ${style}`, maxZoom: 9 },
  { categoryId: ConstraintsCategory.UK_DEMOGRAPHICS_MSOA, maxZoom: 12 },
  { categoryId: ConstraintsCategory.UK_DEMOGRAPHICS_LSOA },
];

const affordabilityConditions = [
  { categoryId: ConstraintsCategory.UK_DEMOGRAPHICS_COUNTRY, maxZoom: 6 },
  { categoryId: ConstraintsCategory.UK_DEMOGRAPHICS_REGION, maxZoom: 8 },
  { categoryId: ConstraintsCategory.UK_DEMOGRAPHICS_LAD, maxZoom: 12 },
  { categoryId: ConstraintsCategory.UK_DEMOGRAPHICS_MSOA },
];

// Only available at LAD level and above
const broadDatasetConditions = [
  { categoryId: ConstraintsCategory.UK_DEMOGRAPHICS_COUNTRY, maxZoom: 6 },
  { categoryId: ConstraintsCategory.UK_DEMOGRAPHICS_REGION, maxZoom: 8 },
  { categoryId: ConstraintsCategory.UK_DEMOGRAPHICS_LAD },
];

// Only available at LAD level and below
const granularDatasetConditions = [
  { categoryId: ConstraintsCategory.UK_DEMOGRAPHICS_LAD, maxZoom: 9 },
  { categoryId: ConstraintsCategory.UK_DEMOGRAPHICS_MSOA, maxZoom: 14 },
  { categoryId: ConstraintsCategory.UK_DEMOGRAPHICS_LSOA },
];

export const DEMOGRAPHICS_UK: Layer = {
  id: "uk_demographics",
  layerType: DemographicsLayerType,
  hideByDefault: true,
  title: "Demographics",
  toggleable: true,
  requiredFeatureAccess: [Feature.amenitiesBundle],
  description: "Insights into the local population inc. age and income.",
  layerConfig: {
    region: "UK",
    subCategoryConditions: [
      { categoryId: ConstraintsCategory.UK_DEMOGRAPHICS_COUNTRY, maxZoom: 6 },
      { categoryId: ConstraintsCategory.UK_DEMOGRAPHICS_REGION, maxZoom: 8 },
      { categoryId: ConstraintsCategory.UK_DEMOGRAPHICS_LAD, maxZoom: 12 },
      { categoryId: ConstraintsCategory.UK_DEMOGRAPHICS_MSOA, maxZoom: 14 },
      { categoryId: ConstraintsCategory.UK_DEMOGRAPHICS_LSOA },
    ],
    attributes_style_options: [
      null, // divider
      {
        attribute: "median_age_decile",
        style: "decile_sequential_blue",
        displayName: "Median age",
      },
      {
        attribute: "age_>65pc",
        style: "default_percentage_normal_0_to_25",
        displayName: "Population (65+)",
      },
      {
        attribute: "population_density_decile",
        style: "decile_sequential_blue",
        displayName: "Population density",
        customConditions: densityRangeConditions("population density"),
      },
      {
        attribute: "pop_change_5yr_decile",
        style: "decile_sequential_blue",
        displayName: "Population projection over 5 years",
        customConditions: broadDatasetConditions,
      },
      {
        attribute: "pop_change_10yr_decile",
        style: "decile_sequential_blue",
        displayName: "Population projection over 10 years",
        customConditions: broadDatasetConditions,
      },
      // // missing from the MSOA & LSOA
      // {
      //   attribute: "pop_change_over_65_5yr_decile",
      //   style: "default_decile",
      //   //Better name?
      //   displayName: "Population growth over 5 years (65+)",
      // },
      // {
      //   attribute: "pop_change_over_65_10yr_decile",
      //   style: "default_decile",
      //   //Better name?
      //   displayName: "Population growth over 10 years (65+)",
      // },
      {
        attribute: "net_annual_income_decile",
        style: "decile_sequential_green",
        displayName: "Income",
      },
      {
        attribute: "affordability_decile",
        style: "affordability_ratio",
        displayName: "Affordability",
        customConditions: affordabilityConditions,
      },
      {
        attribute: "imd_decile_2019",
        style: "deprivation_index",
        displayName: "Deprivation index (IMD 2019)",
        customConditions: granularDatasetConditions,
      },

      null, // divider
      {
        attribute: "full_time_employed_decile",
        style: "decile_diverging_red_yellow_green",
        displayName: "Full-time employed",
      },
      {
        attribute: "part_time_employed_decile",
        style: "decile_diverging_red_yellow_green",
        displayName: "Part-time employed",
      },
      {
        attribute: "unemployed_economically_active_decile",
        style: "decile_diverging_red_yellow_green",
        displayName: "Unemployed",
      },
      {
        attribute: "retired_decile",
        style: "decile_diverging_red_yellow_green",
        displayName: "Retired",
      },
      {
        attribute: "student_decile",
        style: "decile_diverging_red_yellow_green",
        displayName: "Students",
      },
      {
        attribute: "non_working_other_decile",
        style: "decile_diverging_red_yellow_green",
        displayName: "Economically inactive",
      },
      null, // divider
      {
        attribute: "occ_rmo_pc_decile",
        style: "decile_sequential_blue",
        displayName: "Routine or manual occupations",
      },
      {
        attribute: "occ_io_pc_decile",
        style: "decile_sequential_blue",
        displayName: "Intermediate occupations",
      },
      {
        attribute: "occ_mpa_pc_decile",
        style: "decile_sequential_blue",
        displayName: "Managerial, professional and administrative occupations",
      },
      {
        attribute: "occ_stnwltu_pc_decile",
        style: "decile_sequential_blue",
        displayName: "Students, never worked or long-term unemployed",
      },
      null, // divider
      {
        attribute: "occupancy_decile",
        style: "decile_sequential_blue",
        displayName: "Household size",
      },
      {
        attribute: "dwellings_per_ha_decile",
        style: "decile_sequential_blue",
        displayName: "Dwelling density",
        customConditions: densityRangeConditions("dwelling density"),
      },
      /*
      // doesn't exist yet
      {
        attribute: "housing_type",
        style: "housing_type_qualitative",
        displayName: "Housing type",
      },
      {
        attribute: "housing_age_bands",
        style: "housing_type_sequential",
        displayName: "Housing age",
      },
      */
      // {
      //   attribute: "tenure",
      //   style: "housing_type_qualitative",
      //   displayName: "Tenure",
      // },
    ],
  },
};

export const DEMOGRAPHICS_SUMMARY_UK: Layer = {
  id: "uk_demographics_summary",
  layerType: DemographicsSummaryLayerType,
  hideByDefault: false,
  title: "Local age & income",
  toggleable: true,
  requiredFeatureAccess: [Feature.insightsSummary, Feature.amenitiesBundle],
  requiredBetaAccess: [Feature.insightsSummaryBeta],
  description: "Insights into the local population inc. age and income.",
  layerConfig: DEMOGRAPHICS_UK.layerConfig,
};
