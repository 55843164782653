import { Tooltip } from "react-migration/components/Tooltip";
import { Theme } from "react-migration/lib/theme/Theme";

interface InsightSelectedAreaWithTooltipProps {
  children?: string;
}

export const InsightSelectedAreaWithTooltip = ({
  children,
}: InsightSelectedAreaWithTooltipProps) => {
  if (!children) return null;

  return (
    <div>
      <Tooltip.Wrapper>
        <Tooltip.Content size="sm" side="top" align="center" theme={Theme.Light}>
          <Tooltip.Description>
            A site may have multiple overlapping areas. The insights shown will represent the
            largest area of overlap.
          </Tooltip.Description>
        </Tooltip.Content>
        <Tooltip.Trigger>
          <p className="atlas-text-xs atlas-mt-[10px] atlas-inline-flex atlas-max-w-full atlas-gap-x-1">
            <span
              className="atlas-text-text-base-primary atlas-cursor-default atlas-truncate"
              title={children}
            >
              {children}
            </span>
            <i className="icon-lt-information-line atlas-text-text-base-secondary-a print:atlas-hidden" />
          </p>
        </Tooltip.Trigger>
      </Tooltip.Wrapper>
    </div>
  );
};
