import { Tooltip } from "react-migration/components/Tooltip";
import { Theme } from "react-migration/lib/theme/Theme";

export const RecentApplicationsInfoTooltip = () => (
  <Tooltip.Wrapper>
    <Tooltip.Content size="sm" side="bottom" align="end" theme={Theme.Light}>
      <Tooltip.Description>
        <p className="atlas-my-2 atlas-text-xs atlas-font-normal">
          <h1 className="atlas-text-xs atlas-font-bold atlas-mb-1">Included application types:</h1>
          Full Application, Outline, Reserved Matters, Prior Approval, Change of Use, Environmental
          Impact Assessment (EIA), and any uncategorised applications.
        </p>
        <p className="atlas-my-2 atlas-text-xs atlas-font-normal">
          <h1 className="atlas-text-xs atlas-font-bold atlas-mb-1">Excluded application types:</h1>
          Work to Trees, Listed Buildings, Discharge of Conditions.
        </p>
      </Tooltip.Description>
    </Tooltip.Content>
    <Tooltip.Trigger>
      <i className="icon-lt-information-line atlas-text-text-base-secondary-a print:atlas-hidden" />
    </Tooltip.Trigger>
  </Tooltip.Wrapper>
);
