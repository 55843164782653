import { useContext, useCallback } from "react";
import { SelectFilterModal, SelectFilterPopover } from "react-migration/components/SelectFilter";
import { useSiteLabelOptions } from "react-migration/domains/sites/hooks/useSiteLabelOptions";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { SiteFiltersContext } from "../SiteFilters";

type SiteLabelsFilterProps = {
  className?: string;
  type: "mobile" | "desktop";
  openModal?: boolean;
  setOpenModal?: (v: boolean) => void;
};
export const SiteLabelsFilter = ({
  className,
  type,
  openModal,
  setOpenModal,
}: SiteLabelsFilterProps) => {
  const { t } = useTranslation();
  const { onFilterUpdate, useSiteLabels, updateSiteLabels } = useContext(SiteFiltersContext);

  const siteLabelOptions = useSiteLabelOptions();
  const selectedLabelIds = useSiteLabels();

  const onSelectedOptionsUpdate = useCallback(
    (optionIds: string[]) => {
      onFilterUpdate("siteLabels");
      updateSiteLabels(optionIds);
    },
    [onFilterUpdate, updateSiteLabels]
  );

  if (siteLabelOptions.length > 0) {
    if (type === "desktop") {
      return (
        <SelectFilterPopover
          dataTestId="labels-select-filter"
          label={t("sites.pipeline.filters.label")}
          contentTitle={t("sites.pipeline.filters.select_labels")}
          options={siteLabelOptions}
          selectedOptionIds={selectedLabelIds}
          onSelectedOptionsUpdate={onSelectedOptionsUpdate}
          className={className}
        />
      );
    }
    if (type === "mobile" && openModal && setOpenModal) {
      return (
        <SelectFilterModal
          contentTitle={t("sites.pipeline.filters.select_labels")}
          options={siteLabelOptions}
          selectedOptionIds={selectedLabelIds}
          onSelectedOptionsUpdate={onSelectedOptionsUpdate}
          open={openModal}
          setOpen={setOpenModal}
        />
      );
    }
  } else {
    return null;
  }
};
