import { KeyIndicatorColor } from "react-migration/lib/map/useKeyIndicatorTags";

export const approvalRateTag = ({
  totalValue,
  totalRegionalValue,
}: {
  totalValue: number;
  totalRegionalValue: number;
}) => {
  const tag = totalValue + "%";
  if (totalValue > totalRegionalValue) {
    return {
      color: KeyIndicatorColor.GREEN,
      tag,
    };
  } else if (totalRegionalValue - totalValue <= 5) {
    return {
      color: KeyIndicatorColor.ORANGE,
      tag,
    };
  } else {
    return {
      color: KeyIndicatorColor.RED,
      tag,
    };
  }
};
