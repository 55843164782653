import clsx from "clsx";
import { padStart } from "lodash";
import { useId, useMemo, useState } from "react";

export type SparklineCategory = {
  categoryKey: string;
  name: string;
  color: string;
  percentage: number;
};

export interface SparklineProps {
  categories: SparklineCategory[];
  hoveredCategoryKey?: string;
  showLegend?: boolean;
  showNone?: boolean;
  noneCategoryPosition?: "start" | "end";
  noneLabel?: string;
  decimalPlaces?: number;
}

const BAR_HEIGHT = 24;
const BAR_RADIUS = 4;

export const Sparkline = ({
  categories,
  hoveredCategoryKey: overridenHoveredCategoryKey,
  showLegend,
  showNone = true,
  noneCategoryPosition = "start",
  noneLabel = "None",
  decimalPlaces = 2,
}: SparklineProps) => {
  const roundedBarMaskId = `sparkline-rounded-bar-mask-${useId()}`;
  const roundedBarMask = `url(#${roundedBarMaskId})`;
  const [localHovered, setLocalHovered] = useState<string | undefined>(undefined);
  const hoveredCategoryKey = localHovered || overridenHoveredCategoryKey;

  const segments = useMemo(() => {
    const accumulativePercentage = categories.reduce((acc, { percentage }) => acc + percentage, 0);
    const nonePercentage = +(100 - accumulativePercentage).toFixed(2);
    const noneCategoryAtStart = noneCategoryPosition === "start";
    let segments = [];
    let x = noneCategoryAtStart ? nonePercentage : 0;

    for (const category of categories) {
      const width = category.percentage;

      segments.push({
        ...category,
        x,
        width,
        isHovered: hoveredCategoryKey === category.categoryKey,
      });

      x += width;
    }

    if (showNone) {
      const noneCategory = {
        categoryKey: "none",
        name: noneLabel,
        color: "rgb(160, 172, 188)",
        percentage: nonePercentage,
        width: nonePercentage,
        isHovered: hoveredCategoryKey === "none",
        x: noneCategoryAtStart ? 0 : accumulativePercentage,
      };

      if (noneCategoryAtStart) {
        segments = [noneCategory, ...segments];
      } else {
        segments = [...segments, noneCategory];
      }
    }

    return segments;
  }, [categories, hoveredCategoryKey, noneCategoryPosition, noneLabel, showNone]);

  return (
    <div
      data-testid="sparkline-container"
      className={clsx("atlas-flex", "atlas-flex-col", "atlas-space-y-2")}
    >
      <div
        data-testid="sparkline-chart"
        className="atlas-relative atlas-w-full"
        style={{ height: BAR_HEIGHT }}
      >
        <svg
          width="100%"
          xmlns="http://www.w3.org/2000/svg"
          className="atlas-absolute atlas-top-0 atlas-left-0 atlas-w-full atlas-h-full atlas-overflow-visible"
        >
          <mask id={roundedBarMaskId}>
            <rect x={0} y={0} width="100%" height="100%" fill="black" />
            <rect rx={BAR_RADIUS} x={0} y={0} width="100%" height="100%" fill="white" />
          </mask>

          <g>
            {segments
              .filter(hasPercentage)
              .map(({ x, width, color, name, percentage, isHovered, categoryKey }) => (
                <rect
                  data-testid="sparkline-section"
                  key={categoryKey}
                  x={x + "%"}
                  y={0}
                  width={width + "%"}
                  height={BAR_HEIGHT}
                  fill={color}
                  mask={roundedBarMask}
                  className={clsx(
                    "atlas-ease-in-out atlas-duration-200 hover:atlas--translate-y-1",
                    {
                      "atlas--translate-y-1": isHovered,
                    }
                  )}
                >
                  <title>{formatTooltip(percentage, name, decimalPlaces)}</title>
                </rect>
              ))}
            {segments
              .filter(hasPercentage)
              .slice(1)
              .map(({ x, categoryKey }) => (
                <line
                  data-testid="sparkline-divider"
                  key={categoryKey + "-stroke"}
                  x1={x + "%"}
                  x2={x + "%"}
                  y1={-6}
                  y2={BAR_HEIGHT}
                  stroke="white"
                  strokeWidth={2}
                />
              ))}
          </g>
        </svg>
      </div>
      {showLegend && (
        <div
          data-testid="sparkline-legend"
          className="atlas-flex atlas-flex-row atlas-gap-x-4 atlas-gap-y-1 atlas-flex-wrap"
        >
          {segments.map(({ color, name, categoryKey, percentage }) => (
            <div
              key={categoryKey}
              data-testid="sparkline-legend-element"
              className="atlas-text-xs atlas-space-x-1 atlas-flex atlas-flex-row atlas-items-center atlas-cursor-default"
              onMouseEnter={() => setLocalHovered(categoryKey)}
              onMouseLeave={() => setLocalHovered(undefined)}
              title={formatTooltip(percentage, name, decimalPlaces)}
            >
              <div
                className="atlas-rounded-full atlas-h-3 atlas-w-3"
                data-testid="sparkline-legend-element-badge"
                style={{ backgroundColor: color }}
              />
              <span>{name}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

function formatTooltip(percentage: number, name: string, decimalPlaces: number) {
  const rounded = +percentage.toFixed(decimalPlaces);
  let toDisplay = rounded.toString();

  if (percentage === 0) toDisplay = "0";
  if (rounded === 0) toDisplay = `<0.${padStart("1", decimalPlaces, "0")}`;

  return `${name} - ${toDisplay}%`;
}

function hasPercentage({ percentage }: SparklineCategory) {
  return percentage > 0;
}
