import { useMemo } from "react";
import { SelectionGeometry } from "src/js/stores/map/store";
import { useDesignationWithLargestOverlapByGeometry } from "react-migration/domains/constraints/designation/hooks/useDesignationWithLargestOverlapByGeometry";
import { numberAttributeAccessor } from "react-migration/domains/constraints/designation/util/numberAttributeAccessor";
import {
  TRAVEL_TIMES_CATEGORY_ID,
  TRAVEL_TILE_MEDIAN_SCORE_ATTRIBUTE_KEY,
  TRAVEL_TIME_TABLE,
  PUBLIC_TRANSPORT_ACCESSIBILITY_RATING_ATTRIBUTE_KEY,
} from "./constants";

export function useLocalServicesDataByGeometry(geometry?: SelectionGeometry | null) {
  const { designation, ...rest } = useDesignationWithLargestOverlapByGeometry({
    geometry: geometry || null,
    includeArea: true,
    rootCategories: [TRAVEL_TIMES_CATEGORY_ID],
    attributeKeys: [
      // TODO - MAKE SURE REFERENCE DOESNT CHANGE
      TRAVEL_TILE_MEDIAN_SCORE_ATTRIBUTE_KEY,
      PUBLIC_TRANSPORT_ACCESSIBILITY_RATING_ATTRIBUTE_KEY,
      ...TRAVEL_TIME_TABLE.flatMap(([, keys]) => keys),
    ],
  });

  const [tableData, travelTimesScoreMedian, publicTransportRating] = useMemo(() => {
    if (!designation) return [null, null, null];

    const tableData = TRAVEL_TIME_TABLE.map(([label, attributeKeys]) => {
      const timesMinutes = attributeKeys.map((key) => numberAttributeAccessor(designation, key));

      return {
        label,
        timesMinutes,
      };
    });

    return [
      tableData,
      numberAttributeAccessor(designation, TRAVEL_TILE_MEDIAN_SCORE_ATTRIBUTE_KEY),
      numberAttributeAccessor(designation, PUBLIC_TRANSPORT_ACCESSIBILITY_RATING_ATTRIBUTE_KEY),
    ];
  }, [designation]);

  return { tableData, travelTimesScoreMedian, publicTransportRating, ...rest };
}
