import { useMemo } from "react";
import buffer from "@turf/buffer";
import { getGeom } from "@turf/turf";
import { point } from "@turf/helpers";
import {
  EpcEnergyRating,
  PropertyInformation,
  usePropertiesByGeometry,
  usePropertiesByTitleNumber,
} from "./useProperties";
import { SelectionFeature, SelectionType } from "src/js/stores/map/store";
import { TextWithVariants } from "react-migration/components/TextWithVariants";
import { getLengthVariants } from "react-migration/lib/util/getAreaAndPerimeter";
import { Tag, TagColor, TagProps } from "react-migration/components/Tag";
import { isDefined, isNotEmpty } from "react-migration/lib/util/isDefined";
import { Nullable } from "src/js/types/Nullable";
import { groupBy, sortBy } from "lodash";
import { LayerTypeSelectionSidebarProps } from "../../../types";
import { useSelectionSidebarLoading } from "../../../Bundle/useSelectionSidebarLoadingState";
import { UnknownValue } from "../components/UnknownValue";
import { POINT_SELECTION_BUFFER_RADIUS_METERS } from "../constants";
import { CollapsibleConsiderationsCard } from "../../../Bundle/Workbench/AssessmentTab/CollapsibleConsiderationsCard";
import { deckGlColourToRgba } from "react-migration/domains/map/util/deckGlColourToRgba";
import { Colour } from "react-migration/domains/constraints/designation/style/colours";
import { CountPill } from "react-migration/components/CountPill";
import { ExpandibleList } from "react-migration/components/ExpandibleList/ExpandibleList";
import { useSnapshot } from "valtio";
import { userStore } from "src/js/stores/user/store";
import { isPointSelectionType } from "../utils";
import { logEventV2 } from "react-migration/lib/util/logEventV2";
import { DataPanel } from "react-migration/components/DataPanel";
import { useKeyIndicatorTags } from "react-migration/lib/map/useKeyIndicatorTags";
import { Alert } from "react-migration/components/Alert";
import { useBetaFeatureFlag } from "react-migration/lib/user/useFeatureFlag";
import Feature from "src/js/stores/user/Feature";

function bufferPointSelection(selection: SelectionFeature): Nullable<GeoJSON.Geometry> {
  if (!selection.feature?.geometry) return null;

  return getGeom(
    buffer(selection.feature.geometry, POINT_SELECTION_BUFFER_RADIUS_METERS, { units: "meters" })
  );
}

export function usePropertiesBySelection(selection: SelectionFeature) {
  const intersectionGeometry = isPointSelectionType(selection)
    ? bufferPointSelection(selection)
    : selection.feature?.geometry;

  const { properties: propertiesByGeometry, loading: propertiesByGeometryLoading } =
    usePropertiesByGeometry(intersectionGeometry || null);

  // If this brings back additional properties/different data, when we save the site,
  // will this not produce different results, giving us changing values upon site save?
  const { properties: propertiesByTitle, loading: propertiesByTitleLoading } =
    usePropertiesByTitleNumber(
      selection.type === SelectionType.OWNERSHIP_TITLE ? selection.id : undefined
    );

  const uniqueProperties = useMemo(() => {
    const combinedPropertyResults = [propertiesByGeometry, propertiesByTitle]
      .filter(isDefined)
      .flat();

    return Object.values(
      Object.fromEntries(
        combinedPropertyResults.map((property) => [property.address.uprn, property])
      )
    );
  }, [propertiesByGeometry, propertiesByTitle]);

  return {
    properties: uniqueProperties,
    loading: propertiesByGeometryLoading || propertiesByTitleLoading,
  } as const;
}

export function UKPropertyInformationSelectionSidebar({
  selection,
  cardExpanded,
  setDetailSelection,
  onLoadingChange,
  layer,
}: Readonly<LayerTypeSelectionSidebarProps>) {
  const hasInsightsSummaryBeta = useBetaFeatureFlag(Feature.insightsSummaryBeta);
  const { properties, loading } = usePropertiesBySelection(selection);
  useSelectionSidebarLoading(onLoadingChange, loading);

  if (
    loading ||
    !properties?.length ||
    (hasInsightsSummaryBeta && selection.type !== SelectionType.POINT)
  ) {
    return null;
  }

  return (
    <CollapsibleConsiderationsCard
      heading={
        isPointSelectionType(selection) ? (
          <UKPropertyInformationSelectionSidebarHeader count={properties.length} />
        ) : (
          layer.title
        )
      }
      name={isPointSelectionType(selection) ? "Nearby Properties" : layer.title}
      count={properties.length}
      defaultExpanded={cardExpanded}
    >
      <div className="atlas-bg-white atlas-m-2 atlas-mt-1 ">
        <UKPropertyInformationSelectionContent
          properties={properties}
          setDetailSelection={setDetailSelection}
        />
      </div>
    </CollapsibleConsiderationsCard>
  );
}

export function UKPropertyInformationSelectionContent({
  properties,
  setDetailSelection,
}: Pick<LayerTypeSelectionSidebarProps, "setDetailSelection"> & {
  properties: PropertyInformation[];
}) {
  useKeyIndicatorTags(() => {
    return [{ tag: properties?.length, color: TagColor.DEFAULT }];
  }, [properties]);

  if (!properties.length) {
    return <Alert.Info>No properties found for your selection</Alert.Info>;
  }

  return (
    <div className="atlas-flex atlas-flex-col atlas-gap-3">
      <BuildingHeightSummary properties={properties} />
      <div className="md:atlas-grid md:atlas-grid-cols-2">
        <UniqueEpcs properties={properties} />
        <UniqueUseClasses properties={properties} />
      </div>
      <PropertiesList properties={properties} setDetailSelection={setDetailSelection} />
    </div>
  );
}

interface UKPropertyInformationSelectionSidebarHeaderProps {
  count?: number;
}

function UKPropertyInformationSelectionSidebarHeader({
  count,
}: UKPropertyInformationSelectionSidebarHeaderProps) {
  return (
    <h1 className="atlas-flex atlas-gap-x-1 atlas-items-center atlas-text-sm atlas-font-semibold atlas-text-text-base-primary">
      Nearby Properties
      <span className="atlas-font-normal">
        {`(within `}
        <IntersectionRadiusIcon />
        {`${POINT_SELECTION_BUFFER_RADIUS_METERS}m)`}
      </span>
      <CountPill count={count} />
    </h1>
  );
}

const IntersectionRadiusIcon = () => (
  <div
    className="atlas-inline-block atlas-w-[15px] atlas-h-[15px] atlas-rounded-full atlas-border-2 atlas-align-text-top atlas-mr-[2px]"
    style={{
      borderColor: deckGlColourToRgba(Colour.BLUE_60),
      background: deckGlColourToRgba(Colour.BLUE_20),
    }}
  />
);

interface TallestBuildingProps {
  properties: PropertyInformation[];
}

export function BuildingHeightSummary({ properties }: Readonly<TallestBuildingProps>) {
  const { user } = useSnapshot(userStore);

  const tallestBuildingVariants = useMemo(() => {
    const [tallestBuilding] = properties
      .filter((property) => !!property.building_height?.rel_h_max)
      .map((property) => property.building_height.rel_h_max)
      .sort((a, b) => b - a);

    if (!tallestBuilding) return null;

    return getLengthVariants(tallestBuilding, user.settings.unit);
  }, [properties, user.settings.unit]);

  const averageBuildingVariants = useMemo(() => {
    const relativeHeights = properties
      .filter((property) => !!property.building_height?.rel_h_max)
      .map((record) => record.building_height.rel_h_max);

    const total = relativeHeights.reduce((totalHeight, height) => {
      return totalHeight + height;
    }, 0);

    const average = total / relativeHeights.length;

    if (!average) {
      return null;
    }

    return getLengthVariants(average, user.settings.unit);
  }, [properties, user.settings.unit]);

  return (
    <DataPanel.Grid columns={2}>
      <DataPanel.Cell title="Tallest building height">
        {tallestBuildingVariants ? (
          <TextWithVariants variants={tallestBuildingVariants} />
        ) : (
          <UnknownValue />
        )}
      </DataPanel.Cell>
      <DataPanel.Cell title="Average building height">
        {averageBuildingVariants ? (
          <TextWithVariants variants={averageBuildingVariants} />
        ) : (
          <UnknownValue />
        )}
      </DataPanel.Cell>
    </DataPanel.Grid>
  );
}

interface UniqueUseClassesProps {
  properties: PropertyInformation[];
}

export function UniqueUseClasses({ properties }: Readonly<UniqueUseClassesProps>) {
  const group = groupBy(
    properties.map((p) => p.address),
    "use_class_sep_2020"
  );

  const uniqueUseClasses = useMemo(
    () => [
      ...new Set(
        properties
          .map((property) => property.address.use_class_sep_2020)
          .filter(isNotEmpty)
          .sort()
      ),
    ],
    [properties]
  );

  return (
    <div className="atlas-flex atlas-flex-col atlas-gap-1">
      <h2 className="atlas-text-neutral-500 atlas-text-xs atlas-flex atlas-items-center atlas-gap-1">
        Use Classes
      </h2>
      <div className="atlas-flex atlas-flex-wrap atlas-gap-1">
        {uniqueUseClasses.map((useClass) => (
          <Tag
            key={useClass}
            tag={useClass}
            color={TagColor.DEFAULT}
            title={group[useClass][0].use_class_description_2020}
            size="md"
          />
        ))}
      </div>
    </div>
  );
}

interface UniqueEpcProps {
  properties: PropertyInformation[];
}

export function UniqueEpcs({ properties }: Readonly<UniqueEpcProps>) {
  const uniqueEpcRatings = useMemo(
    () => [
      ...new Set(
        properties
          .map((property) => property.epc?.current_energy_rating)
          .filter(isNotEmpty)
          .sort()
      ),
    ],
    [properties]
  );

  return (
    <div className="atlas-flex atlas-flex-col atlas-gap-1">
      <h2 className="atlas-text-neutral-500 atlas-text-xs atlas-flex atlas-items-center atlas-gap-1">
        EPC ratings (current)
      </h2>
      <div className="atlas-flex atlas-flex-wrap atlas-gap-1">
        {uniqueEpcRatings.length ? (
          uniqueEpcRatings.map((uniqueEpcRating) => (
            <EpcTag key={uniqueEpcRating} rating={uniqueEpcRating} size="md" />
          ))
        ) : (
          <span className="atlas-text-xs atlas-text-neutral-400">Unavailable</span>
        )}
      </div>
    </div>
  );
}

interface PropertiesListProps {
  properties: PropertyInformation[];
  setDetailSelection: (s: SelectionFeature) => void;
}

const EpcColorMap: Record<EpcEnergyRating, TagColor> = {
  A: TagColor.GREEN,
  B: TagColor.GREEN,
  C: TagColor.GREEN,
  D: TagColor.ORANGE,
  E: TagColor.ORANGE,
  F: TagColor.RED,
  G: TagColor.RED,
};
export function EpcTag({
  efficiency,
  rating,
  ...rest
}: { rating: EpcEnergyRating; efficiency?: number } & Omit<TagProps, "tag">) {
  const tag = efficiency ? `${rating} | ${efficiency}` : rating;
  return <Tag color={EpcColorMap[rating]} {...rest} tag={tag} />;
}

function PropertiesList({ properties, setDetailSelection }: Readonly<PropertiesListProps>) {
  const sortedProperties = useMemo(
    () => sortBy(properties, ["address.full_address"]),
    [properties]
  );

  return (
    <ExpandibleList limit={3} showCount itemsName="properties">
      {sortedProperties.map((property) => (
        <div
          key={property.address.uprn}
          className="atlas-relative atlas-flex atlas-items-center atlas-gap-1 atlas-p-2 hover:atlas-bg-neutral-100 atlas-cursor-pointer"
        >
          <div className="atlas-text-xs atlas-flex-1 atlas-truncate atlas-font-medium">
            {property.address.full_address}
          </div>
          {property.epc && (
            <div className="atlas-flex">
              <div className="atlas-flex-shrink-0">
                <EpcTag
                  efficiency={property.epc.current_energy_efficiency}
                  rating={property.epc.current_energy_rating}
                />
              </div>

              {property.epc.potential_energy_rating && (
                <>
                  <i className="icon icon-lt-arrow-right-line" />

                  <div className="atlas-flex-shrink-0">
                    <EpcTag
                      efficiency={property.epc.potential_energy_efficiency}
                      rating={property.epc.potential_energy_rating}
                    />
                  </div>
                </>
              )}
            </div>
          )}
          {property.address.use_class_sep_2020 && (
            <div className="atlas-flex-shrink-0">
              <Tag tag={property.address.use_class_sep_2020} color={TagColor.DEFAULT} />
            </div>
          )}
          <button
            className="atlas-absolute atlas-inset-0"
            title={property.address.full_address}
            onClick={() => {
              setDetailSelection({
                type: SelectionType.UK_PROPERTY,
                feature: point(property.address.location.coordinates),
                id: property.address.uprn,
              });
              logEventV2({
                name: "Workbench card click",
                properties: {
                  cardName: "Property Information",
                  action: "goTo",
                  clickDetail: "Property",
                },
              });
            }}
          />
        </div>
      ))}
    </ExpandibleList>
  );
}
