import * as RadixTabs from "@radix-ui/react-tabs";
import { ComponentProps, ElementType, useEffect, useRef, useState } from "react";
import clsx from "classnames";
import type { TabsTriggerProps } from "@radix-ui/react-tabs";

export type TabsRootType = ComponentProps<ElementType> & {
  defaultValue?: string;
  onValueChange: (value: string) => void;
  value: string;
};

export const VisualTab = ({ children, subtitle, isActive }: TabsTriggerType) => {
  return (
    <>
      {children}

      {subtitle && <span className="atlas-text-3xs atlas-text-blueGrey-500">{subtitle}</span>}

      <div className="atlas-absolute atlas-top-0 atlas-right-0 atlas-bottom-0 atlas-left-0 atlas-border-b atlas-border-neutral-300" />
      <div
        className={clsx(
          "atlas-absolute atlas-right-2 atlas-bottom-0 atlas-left-2 atlas-h-1 atlas-rounded-t atlas-bg-platformBlue-700",
          {
            "atlas-invisible": !isActive,
          }
        )}
      />
    </>
  );
};

export const Tabs = ({
  children,
  defaultValue,
  value,
  onValueChange,
  className,
  ...rest
}: TabsRootType) => {
  return (
    <RadixTabs.Root
      className={clsx(
        "atlas-flex atlas-flex-col atlas-group atlas-bg-background-surface",
        className
      )}
      defaultValue={defaultValue}
      onValueChange={onValueChange}
      value={value}
      {...rest}
    >
      {children}
    </RadixTabs.Root>
  );
};

export type TabsListType = RadixTabs.TabsListProps;
export const TabsList = ({ children, className, ...rest }: TabsListType) => {
  return (
    <RadixTabs.List
      className={clsx(
        "atlas-shrink-0 atlas-flex atlas-border-b atlas-border-neutral-300",
        className
      )}
      {...rest}
    >
      {children}
    </RadixTabs.List>
  );
};

export type TabsTriggerType = ComponentProps<ElementType> & TabsTriggerProps;

export const TabsTrigger = ({
  children,
  value,
  className,
  disabled,
  onClick,
  ...rest
}: TabsTriggerType) => {
  const [triggerRef, isActive] = useRadixTriggerState();
  const onTabClick = (event: React.MouseEventHandler<HTMLButtonElement>) => {
    onClick(value, event);
  };

  return (
    <RadixTabs.Trigger
      ref={triggerRef}
      className={clsx(
        "atlas-basis-1/2 atlas-h-[var(--navbar-height)] atlas-relative atlas-flex atlas-flex-col atlas-items-start atlas-justify-end atlas-px-5 atlas-pb-3 atlas-gap-y-2",
        "atlas-leading-none atlas-select-none hover:atlas-bg-neutral-200 atlas-cursor-pointer",
        className
      )}
      value={value}
      disabled={disabled}
      onClick={onTabClick}
      {...rest}
    >
      <VisualTab {...rest} isActive={isActive} disabled={disabled}>
        {children}
      </VisualTab>
    </RadixTabs.Trigger>
  );
};

export type TabsContentType = ComponentProps<ElementType> & { value: string };
export const TabsContent = ({ children, value, className, ...rest }: TabsContentType) => {
  return (
    <RadixTabs.Content
      className={clsx(
        "data-[state=inactive]:atlas-hidden data-[state=active]:atlas-flex atlas-flex-col",
        className
      )}
      value={value}
      {...rest}
    >
      {children}
    </RadixTabs.Content>
  );
};

Tabs.List = TabsList;
Tabs.Trigger = TabsTrigger;
Tabs.Content = TabsContent;

// This seems to be the only means of accessing the current state of the radix
// tab without managing it a higher level For reference:
// https://github.com/radix-ui/primitives/blob/main/packages/react/tabs/src/Tabs.tsx
function useRadixTriggerState() {
  const triggerRef = useRef<HTMLButtonElement | undefined>(null);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (!triggerRef.current) return;

    setIsActive(getRadixTriggerState(triggerRef.current));

    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === "attributes" && mutation.attributeName === `data-state`) {
          setIsActive(getRadixTriggerState(triggerRef.current));
        }
      }
    });

    observer.observe(triggerRef.current, { attributes: true });

    return () => observer.disconnect();
  }, [triggerRef]);

  return [triggerRef, isActive] as const;
}

function getRadixTriggerState(element?: HTMLButtonElement | null) {
  return element?.dataset["state"] === "active";
}
