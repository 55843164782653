import { useCallback, useEffect, useState } from "react";

export function useVariants(variants?: string[], isLarge = false) {
  const [activeVariantIndex, setActiveVariantIndex] = useState(0);

  useEffect(() => {
    setActiveVariantIndex(isLarge ? 1 : 0);
  }, [variants, isLarge]);

  const incrementIndex = useCallback(() => {
    if (!variants) return;

    const newIndex = variants.length === activeVariantIndex + 1 ? 0 : activeVariantIndex + 1;
    setActiveVariantIndex(newIndex);
  }, [activeVariantIndex]);

  return [variants?.[activeVariantIndex], incrementIndex] as const;
}
