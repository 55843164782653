import { InsightSummaryLearnMoreTooltip } from "react-migration/components/InsightSummaryLearnMoreTooltip";

export const ConnectivityScoreLearnMore = () => (
  <InsightSummaryLearnMoreTooltip triggerText="How is this calculated?">
    <p className="atlas-my-2">
      The <span className="atlas-font-bold">Connectivity Score</span> rates a site and the
      surrounding neighbourhood accessibility on a scale of 1 to 10, with 10 being the most
      well-connected.
    </p>
    <ul className="atlas-list-disc atlas-pl-9 atlas-my-2">
      <li>
        <span className="atlas-font-bold">Proximity to key amenities:</span> We assess travel times
        to essential locations like shops, schools, and town centres, prioritising shorter, more
        convenient journeys.
      </li>
      <li>
        <span className="atlas-font-bold">Public transport access:</span> We factor in the distance
        and type of transport stops, with higher ratings for locations near frequent services like
        tube and train stations.
      </li>
      <li>
        <span className="atlas-font-bold">Balanced rating:</span> The score combines both amenity
        access and public transport quality, ensuring a fair comparison across different sites.
      </li>
      <li>
        <span className="atlas-font-bold">Sustainability focus:</span> Walking, cycling, and public
        transport are prioritised - car travel does not significantly impact the score.
      </li>
    </ul>
    <p className="atlas-my-2">
      This helps you quickly assess how well-connected a site is, making it easier to compare
      different sites at a glance.
    </p>
  </InsightSummaryLearnMoreTooltip>
);
