import { PropsWithChildren, useCallback, useMemo } from "react";
import { SelectionFeature, SelectionType } from "src/js/stores/map/store";
import { Checkbox } from "react-migration/components/Checkbox";
import { useAssessmentTabs } from "./useAssessmentTabs";
import { SiteReportContent, SiteReportLoadingState } from "./SiteReportContent";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { Button } from "react-migration/components/Button";
import { useReportLoadingState } from "./useReportLoadingState";
import { useSiteReportLogo } from "./useSiteReportLogo";
import { FileInput } from "react-migration/components/FileInput";
import { Label } from "react-migration/components/Label";
import { siteOptionsSchema } from "./siteOptions";
import { Alert } from "react-migration/components/Alert";
import { AssessmentTab } from "./AssessmentTab";
import { TabsState, useToggleList, useToggleTabs } from "./hooks";
import { useSiteById } from "react-migration/domains/sites/hooks/useSiteById";
import { Nullable } from "src/js/types/Nullable";
import { feature } from "@turf/helpers";
import clsx from "clsx";

interface SiteReportRouteContainerProps {
  siteId: string;
}

/**
 * Component to allow user to configure site report & show preview (akin to Chrome Print Preview).
 *
 * @param {string} props.siteId - The selected feature for which the site report is generated.
 * @example
 * <SiteReportRouteContainer siteId={string} />
 */
export function SiteReportRouteContainer({ siteId }: SiteReportRouteContainerProps) {
  const { t } = useTranslation();

  const { site } = useSiteById(siteId);

  const siteAsSelectionFeature = useMemo<Nullable<SelectionFeature>>(() => {
    if (!site?.geometry || !site?._id) return null;

    return { type: SelectionType.SITE, id: site._id, feature: feature(site?.geometry) };
  }, [site]);

  const assessmentTabs = useAssessmentTabs();
  const [assessmentState, toggleAssessmentState] = useToggleTabs(
    assessmentTabs.reduce((tabsState, tab) => {
      tabsState[tab.key] = {};
      tab.layers.forEach((layer) => {
        tabsState[tab.key][layer.id] = true;
      });
      return tabsState;
    }, {} as TabsState)
  );

  const { isReadyToDownload, setReportLoadingState, getTabLoadingState, getLayerLoadingState } =
    useReportLoadingState();

  const [siteOptions, toggleSiteOptions] = useToggleList(Object.keys(siteOptionsSchema));
  const { logoFile, handleLogoUpload, error } = useSiteReportLogo(siteId);

  const activeAssessmentLayers = Object.values(assessmentState).reduce<string[]>(
    (layersList, tabLayers) => {
      Object.entries(tabLayers).forEach(([layerKey, isSelected]) => {
        if (isSelected) {
          layersList.push(layerKey);
        }
      });
      return layersList;
    },
    []
  );

  const reportLoadedHandle = useCallback(
    (hasLoaded: boolean, loadingState: SiteReportLoadingState) => {
      setReportLoadingState(loadingState);
      Object.entries(assessmentState).forEach(([tabKey, tabState]) => {
        const tabData = assessmentTabs.find((tab) => tab.key === tabKey);
        if (!tabData) {
          return;
        }
        if (tabData.expandable) {
          Object.entries(tabState).forEach(([layerId, isLayerSelected]) => {
            const layerLoadingState = loadingState[layerId];
            if (
              isLayerSelected &&
              layerLoadingState?.mounted &&
              !layerLoadingState.loading &&
              layerLoadingState.error
            ) {
              toggleAssessmentState(tabKey, layerId);
            }
          });
        } else {
          if (
            Object.entries(tabState).some(([layerId, isLayerSelected]) => {
              const layerLoadingState = loadingState[layerId];
              return (
                isLayerSelected &&
                layerLoadingState?.mounted &&
                !layerLoadingState.loading &&
                layerLoadingState.error
              );
            })
          ) {
            toggleAssessmentState(tabKey);
          }
        }
      });
    },
    [assessmentState, assessmentTabs, setReportLoadingState, toggleAssessmentState]
  );

  const assessmentTabKeys = assessmentTabs.map((tab) => tab.key);

  if (!siteAsSelectionFeature) return null;

  return (
    <div
      className={clsx(
        "atlas-h-full",
        "atlas-w-full",
        "atlas-flex",
        "atlas-gap-4",
        "atlas-p-4",
        "print:atlas-p-0"
      )}
      data-testid="site-report-container"
    >
      <div className="print:atlas-hidden atlas-w-full md:atlas-w-1/3">
        <div className="atlas-bg-blueGrey-100 atlas-rounded-lg atlas-p-2">
          <div className="atlas-flex atlas-flex-col atlas-gap-8">
            <div className="atlas-flex atlas-flex-col atlas-gap-4">
              <div className="atlas-w-full atlas-flex atlas-flex-col atlas-gap-2">
                <Label showOptionalText>{t("sites.card.site_report.upload_logo")}</Label>
                <FileInput onSelectFile={handleLogoUpload} value={logoFile ?? undefined} />
                {error && <Alert.Error>{error}</Alert.Error>}
              </div>
              {!!assessmentTabKeys.length && (
                <div className="atlas-flex atlas-flex-col atlas-gap-2">
                  <FormHeading>Assessment</FormHeading>
                  <div className="atlas-flex atlas-flex-col atlas-gap-4">
                    {assessmentTabs.map((tab) => (
                      <AssessmentTab
                        key={tab.key}
                        tab={tab}
                        loadingState={getTabLoadingState(tab)}
                        checkLayerLoadingState={getLayerLoadingState}
                        checkedState={assessmentState[tab.key]}
                        handleCheckedChange={(layerKey) => toggleAssessmentState(tab.key, layerKey)}
                      />
                    ))}
                  </div>
                </div>
              )}

              <div className="atlas-flex atlas-flex-col atlas-gap-2">
                <FormHeading>Management</FormHeading>
                <div className="atlas-flex atlas-flex-col atlas-gap-4">
                  {Object.entries(siteOptionsSchema).map(([key, { displayName }]) => (
                    <div className="atlas-flex atlas-items-center atlas-gap-2" key={key}>
                      <Checkbox
                        id={key}
                        checked={siteOptions.includes(key)}
                        onCheckedChange={() => toggleSiteOptions(key)}
                      />
                      <label
                        htmlFor={key}
                        className="atlas-text-sm atlas-text-blueGrey-950 atlas-select-none"
                      >
                        {displayName}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <Button
              disabled={!isReadyToDownload}
              leadIcon={isReadyToDownload ? "lt-download-line" : "lt-loader-4-line"}
              onClick={() => window.print()}
              leadIconAnimation={isReadyToDownload ? undefined : "spin"}
            >
              {!isReadyToDownload ? t("site_report.loading") : t("site_report.save_as_pdf")}
            </Button>
          </div>
        </div>
      </div>
      <div className={clsx("atlas-flex", "atlas-w-2/3", "atlas-h-full", "print:atlas-w-full")}>
        <div
          className={clsx(
            "atlas-w-full",
            "atlas-overflow-y-scroll",
            "atlas-bg-white",
            "atlas-p-2",
            "atlas-border-8",
            "atlas-rounded-lg",
            "atlas-border-blueGrey-400",
            "print:atlas-rounded-none",
            "print:atlas-border-0",
            "print:atlas-overflow-visible",
            "print:atlas-p-1"
          )}
        >
          <SiteReportContent
            activeAssessmentLayers={activeAssessmentLayers}
            siteOptions={siteOptions}
            selection={siteAsSelectionFeature}
            onReportLoaded={reportLoadedHandle}
          />
        </div>
      </div>
    </div>
  );
}

export function FormHeading({ children }: PropsWithChildren) {
  return (
    <h2 className="atlas-text-base atlas-text-blueGrey-950 atlas-font-semibold">{children}</h2>
  );
}
