import { HTMLProps, ReactNode } from "react";
import { DataPanel } from "react-migration/components/DataPanel";

type WrapperType = Omit<HTMLProps<HTMLDivElement>, "title"> & {
  title: ReactNode;
  tooltip?: string | ReactNode;
  "data-testid"?: string;
};

export const Wrapper = ({ title, tooltip, children, "data-testid": dataTestId }: WrapperType) => {
  return (
    <DataPanel.Cell
      title={title}
      tooltip={tooltip}
      variant="light"
      className="atlas-px-4 atlas-py-4 atlas-rounded-xl"
      data-testid={dataTestId}
    >
      {children}
    </DataPanel.Cell>
  );
};
