import { useEffect, useMemo } from "react";
import { InsightSelectedAreaWithTooltip } from "react-migration/components/InsightSelectedAreaWithTooltip";
import { useDesignationsByGeometry } from "react-migration/domains/constraints/designation/hooks/useDesignationsByGeometry";
import { useDesignationById } from "react-migration/domains/constraints/designation/hooks/useDesignationById";
import { SingleDesignation } from "react-migration/domains/constraints/typings/applicationTypes/SingleDesignation";
import { SkeletonLoading } from "react-migration/components/SkeletonLoading";
import {
  KeyIndicatorColor,
  useKeyIndicatorTags,
} from "react-migration/lib/map/useKeyIndicatorTags";
import { LayerTypeLayerSummaryProps, SupportedRegions } from "../../types";
import { useDesignationIntersections } from "./OutputAreaDetailView/useDesignationIntersectionsQuery";
import { DesignationsComparisonChart } from "./OutputAreaDetailView/AttributeComparisonSummarySection";
import { OutputAreaAttribute } from "./OutputAreaDetailView/types";
import * as UK_ATTRS from "./OutputAreaDetailView/OutputAreaAttributesUK";
import * as US_ATTRS from "./OutputAreaDetailView/OutputAreaAttributesUS";
import { useDemographicSubCategory } from "./useDemographicSubCategory";
import { getAttributeNumericValue } from "./utils";
import { DemographicsLayerConfig } from "./DemographicsLayerType";
import { useLargestDesignationByGeometry } from "react-migration/domains/constraints/designation/hooks/useLargestDesignationByGeometry";

type DemographicsLayerSummaryItemProps = {
  label: string;
  attribute: OutputAreaAttribute;
  designation: SingleDesignation;
  comparableDesignation?: SingleDesignation;
};

const DemographicsLayerSummaryItem = ({
  label,
  attribute,
  designation,
  comparableDesignation,
}: DemographicsLayerSummaryItemProps) => {
  const summaryNumericValue = getAttributeNumericValue(
    designation?.designation_attributes,
    attribute.attributeKey
  );

  useKeyIndicatorTags(
    (tags) => {
      return [
        ...tags,
        {
          tag:
            attribute.format && summaryNumericValue != null
              ? attribute.format(summaryNumericValue)
              : summaryNumericValue,
          id: attribute.attributeKey,
          color: KeyIndicatorColor.DEFAULT,
        },
      ];
    },
    [attribute, summaryNumericValue]
  );
  return (
    <div>
      <div className="atlas-flex atlas-flex-row atlas-justify-between atlas-items-center atlas-gap-x-2 atlas-w-full atlas-my-2">
        <h1 className="atlas-shrink-0 atlas-text-sm atlas-text-neutral-800 atlas-font-semibold">
          {label}
        </h1>
      </div>
      <DesignationsComparisonChart
        attribute={attribute}
        designation={designation}
        comparableDesignation={comparableDesignation}
      />
    </div>
  );
};

export const DemographicsLayerSummary = ({
  layer,
  selection,
  onLoadingChange,
}: LayerTypeLayerSummaryProps) => {
  const { categoryId } = useDemographicSubCategory(layer);

  const config = layer.layerConfig as DemographicsLayerConfig;
  const region = config.region;

  // The only time we see null is when the user is zoomed out too far
  // This will be the case for the first subcategory condition,
  // so we can use the second one as a fallback
  const comparableCategory =
    config.subCategoryConditions[0].categoryId ?? config.subCategoryConditions[1].categoryId!;

  const { designation: selectedDesignation, loading: designationsLoading } =
    useLargestDesignationByGeometry({
      geometry: selection.feature?.geometry || null,
      // TODO: Dumb to default here?
      rootCategories: [categoryId ?? comparableCategory],
      includeArea: true,
    });

  const { designation, loading: designationLoading } = useDesignationById(selectedDesignation?.id);

  const { designations: intersectingDesignations, loading: intersectionsLoading } =
    useDesignationIntersections({
      id: selectedDesignation?.id,
      bufferMeters: -5,
      rootCategories: [comparableCategory],
      skip: !selectedDesignation?.id || designationsLoading,
    });

  // TODO: Are we always comparing to the national level?
  const comparableDesignation = intersectingDesignations[0];

  const loading = designationsLoading || designationLoading || intersectionsLoading;

  useEffect(() => {
    onLoadingChange(loading);
  }, [onLoadingChange, loading]);

  if (loading) {
    return (
      <div className="atlas-relative atlas-p-2 atlas-h-[230px]">
        <SkeletonLoading rows={2} showPadding={false} bgClassName="atlas-bg-transparent" />
      </div>
    );
  }

  if (!designation) {
    throw new Error("Could not retrieve designations");
  }

  return (
    <div className="atlas-flex atlas-flex-col atlas-divide-y atlas-divide-solid atlas-divide-neutral-200">
      <DemographicsLayerSummaryItem
        label="Age"
        attribute={region === "US" ? US_ATTRS.AGE_ATTRS[0] : UK_ATTRS.AGE_ATTRS[0]}
        designation={designation}
        comparableDesignation={comparableDesignation}
      />

      <DemographicsLayerSummaryItem
        label="Income"
        attribute={region === "US" ? US_ATTRS.INCOME_ATTRS[0] : UK_ATTRS.INCOME_ATTRS[0]}
        designation={designation}
        comparableDesignation={comparableDesignation}
      />
      <InsightSelectedAreaWithTooltip>{designation.display_name}</InsightSelectedAreaWithTooltip>
    </div>
  );
};

DemographicsLayerSummary.icon = "icon-lt-line-chart-line";
DemographicsLayerSummary.supportedRegions = [SupportedRegions.england, SupportedRegions.wales];
