import { useCallback } from "react";
import { logEvent } from "react-migration/lib/util/logEvent";
import { Nullable } from "src/js/types/Nullable";
import clsx from "clsx";

type FileInputProps = {
  onSelectFile: (file?: File) => void;
  value?: Nullable<File>;
};

export const FileInput = ({ onSelectFile, value }: FileInputProps) => {
  const onFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.currentTarget.files?.[0];
      logEvent("Site Report - Upload Logo - clicked");

      onSelectFile(file);
    },
    [onSelectFile]
  );

  const onDeleteClick = useCallback(() => onSelectFile(undefined), [onSelectFile]);

  return (
    <div className="atlas-flex atlas-w-full atlas-rounded atlas-bg-white atlas-border atlas-border-blueGrey-200 hover:atlas-border-blueGrey-300 atlas-relative">
      <div className="atlas-flex-grow">
        <div className={clsx("atlas-px-3 atlas-py-2", { "atlas-text-blueGrey-400": !value })}>
          {value?.name ? value.name : "Upload a logo..."}
        </div>
      </div>

      {value ? (
        <button
          onClick={onDeleteClick}
          className="atlas-flex-shrink-0 atlas-flex atlas-items-center atlas-px-2 "
        >
          <i className="icon-lt-delete-bin-line atlas-text-xl" />
        </button>
      ) : (
        <div className="atlas-flex-shrink-0 atlas-flex atlas-items-center atlas-px-2">
          <i className="icon-lt-upload-cloud-2-line atlas-text-xl" />
        </div>
      )}
      {!value && (
        <input
          type="file"
          className="atlas-opacity-0 atlas-absolute atlas-top-0 atlas-w-full atlas-h-full atlas-cursor-pointer"
          onChange={onFileChange}
        />
      )}
    </div>
  );
};
