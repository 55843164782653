import { useMemo } from "react";
import { keyBy, round } from "lodash";
import { useDesignationAreaByGeometry } from "react-migration/domains/constraints/designation/hooks/useDesignationAreaByGeometry";
import { SkeletonLoading } from "react-migration/components/SkeletonLoading";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { Insight } from "react-migration/components/Insight/Insight";
import {
  KeyIndicatorColor,
  useKeyIndicatorTags,
} from "react-migration/lib/map/useKeyIndicatorTags";
import { LayerTypeLayerSummaryProps, SupportedRegions } from "../../../types";
import { useSelectionSidebarLoading } from "../../../Bundle/useSelectionSidebarLoadingState";

type ProtectedLandCategory =
  | typeof GREENBELT_CATEGORY_ID
  | typeof AONB_CATEGORY_ID
  | typeof COMMON_LAND_CATEGORY_ID;

interface ProtectedLandSection {
  title: string;
  intersects: boolean;
  coverage: number;
}

const GREENBELT_CATEGORY_ID = "green_belt_outer";
const AONB_CATEGORY_ID = "national_landscape";
const COMMON_LAND_CATEGORY_ID = "common_land";

const SECTION_CATEGORIES: ProtectedLandCategory[] = [
  GREENBELT_CATEGORY_ID,
  AONB_CATEGORY_ID,
  COMMON_LAND_CATEGORY_ID,
];

export function ProtectedLandLayerSummary({
  selection,
  onLoadingChange,
}: LayerTypeLayerSummaryProps) {
  const { t } = useTranslation();
  const geometry = selection.feature?.geometry ?? null;

  const {
    data: areas,
    loading,
    error,
  } = useDesignationAreaByGeometry(geometry, SECTION_CATEGORIES);

  const sections = useMemo<ProtectedLandSection[]>(() => {
    const designationAreasByCategory = keyBy(areas, "sub_category");

    return SECTION_CATEGORIES.map((category) => {
      const area = designationAreasByCategory[category];

      return {
        title: t(`workbench.summary.protected_land.section_title.${category}`),
        intersects: !!area,
        coverage: area?.category_coverage_percentage ?? 0,
      };
    });
  }, [areas, t]);

  useKeyIndicatorTags(() => {
    const impacted = !!sections.filter((section) => section.intersects).length;
    return impacted
      ? [
          {
            tag: "Yes",
            color: KeyIndicatorColor.RED,
          },
        ]
      : [
          {
            tag: "No",
            color: KeyIndicatorColor.GREEN,
          },
        ];
  }, [sections]);

  useSelectionSidebarLoading(onLoadingChange, loading);

  if (loading) {
    return (
      <div className="atlas-relative atlas-p-2 atlas-h-[102px]">
        <SkeletonLoading rows={1} showPadding={false} bgClassName="atlas-bg-transparent" />
      </div>
    );
  }

  if (error) {
    // caught by error boundary
    throw error;
  }

  return (
    <div className="atlas-grid atlas-grid-cols-3 atlas-gap-2">
      {sections.map((section) => (
        <Insight.Wrapper key={section.title} title={section.title}>
          <Insight.Body status={section.intersects ? "red" : "green"}>
            {section.intersects ? "Yes" : "No"}
          </Insight.Body>
          <Insight.Footer description="Coverage">
            {section.coverage < 1 && section.coverage !== 0 ? "<1" : round(section.coverage)}%
          </Insight.Footer>
        </Insight.Wrapper>
      ))}
    </div>
  );
}

ProtectedLandLayerSummary.icon = "icon-lt-hand-leaf-line";
ProtectedLandLayerSummary.supportedRegions = [
  SupportedRegions.england,
  SupportedRegions.scotland,
  SupportedRegions.wales,
];
