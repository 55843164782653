import { DesignationsSelectionSidebarConnected } from "react-migration/domains/constraints/designation/components/Designations/DesignationsSelectionSidebar";
import type { LayerType } from "../../types";
import { ConstraintsMapLayer } from "./ConstraintsMapLayer";
import { ConstraintsControlPage } from "./ControlPage";
import { constraintClickResolver } from "./constraintClickResolver";
import { ConstraintsLayerTypeProvider } from "./ConstraintsContext";
import { CONSTRAINTS_LAYER_TYPE_ID, LISTED_BUILDINGS_LAYER_SUMMARY_TYPE_ID } from "./constants";
import { ConstraintsPrintable } from "./ConstraintsPrintable";
import { ProtectedLandLayerSummary } from "./ProtectedLandSummary/ProtectedLandSummary";
import { ListedBuildingSummary } from "./ListedBuildingSummary/ListedBuildingSummary";

export const ConstraintsLayerType: LayerType = {
  id: CONSTRAINTS_LAYER_TYPE_ID,
  Provider: ConstraintsLayerTypeProvider,
  MapLayer: ConstraintsMapLayer,
  ControlPage: ConstraintsControlPage,
  SelectionSidebar: DesignationsSelectionSidebarConnected,
  clickResolver: constraintClickResolver,
  Printable: ConstraintsPrintable,
};

export const ConstraintsListedBuildingSummaryLayerType: LayerType = {
  id: LISTED_BUILDINGS_LAYER_SUMMARY_TYPE_ID,
  LayerSummary: ListedBuildingSummary,
};

export const ProtectedLandSummaryLayerType: LayerType = {
  id: LISTED_BUILDINGS_LAYER_SUMMARY_TYPE_ID,
  LayerSummary: ProtectedLandLayerSummary,
};
