import classNames from "classnames";
import { useVariants } from "./useVariants";

interface Props {
  variants: string[];
  extraClassNames?: string;
  prefix?: string;
}

export const TextWithVariants = ({ variants, extraClassNames, prefix }: Props) => {
  const [variant, incrementIndex] = useVariants(variants);

  return (
    <span
      className={classNames(
        "atlas-font-normal atlas-text-platform-600 atlas-cursor-pointer hover:atlas-underline",
        extraClassNames
      )}
      data-testid="text-with-variants"
      onClick={incrementIndex}
    >
      {prefix}
      {variant}
    </span>
  );
};
