import { feature } from "@turf/turf";
import { useCallback } from "react";
import { PlanningApplication } from "react-migration/domains/planning/types";
import { logEventV2 } from "react-migration/lib/util/logEventV2";
import { SelectionFeature, SelectionType } from "src/js/stores/map/store";

interface UseTrackedApplicationSelectionHandlerProps {
  cardName: string;
  clickDetail: string;
}

export function useTrackedApplicationSelectionHandler(
  setSelection: ((selection: SelectionFeature) => void) | undefined,
  eventProps: UseTrackedApplicationSelectionHandlerProps
) {
  return useCallback(
    (application: PlanningApplication) => {
      if (!setSelection) return;

      const selectionGeometry = application.boundary || application.location;

      setSelection({
        type: SelectionType.PLANNING_APPLICATION,
        id: application.id,
        feature: selectionGeometry ? feature(selectionGeometry) : undefined,
      });

      logEventV2({
        name: "Workbench card click",
        properties: {
          ...eventProps,
          action: "goTo",
          planningApplicationID: application.id,
          planningApplicationClassification: application.classification,
          planningApplicationType: application.types_derived.join(", "),
        },
      });
    },
    [eventProps, setSelection]
  );
}
