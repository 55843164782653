import type { LayerType, SelectionHandler } from "../../types";
import { DesignationSelectionHandler } from "../ConstraintsLayerType";
import { DemographicsControls } from "./DemographicsControls";
import { DemographicsLayerSummary } from "./DemographicsLayerSummary";
import { DemographicsMapLayer } from "./DemographicsMapLayer";
import { DemographicsProvider } from "./DemographicsProvider";
import { DemographicsSelectionSidebar } from "./DemographicsSelectionSidebar";
import { DemographicsSelectionTitle } from "./DemographicsSelectionTitle";
import { OutputAreaDetailView } from "./OutputAreaDetailView";
import { DemographicsStyles } from "./constants";
import { demographicsClickResolver } from "./demographicsClickResolver";
import { DemographicsRegion } from "./types";
import { SubCategoryCondition } from "./useDemographicSubCategory";

type AttributeStyleOption = {
  attribute: string;
  style: DemographicsStyles;
  displayName: string;
  customConditions?: SubCategoryCondition[];
};

export type DemographicsLayerConfig = {
  region: DemographicsRegion;
  subCategoryConditions: SubCategoryCondition[];
  attributes_style_options: (AttributeStyleOption | null)[];
};

export const DemographicsLayerType: LayerType = {
  MapLayer: DemographicsMapLayer,
  SelectionSidebar: DemographicsSelectionSidebar,
  ControlPage: DemographicsControls,
  Provider: DemographicsProvider,
  clickResolver: demographicsClickResolver,
};

export const DemographicsSummaryLayerType: LayerType = {
  LayerSummary: DemographicsLayerSummary,
};

export const OutputAreaSelectionHandler: SelectionHandler = {
  ...DesignationSelectionHandler,
  SelectionTitle: DemographicsSelectionTitle,
  SelectionDetailView: OutputAreaDetailView,
};
