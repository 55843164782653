import { keyBy, round } from "lodash";
import { Nullable } from "src/js/types/Nullable";
import { TagColor as KeyIndicatorColor } from "react-migration/components/Tag";
import type { DesignationDistance } from "react-migration/domains/constraints/designation/hooks/useNearestDesignationQuery";
import { useKeyIndicatorTags } from "react-migration/lib/map/useKeyIndicatorTags";
import { isFinite } from "react-migration/lib/util/isDefined";
import { TRAIN_STATION_CATEGORY, SUBWAY_STATION_CATEGORY, BUS_STOP_CATEGORY } from "./constants";

/**
 * The score algorithm calculates a connectivity score based on distances to
 * transport hubs, travel times, and public transport ratings. It works as
 * follows:
 *
 * 1. For each transport hub category (train, subway, bus), it determines a
 *    score based on predefined distance bands. If the distance is undefined or
 *    not finite it is considered too far away and the score is 0.
 *
 * 2. It calculates the highest transport score among the Public Transport
 *    Accessibility (`ptal_lt_rating` designation attribute), train score,
 *    subway score, and bus score.
 *
 * 3. The highest transport score is then combined with the travel times score
 *    median (`travel_times_score_median` designation attribute) to calculate
 *    the final score using:
 *
 *    score = round((median travel time score + highest transport score) / 2)
 */

const CONNECTIVITY_SCORE_MAX = 10;

type TransportHubCategory =
  | typeof TRAIN_STATION_CATEGORY
  | typeof SUBWAY_STATION_CATEGORY
  | typeof BUS_STOP_CATEGORY;

type TransportScoreBand = { score: number; lteMeters: number };

const transportScoreBands: Record<TransportHubCategory, TransportScoreBand[]> = {
  [TRAIN_STATION_CATEGORY]: [
    { score: 10, lteMeters: 100 },
    { score: 9, lteMeters: 500 },
    { score: 8, lteMeters: 1000 },
    { score: 3, lteMeters: 5000 },
    { score: 0, lteMeters: Infinity },
  ],
  [SUBWAY_STATION_CATEGORY]: [
    { score: 10, lteMeters: 100 },
    { score: 9, lteMeters: 500 },
    { score: 8, lteMeters: 1000 },
    { score: 2, lteMeters: 5000 },
    { score: 0, lteMeters: Infinity },
  ],
  [BUS_STOP_CATEGORY]: [
    { score: 6, lteMeters: 100 },
    { score: 4, lteMeters: 500 },
    { score: 2, lteMeters: 1000 },
    { score: 0, lteMeters: Infinity },
  ],
};

function findScoreForDistance(
  distanceMeters: number | undefined,
  scoreBands: TransportScoreBand[]
): number {
  if (!isFinite(distanceMeters)) return 0;
  const band = scoreBands.find((band) => distanceMeters <= band.lteMeters);
  return band?.score ?? 0;
}

export function useConnectivityScoreKeyIndicator(
  distancesToTransportHubs?: Nullable<DesignationDistance[]>,
  travelTimesScoreMedian?: Nullable<number>,
  publicTransportRating?: Nullable<number>
) {
  useKeyIndicatorTags(
    function setKeyIndicators() {
      const transportDistanceMap = keyBy(distancesToTransportHubs, "category");
      const trainStationDistance = transportDistanceMap[TRAIN_STATION_CATEGORY]?.distanceMeters;
      const subwayStationDistance = transportDistanceMap[SUBWAY_STATION_CATEGORY]?.distanceMeters;
      const busStationDistance = transportDistanceMap[BUS_STOP_CATEGORY]?.distanceMeters;

      if (!isFinite(travelTimesScoreMedian)) return [];

      const trainScore = findScoreForDistance(
        trainStationDistance,
        transportScoreBands[TRAIN_STATION_CATEGORY]
      );

      const subwayScore = findScoreForDistance(
        subwayStationDistance,
        transportScoreBands[SUBWAY_STATION_CATEGORY]
      );

      const busScore = findScoreForDistance(
        busStationDistance,
        transportScoreBands[BUS_STOP_CATEGORY]
      );

      const highestTransportScore = Math.max(
        publicTransportRating ?? 0,
        trainScore,
        subwayScore,
        busScore
      );

      const score = Math.min(
        round((travelTimesScoreMedian + highestTransportScore) / 2),
        CONNECTIVITY_SCORE_MAX
      );

      const color =
        score >= 7
          ? KeyIndicatorColor.GREEN
          : score >= 6
          ? KeyIndicatorColor.ORANGE
          : KeyIndicatorColor.RED;

      return [
        {
          tag: `${score} / ${CONNECTIVITY_SCORE_MAX}`,
          color,
        },
      ];
    },
    [distancesToTransportHubs, publicTransportRating, travelTimesScoreMedian]
  );
}
