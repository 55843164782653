import { SkeletonLoading } from "react-migration/components/SkeletonLoading";
import { useSelectionSidebarLoading } from "../../Bundle/useSelectionSidebarLoadingState";
import { LayerTypeLayerSummaryProps, SupportedRegions } from "../../types";
import { Alert } from "react-migration/components/Alert";
import { useDesignationAreaByGeometry } from "react-migration/domains/constraints/designation/hooks/useDesignationAreaByGeometry";
import {
  KeyIndicatorColor,
  useKeyIndicatorTags,
} from "react-migration/lib/map/useKeyIndicatorTags";
import { DesignationAreaChart } from "react-migration/domains/constraints/designation/components/DesignationAreaChart";

const formatter = new Intl.NumberFormat(undefined, {
  style: "percent",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export function HazardWetlandsLayerSummary({
  selection,
  onLoadingChange,
}: LayerTypeLayerSummaryProps) {
  const { data, loading, error } = useDesignationAreaByGeometry(selection?.feature?.geometry, [
    "us_wetlands_estuarine_and_marine_deepwater",
    "us_wetlands_estuarine_and_marine_wetland",
    "us_wetlands_freshwater_emergent_wetland",
    "us_wetlands_freshwater_forested_shrub_wetland",
    "us_wetlands_freshwater_pond",
    "us_wetlands_lake",
    "us_wetlands_riverine",
    "us_wetlands_other",
    "us_wetlands",
  ]);

  useKeyIndicatorTags(() => {
    if (!data) {
      return [];
    }

    const wetlands = data.find((d) => d.sub_category === "us_wetlands")!;

    if (!data.length || wetlands?.category_coverage_area === 0) {
      return [{ tag: "Not impacted", color: KeyIndicatorColor.GREEN }];
    }

    const getColor = (coverage: number) => {
      if (coverage < 5) {
        return KeyIndicatorColor.GREEN;
      }

      if (coverage < 50) {
        return KeyIndicatorColor.ORANGE;
      }

      return KeyIndicatorColor.RED;
    };

    return [
      {
        tag: formatter.format(wetlands.category_coverage_percentage / 100),
        color: getColor(wetlands.category_coverage_percentage),
      },
    ];
  }, [data]);

  useSelectionSidebarLoading(onLoadingChange, loading);

  if (loading) {
    return (
      <div className="atlas-relative atlas-p-2 atlas-h-[168px]">
        <SkeletonLoading rows={2} showPadding={false} bgClassName="atlas-bg-transparent" />
      </div>
    );
  }

  if (error) {
    // caught by error boundary
    throw error;
  }

  if (!data?.length) {
    return <Alert.Info>Selection is not affected by wetlands.</Alert.Info>;
  }

  // Ignore the overarching category, this is not displayed in the summary
  const wetlandsCategories = data.filter((d) => d.sub_category !== "us_wetlands");

  return (
    <div className="atlas-flex atlas-flex-col atlas-gap-y-4">
      <DesignationAreaChart areas={wetlandsCategories} heading="Wetlands" />
    </div>
  );
}

HazardWetlandsLayerSummary.icon = "icon-lt-contrast-drop-2-line";
HazardWetlandsLayerSummary.supportedRegions = [SupportedRegions.us];
