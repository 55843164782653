import { useDesignationsByGeometry } from "react-migration/domains/constraints/designation/hooks/useDesignationsByGeometry";
import { LayerTypeLayerSummaryProps } from "../../types";
import { RecentApplicationsContainer } from "../PlanningApplicationsLayerType/RecentApplicationsSummary";
import { Carousel } from "../PlanningApplicationsLayerType/RecentApplicationsSummary/MostRecentSection";
import { SingleDesignation } from "react-migration/domains/constraints/typings/applicationTypes/SingleDesignation";
import { useCallback, useEffect, useMemo } from "react";
import { logEventV2 } from "react-migration/lib/util/logEventV2";
import { EnvironmentPermitDetail } from "./EnvironmentPermitDetail";
import { useKeyIndicatorTags } from "react-migration/lib/map/useKeyIndicatorTags";
import { TagColor } from "react-migration/components/Tag";
import { Alert } from "react-migration/components/Alert";
import { SkeletonLoading } from "react-migration/components/SkeletonLoading";
import { useWorkbench } from "../../Bundle/Workbench/WorkbenchContext";
import { SelectionType } from "src/js/stores/map/store";
import { EnvironmentalPermitsInfoTooltip } from "./EnvironmentalPermitsInfoTooltip";
function getAttribute(designation: SingleDesignation, key: string) {
  return designation.designation_attributes.find((attr) => attr.key === key)?.value ?? "";
}

const Tooltip = () => {
  return (
    <h1 className="atlas-text-sm atlas-font-medium atlas-text-text-base-primary">
      Most recent <EnvironmentalPermitsInfoTooltip />
    </h1>
  );
};

export function EnvironmentalPermitsSummary({
  selection,
  onLoadingChange,
}: LayerTypeLayerSummaryProps) {
  const geometry = selection.feature?.geometry ?? null;

  const { setDetailSelection } = useWorkbench();

  const { designations, loading, error } = useDesignationsByGeometry({
    geometry,
    rootCategories: ["environmental_permits"],
    attributeKeys: [
      "applicant_name",
      "application_status_date",
      "application_submission_date",
      "application_type",
      "classification",
      "application_ref_#",
      "address",
    ],
  });

  if (error) {
    throw error;
  }

  useEffect(() => {
    onLoadingChange(loading);
  }, [onLoadingChange, loading]);

  useKeyIndicatorTags(() => {
    if (Array.isArray(designations)) {
      return [{ color: TagColor.DEFAULT, tag: designations.length }];
    }

    return [];
  }, [designations]);

  const navigateToPermitDetails = useCallback(
    (designation: SingleDesignation) => {
      setDetailSelection({ type: SelectionType.DESIGNATION, id: designation.id });
    },
    [setDetailSelection]
  );

  if (loading) {
    return (
      <RecentApplicationsContainer
        tooltip={<Tooltip />}
        keyStatsSlot={false}
        mostRecentSlot={
          <div className="atlas-relative atlas-h-[154px]">
            <SkeletonLoading rows={1} showPadding={false} />
          </div>
        }
      />
    );
  }

  if (!designations?.length) {
    return <Alert.Info>No environmental permits found for this selection</Alert.Info>;
  }

  return (
    <RecentApplicationsContainer
      tooltip={<Tooltip />}
      keyStatsSlot={false}
      mostRecentSlot={
        <MostRecentSection designations={designations} onTitleClick={navigateToPermitDetails} />
      }
    />
  );
}

EnvironmentalPermitsSummary.icon = "icon-lt-archive-line";

export interface MostRecentSectionProps {
  designations: SingleDesignation[];
  onTitleClick: (application: SingleDesignation) => void;
}

const CARD_NAME = "Environmental permits";

const MostRecentSection = ({ designations, onTitleClick }: MostRecentSectionProps) => {
  const applicationsOrderedByDateReceived = useMemo(
    () =>
      designations
        .slice()
        .sort(
          (a, b) =>
            +new Date(getAttribute(a, "application_submission_date")) -
            +new Date(getAttribute(b, "application_submission_date"))
        ),
    [designations]
  );

  const handleApplicationChange = useCallback(() => {
    // only log if scroll resulted in viewing a new application
    logEventV2({
      name: "Workbench card click",
      properties: {
        cardName: CARD_NAME,
        clickDetail: "Environmental permit",
        action: "scrolled",
      },
    });
  }, []);

  const onTitleClickHandler = useCallback(
    (application: SingleDesignation) => {
      onTitleClick(application);
      logEventV2({
        name: "Workbench card click",
        properties: {
          cardName: CARD_NAME,
          clickDetail: "Environmental permit",
          action: "goTo",
          planningApplicationID: application.id,
        },
      });
    },
    [onTitleClick]
  );

  return (
    <Carousel
      items={applicationsOrderedByDateReceived}
      onIndexChange={handleApplicationChange}
      renderItem={(application) => (
        <EnvironmentPermitDetail application={application} onTitleClick={onTitleClickHandler} />
      )}
    />
  );
};
