import Feature from "src/js/stores/user/Feature";
import {
  PlanningApplicationsLayerType,
  PlanningApplicationsRecentSummaryLayerType,
} from "../layer_types/PlanningApplicationsLayerType/PlanningLayerType";
import { Layer } from "../types";

export const PLANNING_APPLICATION_LAYER: Layer = {
  id: "PLANNING_APPLICATIONS",
  layerType: PlanningApplicationsLayerType,
  title: "Planning Applications",
  requiredFeatureAccess: [Feature.planningLayer],
  toggleable: true,
  description: "Planning applications and appeals",
  layerConfig: {
    localStorageKey: "planning-filter-state-v2",
  },
};

export const PLANNING_APPLICATION_ASSESSMENT_LAYER: Layer = {
  ...PLANNING_APPLICATION_LAYER,
  id: "PLANNING_APPLICATIONS_ASSESSMENT",
  layerConfig: {
    localStorageKey: "planning-filter-assessment",
    initialFilterState: {
      maxYear: 35,
      minSize: 0,
    },
  },
};

export const PLANNING_APPLICATION_LAYER_SOURCING_TOOL: Layer = {
  id: "PLANNING_APPLICATIONS_SOURCING_TOOL",
  layerType: PlanningApplicationsLayerType,
  title: "Planning Applications",
  requiredFeatureAccess: [Feature.planningLayer],
  toggleable: false,
  description: "Planning applications and appeals",
  hideMapLayer: true,
  layerConfig: {
    localStorageKey: "planning-filter-sourcing-tool",
    initialFilterState: {
      maxYear: 100,
      minSize: 0,
    },
  },
};

export const PLANNING_APPLICATION_RECENT_SUMMARY_LAYER: Layer = {
  id: "PLANNING_APPLICATIONS__RECENT_SUMMARY",
  layerType: PlanningApplicationsRecentSummaryLayerType,
  title: "Recent applications",
  requiredFeatureAccess: [Feature.insightsSummary, Feature.planningLayer],
  requiredBetaAccess: [Feature.insightsSummaryBeta],
  description: "Summary of recent applications and appeals",
  hideMapLayer: true,
  toggleable: true,
};
