import { Button } from "react-migration/components/Button";

import hasFeature from "src/js/stores/user/actions/hasFeature";
import Feature from "src/js/stores/user/Feature";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";

import { setShowAddressModal, setShowUpgradeModal } from "src/js/stores/navigation/actions";
import { logEventV2, UpgradeEventLocation } from "react-migration/lib/util/logEventV2";
import { useAccountQuery } from "react-migration/domains/nav/hooks/useAccountQuery";

export type UpgradeButtonProps = { source: UpgradeEventLocation };

export const UpgradeButton = ({ source }: UpgradeButtonProps) => {
  const { t } = useTranslation();

  const isUSUser = hasFeature(Feature.usAccess);

  // We only care about checking the billing address for US users currently
  const { data: accountData, loading } = useAccountQuery({ skip: !isUSUser });

  const handleUpgrade = () => {
    if (isUSUser && !accountData?.accountWithViews.billing_address?.length) {
      setShowAddressModal(true, {
        onComplete: () => {
          logEventV2({ name: "Upgrade", properties: { source, action: "initiate-upgrade" } });
          setShowAddressModal(false, null);
          setShowUpgradeModal(true);
        },
      });
    } else {
      logEventV2({ name: "Upgrade", properties: { source, action: "initiate-upgrade" } });
      setShowUpgradeModal(true);
    }
  };

  if (!hasFeature(Feature.upgradeButton)) {
    return null;
  }

  return (
    <Button
      variant="primary"
      data-testid="upgrade-button"
      onClick={() => handleUpgrade()}
      tailIcon="lt-arrow-right-s-line"
      disabled={isUSUser && loading}
    >
      {t("nav.nav.upgrade")}
    </Button>
  );
};
