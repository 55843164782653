import { ModalV2 } from "react-migration/components/ModalV2/ModalV2";
import StripeBillingAddress from "react-migration/components/StripePayment/StripeBillingAddress";

export type BillingAddressModalProps = {
  onClose: (v: boolean) => void;
  onComplete?: () => void;
};

export const BillingAddressModal = ({ onComplete, onClose }: BillingAddressModalProps) => {
  return (
    <ModalV2.Root defaultOpen={true} onOpenChange={onClose}>
      <ModalV2.Body
        title="Billing address"
        className="!atlas-z-[99999]"
        contentClassName="!atlas-w-[500px]"
        descriptionClassName="!atlas-overflow-visible"
      >
        <div className="atlas-text-sm atlas-leading-normal" data-testid="overdue-invoice-modal">
          <p>Please enter your billing address before accessing the billing and management page.</p>
        </div>
        <StripeBillingAddress onComplete={onComplete} />
      </ModalV2.Body>
    </ModalV2.Root>
  );
};
