import { SiteFiltersStore, buildSiteFiltersStoreMethods } from "./SiteFiltersStore/store";
import { createContext, ReactPortal, useCallback, useContext, useMemo, useState } from "react";
import logSiteCardEvent, { SiteFiltersView } from "src/js/view/sites/logSiteCardEvent";
import { SiteFilters as TSiteFilters } from "../../typings/SiteFilters";
import classNames from "classnames";
import { noop } from "lodash";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { SiteSearch } from "./components/SiteSearch";
import { ClearFiltersButton } from "./components/ClearFiltersButton";
import { DropdownMenu, DropdownMenuSize } from "react-migration/components/DropdownMenu";
import { Button } from "react-migration/components/Button";
import { createPortal } from "react-dom";
import { SiteLocalAuthorityFilter } from "./components/SiteLocalAuthorityFilter";
import { SiteLabelsFilter } from "./components/SiteLabelsFilter";
import { StagesFilter } from "./components/StagesFilter";
import { AssigneesFilter } from "./components/AssigneesFilter";
import { SelectFilterStatus } from "react-migration/components/SelectFilter";

export type OnFilterUpdateCallback = (filter: keyof TSiteFilters) => void;

export type OnFiltersClearedCallback = () => void;

export type SiteFiltersEventMetadata = {
  view: SiteFiltersView;
};

type VisibilityType = {
  siteCount: number;
  totalSiteCount: number;
  showingStageCount: number;
  totalStageCount: number;
};

export type SiteFiltersProps = {
  /**
   * Which type of Filters layout should be displayed
   */
  layout?: SiteFiltersLayout;
  /** Essential metadata for Site Filters mixpanel event logging */
  eventMetadata: SiteFiltersEventMetadata;
  /** Which store to use */
  store: SiteFiltersStore;
  visibility?: VisibilityType;
};

export enum SiteFiltersLayout {
  Flex = "flex",
  Grid = "grid",
}

/** Context for all *INTERNAL* rendering concerns  to the Site Filters
 * For example, setting up common functions that contain context from consumers for event logging
 *
 * If state must be shared with consumers of SiteFilters then please use the Valtio SiteFiltersStore
 * For example, the values of the filters
 */
export const SiteFiltersContext = createContext<
  {
    onFilterUpdate: OnFilterUpdateCallback;
    onFiltersCleared: OnFiltersClearedCallback;
  } & ReturnType<typeof buildSiteFiltersStoreMethods>
>({
  onFilterUpdate: noop,
  onFiltersCleared: noop,
  useAssignees: () => [],
  updateAssignees: noop,
  useLocalAuthorities: () => [],
  updateLocalAuthorities: noop,
  useSearchString: () => "",
  updateSearchString: noop,
  useSiteLabels: () => [],
  updateSiteLabels: noop,
  useStageIds: () => [],
  updateStageIds: noop,
  resetSiteFiltersStore: noop,
});

export const SiteFilters = ({
  layout = SiteFiltersLayout.Flex,
  eventMetadata: { view },
  visibility,
  store,
}: SiteFiltersProps) => {
  const [showLocalAuthorityModal, setShowLocalAuthorityModal] = useState(false);
  const [showLabelsModal, setShowLabelsModal] = useState(false);
  const [showAssigneesModal, setShowAssigneesModal] = useState(false);
  const [showStagesModal, setShowStagesModal] = useState(false);

  const onFilterUpdate: OnFilterUpdateCallback = useCallback(
    (filter) => {
      logSiteCardEvent({
        name: "Site Filters - Filter Updated",
        context: {
          view,
          filter,
        },
      });
    },
    [view]
  );

  const onFiltersCleared: OnFiltersClearedCallback = useCallback(() => {
    logSiteCardEvent({
      name: "Site Filters - Filters Cleared",
      context: {
        view,
      },
    });
  }, [view]);

  const contextValues = useMemo(() => {
    const storeMethods = buildSiteFiltersStoreMethods(store);
    return { onFilterUpdate, onFiltersCleared, ...storeMethods };
  }, [onFilterUpdate, onFiltersCleared, store]);

  switch (layout) {
    case SiteFiltersLayout.Flex:
      return (
        <SiteFiltersContext.Provider value={contextValues}>
          <div
            data-testid="site-filters"
            className="atlas-flex atlas-flex-row atlas-items-center atlas-gap-4 atlas-shrink-0"
          >
            <SiteSearch />
            <div className="atlas-hidden lg:atlas-flex atlas-gap-2">
              <SiteLocalAuthorityFilter type="desktop" />
              <SiteLabelsFilter type="desktop" />
              <AssigneesFilter type="desktop" />
              <StagesFilter type="desktop" />
              <ClearFiltersButton />
              <div className="atlas-hidden 2xl:atlas-flex atlas-pl-2 atlas-border-l atlas-border-border-base-divider">
                {visibility && <VisibilityDetails visibility={visibility} />}
              </div>
            </div>

            <div className="atlas-flex lg:atlas-hidden atlas-gap-2">
              <SiteLocalAuthorityFilter
                type="mobile"
                openModal={showLocalAuthorityModal}
                setOpenModal={setShowLocalAuthorityModal}
              />
              <SiteLabelsFilter
                type="mobile"
                openModal={showLabelsModal}
                setOpenModal={setShowLabelsModal}
              />
              <AssigneesFilter
                type="mobile"
                openModal={showAssigneesModal}
                setOpenModal={setShowAssigneesModal}
              />
              <StagesFilter
                type="mobile"
                openModal={showStagesModal}
                setOpenModal={setShowStagesModal}
              />
              <MobileFilterDropdown
                setShowLocalAuthorityModal={setShowLocalAuthorityModal}
                setShowLabelsModal={setShowLabelsModal}
                setShowAssigneesModal={setShowAssigneesModal}
                setShowStagesModal={setShowStagesModal}
                visibility={visibility}
              />
              <ClearFiltersButton />
            </div>
          </div>
        </SiteFiltersContext.Provider>
      );
    case SiteFiltersLayout.Grid:
      return (
        <SiteFiltersContext.Provider value={contextValues}>
          <div
            data-testid="site-filters"
            className={classNames(
              "atlas-grid",
              "atlas-grid-cols-10",
              "atlas-grid-rows-3",
              "atlas-gap-1"
            )}
          >
            <SiteSearch className={classNames("atlas-order-1", "atlas-col-span-6")} />
            <SiteLocalAuthorityFilter
              type="desktop"
              className={classNames("atlas-order-3", "atlas-col-span-5")}
            />
            <SiteLabelsFilter
              type="desktop"
              className={classNames("atlas-order-4", "atlas-col-span-5")}
            />
            <AssigneesFilter
              type="desktop"
              className={classNames("atlas-order-5", "atlas-col-span-5")}
            />
            <StagesFilter
              type="desktop"
              className={classNames("atlas-order-6", "atlas-col-span-5")}
            />
            <ClearFiltersButton className={classNames("atlas-order-2", "atlas-col-span-4")} />
          </div>
        </SiteFiltersContext.Provider>
      );

    default:
      return null;
  }
};
type MobileFilterDropdownType = {
  setShowLocalAuthorityModal: (v: boolean) => void;
  setShowLabelsModal: (v: boolean) => void;
  setShowAssigneesModal: (v: boolean) => void;
  setShowStagesModal: (v: boolean) => void;
  visibility?: VisibilityType;
};
const MobileFilterDropdown = ({
  setShowLocalAuthorityModal,
  setShowLabelsModal,
  setShowAssigneesModal,
  setShowStagesModal,
  visibility,
}: MobileFilterDropdownType) => {
  const { t } = useTranslation();
  const { useAssignees, useLocalAuthorities, useStageIds, useSiteLabels } =
    useContext(SiteFiltersContext);

  return (
    <div>
      <DropdownMenu.Provider size={DropdownMenuSize.AUTO}>
        <DropdownMenu.Trigger
          data-testid="sites-filters-trigger"
          align={DropdownMenu.TriggerAlignment["BOTTOM"]}
        >
          <Button leadIcon="lt-filter-line" variant="tertiary" />
        </DropdownMenu.Trigger>
        {
          createPortal(
            <DropdownMenu.Content className="atlas-text-sm">
              {visibility && (
                <div className="atlas-pt-2 atlas-px-2">
                  <VisibilityDetails visibility={visibility} />
                </div>
              )}
              <DropdownMenu.Item
                onClick={() => setShowLocalAuthorityModal(true)}
                className="atlas-flex atlas-justify-between atlas-items-center"
              >
                <span>{t("sites.pipeline.filters.local_authority")}</span>
                <SelectFilterStatus selectedOptionIds={useLocalAuthorities()} />
              </DropdownMenu.Item>
              <DropdownMenu.Item
                onClick={() => setShowLabelsModal(true)}
                className="atlas-flex atlas-justify-between atlas-items-center"
              >
                <span>{t("sites.pipeline.filters.select_labels")}</span>
                <SelectFilterStatus selectedOptionIds={useSiteLabels()} />
              </DropdownMenu.Item>
              <DropdownMenu.Item
                onClick={() => setShowAssigneesModal(true)}
                className="atlas-flex atlas-justify-between atlas-items-center"
              >
                <span>{t("sites.pipeline.filters.select_assignees")}</span>
                <SelectFilterStatus selectedOptionIds={useAssignees()} />
              </DropdownMenu.Item>
              <DropdownMenu.Item
                onClick={() => setShowStagesModal(true)}
                className="atlas-flex atlas-justify-between atlas-items-center"
              >
                <span>{t("sites.pipeline.filters.select_stages")}</span>
                <SelectFilterStatus selectedOptionIds={useStageIds()} />
              </DropdownMenu.Item>
            </DropdownMenu.Content>,
            document.body
          ) as ReactPortal
        }
      </DropdownMenu.Provider>
    </div>
  );
};

const VisibilityDetails = ({ visibility }: { visibility: VisibilityType }) => {
  const { t } = useTranslation();
  return (
    <div className="atlas-self-center atlas-flex-initial atlas-text-xs lg:atlas-text-sm atlas-p-2 atlas-text-text-base-secondary-a">
      {t("sites.pipeline.stages.filters.stages_filters.visibility_indicator", {
        siteCountDisplay: (
          <strong className="atlas-px-0.5">{`${visibility.siteCount || 0}/${
            visibility.totalSiteCount || 0
          }`}</strong>
        ),
        stageCountDisplay: (
          <strong className="atlas-px-0.5">{`${visibility.showingStageCount || 0}/${
            visibility.totalStageCount || 0
          }`}</strong>
        ),
      })}
    </div>
  );
};
