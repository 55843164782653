import { useSnapshot } from "valtio/react";
import { userStore, MeasurementSystem } from "src/js/stores/user/store";
import { formatToCurrency } from "react-migration/lib/util/numberFormat";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { pricePerArea } from "react-migration/domains/comparables/util/Prices";
import { isFinite } from "react-migration/lib/util/isDefined";

interface GbpPerAreaProps {
  gbpPerSqMeter: number | null;
  unitClassNames?: string;
}

export function GbpPerArea({ gbpPerSqMeter, unitClassNames }: GbpPerAreaProps) {
  const { t } = useTranslation();
  const { unit } = useSnapshot(userStore.user.settings);
  const priceDisplay = useRoundedFormattedGbp(gbpPerSqMeter);

  if (!priceDisplay) return <span>-</span>;

  return (
    <span className="atlas-flex">
      {priceDisplay}
      <span className={unitClassNames}>
        &#x2F;
        {unit === MeasurementSystem.METRIC ? t("comparables.sqm.alt") : t("comparables.sqft.alt")}
      </span>
    </span>
  );
}

export function useGbpPerAreaDisplay(gbpPerSqMeter: number | null) {
  const { t } = useTranslation();
  const { unit } = useSnapshot(userStore.user.settings);
  const priceDisplay = useRoundedFormattedGbp(gbpPerSqMeter);

  if (!priceDisplay) return "-";

  return `${priceDisplay}/${
    unit === MeasurementSystem.METRIC ? t("comparables.sqm.alt") : t("comparables.sqft.alt")
  }`;
}

function useRoundedFormattedGbp(gbpPrice: number | null) {
  const { unit } = useSnapshot(userStore.user.settings);

  if (!isFinite(gbpPrice)) return null;

  return formatToCurrency({
    amount: pricePerArea({ price: gbpPrice, unitPreference: unit }),
    currency: "GBP",
    maximumFractionDigits: 0,
  });
}
