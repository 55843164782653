import { includes } from "lodash";
import { TagColor as KeyIndicatorColor } from "react-migration/components/Tag";
import { useKeyIndicatorTags } from "react-migration/lib/map/useKeyIndicatorTags";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { DesignationArea } from "react-migration/domains/constraints/designation/hooks/useDesignationAreaByGeometry";

export function useFloodingKeyIndicators(floodZoneCoverageCategories?: DesignationArea[]) {
  const { t } = useTranslation();

  useKeyIndicatorTags(
    function setKeyIndicators() {
      const intersecting = floodZoneCoverageCategories?.map((area) => area.sub_category) || [];
      const areaPercentages: Record<string, number> =
        floodZoneCoverageCategories?.reduce(
          (acc, { category_coverage_percentage, sub_category }) => {
            acc[sub_category] = Math.round(category_coverage_percentage);
            return acc;
          },
          {} as Record<string, number>
        ) || {};

      if (includes(intersecting, "flood_zone_3_env_agency")) {
        return [
          {
            tag: `${areaPercentages["flood_zone_3_env_agency"]}% ${t(
              "workbench.summary.flooding.risk_level.flood_zone_3_env_agency"
            )}`,
            color: KeyIndicatorColor.RED,
          },
        ];
      } else if (includes(intersecting, "flood_zone_3b")) {
        return [
          {
            tag: `${areaPercentages["flood_zone_3b"]}% ${t(
              "workbench.summary.flooding.risk_level.flood_zone_3b"
            )}`,
            color: KeyIndicatorColor.RED,
          },
        ];
      } else if (includes(intersecting, "flood_zone_3a")) {
        return [
          {
            tag: `${areaPercentages["flood_zone_3a"]}% ${t(
              "workbench.summary.flooding.risk_level.flood_zone_3a"
            )}`,
            color: KeyIndicatorColor.RED,
          },
        ];
      } else if (includes(intersecting, "flood_zone_2_env_agency")) {
        return [
          {
            tag: `${areaPercentages["flood_zone_2_env_agency"]}% ${t(
              "workbench.summary.flooding.risk_level.flood_zone_2_env_agency"
            )}`,
            color: KeyIndicatorColor.ORANGE,
          },
        ];
      } else if (includes(intersecting, "flood_zone_2")) {
        return [
          {
            tag: `${areaPercentages["flood_zone_2"]}% ${t(
              "workbench.summary.flooding.risk_level.flood_zone_2"
            )}`,
            color: KeyIndicatorColor.ORANGE,
          },
        ];
      } else {
        return [{ tag: "No risk", color: KeyIndicatorColor.GREEN }];
      }
    },
    [floodZoneCoverageCategories, t]
  );
}
