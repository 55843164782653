import { LayerTypeSelectionSidebarProps } from "../../types";
import { SlopeStatsStrict } from "./Slope/types";

export enum AspectCategoryKey {
  Flat = "Flat",
  N = "N",
  NE = "NE",
  E = "E",
  SE = "SE",
  S = "S",
  SW = "SW",
  W = "W",
  NW = "NW",
}

export type SlopeCategory = {
  categoryKey: string;
  name: string;
  color: string;
  description: string;
  colorMapRange: [number | string, number | string];
};

export type EnrichedSlopeCategory = SlopeCategory & { percentage: number };

export type AspectCategory = {
  categoryKey: AspectCategoryKey;
  color: string;
  label: string;
  colorMapRanges: [number | string, number | string][];
};

export type EnrichedAspectCategory = AspectCategory & { percentage: number };

export type TopographySelectionSidebarInnerProps<S = SlopeStatsStrict> =
  TopographySelectionSidebarProps & {
    onLoaded?: () => void;
    onStatsLoaded?: (stats: S) => void;
    hideChart?: boolean;
  };

export type TopographySelectionSidebarProps = Pick<LayerTypeSelectionSidebarProps, "selection">;
