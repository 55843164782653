import { SkeletonLoading } from "react-migration/components/SkeletonLoading";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { DesignationAreaChart } from "react-migration/domains/constraints/designation/components/DesignationAreaChart";
import { useSelectionSidebarLoading } from "../../../Bundle/useSelectionSidebarLoadingState";
import { LayerTypeLayerSummaryProps, SupportedRegions } from "../../../types";
import { useFloodingKeyIndicators } from "./useFloodingKeyIndicators";
import { useFloodingSummaryQuery } from "./useFloodingSummaryQuery";
import { Alert } from "react-migration/components/Alert";
import { FloodingLayerSummarySection } from "./FloodingLayerSummarySection";
import { useMemo } from "react";
import turfArea from "@turf/area";

export function FloodingLayerSummary({ selection, onLoadingChange }: LayerTypeLayerSummaryProps) {
  const { t } = useTranslation();
  const { floodRiskCoverage, floodZoneCoverage, error, loading } = useFloodingSummaryQuery(
    selection?.feature?.geometry
  );

  const totalArea = useMemo(() => {
    const geometry = selection?.feature?.geometry;
    if (!geometry) return 0;
    return turfArea(geometry);
  }, [selection?.feature?.geometry]);

  const lowProbabilityFloodZoneArea = useMemo(() => {
    const accumulativeArea = floodZoneCoverage.reduce(
      (acc, { category_coverage_area }) => acc + category_coverage_area,
      0
    );
    return totalArea - accumulativeArea;
  }, [floodZoneCoverage, totalArea]);

  const lowProbabilityFloodZoneAreaPercentage = useMemo(() => {
    const accumulativePercentage = floodZoneCoverage.reduce(
      (acc, { category_coverage_percentage }) => acc + category_coverage_percentage,
      0
    );
    return 100 - accumulativePercentage;
  }, [floodZoneCoverage]);

  const unaffectedFloodRiskArea = useMemo(() => {
    const accumulativeArea = floodRiskCoverage.reduce(
      (acc, { category_coverage_area }) => acc + category_coverage_area,
      0
    );
    return totalArea - accumulativeArea;
  }, [floodRiskCoverage, totalArea]);

  const unaffectedFloodRiskAreaPercentage = useMemo(() => {
    const accumulativePercentage = floodRiskCoverage.reduce(
      (acc, { category_coverage_percentage }) => acc + category_coverage_percentage,
      0
    );
    return 100 - accumulativePercentage;
  }, [floodRiskCoverage]);

  useSelectionSidebarLoading(onLoadingChange, loading);
  useFloodingKeyIndicators(floodZoneCoverage);

  if (loading) {
    return (
      <div className="atlas-relative atlas-p-2 atlas-h-[168px]">
        <SkeletonLoading rows={3} showPadding={false} bgClassName="atlas-bg-transparent" />
      </div>
    );
  }

  if (error) {
    // caught by error boundary
    throw error;
  }

  if (!floodRiskCoverage?.length && !floodZoneCoverage?.length) {
    return <Alert.Info>Selection is not impacted by risk of flooding</Alert.Info>;
  }

  return (
    <div className="atlas-flex atlas-flex-col atlas-gap-y-4">
      <DesignationAreaChart
        areas={floodZoneCoverage}
        heading={t("workbench.summary.flooding.flood_zone_heading")}
        noneLabel="Zone 1"
        tooltip={
          <>
            <p className="atlas-my-2">
              Designated flood zones in the UK are categorised as follows:
            </p>
            <ul className="atlas-list-disc atlas-pl-9 atlas-my-2">
              <li>
                <span className="atlas-font-bold">Flood Zone 1:</span> Low Probability (&lt;0.1%)
              </li>
              <li>
                <span className="atlas-font-bold">Flood Zone 2:</span> Medium Probability (0.1%–1%)
              </li>
              <li>
                <span className="atlas-font-bold">Flood Zone 3:</span> High Probability (&gt;1%)
              </li>
            </ul>
          </>
        }
      />
      <div className="atlas-grid atlas-grid-cols-2 atlas-gap-2">
        {floodZoneCoverage[0] && (
          <FloodingLayerSummarySection
            percentageCovered={floodZoneCoverage[0].category_coverage_percentage}
            areaCovered={floodZoneCoverage[0].category_coverage_area}
            subCategory={floodZoneCoverage[0].sub_category}
          />
        )}
        <FloodingLayerSummarySection
          areaCovered={lowProbabilityFloodZoneArea}
          percentageCovered={lowProbabilityFloodZoneAreaPercentage}
        />
      </div>
      <DesignationAreaChart
        areas={floodRiskCoverage}
        heading={t("workbench.summary.flooding.flood_risk_heading")}
        tooltip={
          <>
            <p className="atlas-my-2">
              Flood risk categorises the likelihood of flooding from seas and rivers, scored as:
            </p>
            <ul className="atlas-list-disc atlas-pl-9 atlas-my-2">
              <li>
                <span className="atlas-font-bold">High Risk:</span> Significant risk of flooding,
                requiring mitigation measures.
              </li>
              <li>
                <span className="atlas-font-bold">Medium Risk:</span> Moderate risk, with careful
                planning needed.
              </li>
              <li>
                <span className="atlas-font-bold">Low Risk:</span> Low likelihood of flooding but
                worth monitoring.
              </li>
              <li>
                <span className="atlas-font-bold">Very Low Risk:</span> Minimal risk, often
                requiring no additional action.
              </li>
            </ul>
          </>
        }
      />
      <div className="atlas-grid atlas-grid-cols-2 atlas-gap-2">
        {floodRiskCoverage[0] && (
          <FloodingLayerSummarySection
            percentageCovered={floodRiskCoverage[0].category_coverage_percentage}
            areaCovered={floodRiskCoverage[0].category_coverage_area}
            subCategory={floodRiskCoverage[0].sub_category}
          />
        )}
        <FloodingLayerSummarySection
          areaCovered={unaffectedFloodRiskArea}
          percentageCovered={unaffectedFloodRiskAreaPercentage}
        />
      </div>
    </div>
  );
}

FloodingLayerSummary.icon = "icon-lt-drop-line";
FloodingLayerSummary.supportedRegions = [
  SupportedRegions.england,
  SupportedRegions.scotland,
  SupportedRegions.wales,
];
