import { useNearestDesignationsQuery } from "react-migration/domains/constraints/designation/hooks/useNearestDesignationQuery";
import { useSelectionSidebarLoading } from "../../Bundle/useSelectionSidebarLoadingState";
import { LayerTypeLayerSummaryProps, SupportedRegions } from "../../types";
import { useConnectivityScoreKeyIndicator } from "./useConnectivityScoreKeyIndicator";
import { useLocalServicesDataByGeometry } from "./useLocalServicesDataByGeometry";
import { ConnectivityScoreLearnMore } from "./ConnectivityScoreLearnMore";
import { LocalServicesSection } from "./LocalServicesSection";
import { TRANSPORT_RADIUSES } from "./constants";
import { TransportSection } from "./TransportSection";

export function AmenitiesLayerSummary({ selection, onLoadingChange }: LayerTypeLayerSummaryProps) {
  const geometry = selection?.feature?.geometry;
  const { distances: transportHubDistances, ...transportHubQuery } = useNearestDesignationsQuery(
    geometry,
    TRANSPORT_RADIUSES
  );

  const {
    tableData,
    travelTimesScoreMedian,
    publicTransportRating,
    ...localServicesTravelTimeQuery
  } = useLocalServicesDataByGeometry(geometry);

  useSelectionSidebarLoading(
    onLoadingChange,
    transportHubQuery.loading || localServicesTravelTimeQuery.loading
  );

  useConnectivityScoreKeyIndicator(
    transportHubDistances,
    travelTimesScoreMedian,
    publicTransportRating
  );

  if (transportHubQuery.error || localServicesTravelTimeQuery.error) {
    // caught by error boundary
    throw transportHubQuery.error || localServicesTravelTimeQuery.error;
  }

  return (
    <>
      <div className="atlas-flex atlas-flex-col atlas-gap-5">
        <TransportSection distances={transportHubDistances} loading={transportHubQuery.loading} />
        <LocalServicesSection
          tableData={tableData}
          loading={localServicesTravelTimeQuery.loading}
        />
      </div>
      <ConnectivityScoreLearnMore />
    </>
  );
}

AmenitiesLayerSummary.icon = "icon-lt-circle-line-dot-radius";
AmenitiesLayerSummary.title = "Connectivity score";
AmenitiesLayerSummary.supportedRegions = [SupportedRegions.england];
