import Feature from "src/js/stores/user/Feature";
import { ConstraintsCategory } from "../../Constraints/types";
import { Layer } from "../types";
import { ConstraintsLayerType } from "../layer_types/ConstraintsLayerType";
import { ZoningFlumSummaryLayerType } from "../layer_types/ZoningLayerType";

export const ZONING: Layer = {
  id: ConstraintsCategory.ZONING,
  layerType: ConstraintsLayerType,
  hideByDefault: false,
  title: "Zoning",
  requiredFeatureAccess: [Feature.zoningLayer],
  toggleable: true,
  mapAttributionText: "map.map_overlay.attributions.zoneomics",
  description: "Zoning map and key development standards",
  layerConfig: {
    intersectionThresholdMeters: -2,
    rootCategory: ConstraintsCategory.ZONING,
    showConstraintLabels: true,
    categorySchema: [
      { key: "zoning_agriculture_outer", defaultValue: true },
      { key: "zoning_planned_outer", defaultValue: false },
      { key: "zoning_special_outer", defaultValue: false },
      { key: "zoning_mixed_outer", defaultValue: false },
      { key: "zoning_single_family", defaultValue: true },
      { key: "zoning_two_family", defaultValue: true },
      { key: "zoning_multi_family", defaultValue: true },
      { key: "zoning_neighborhood_commercial", defaultValue: true },
      { key: "zoning_general_commercial", defaultValue: true },
      { key: "zoning_core_commercial", defaultValue: true },
      { key: "zoning_retail_commercial", defaultValue: true },
      { key: "zoning_special_commercial", defaultValue: true },
      { key: "zoning_light_industrial", defaultValue: true },
      { key: "zoning_industrial", defaultValue: true },
      { key: "zoning_office", defaultValue: true },
      { key: "zoning_overlay_outer", defaultValue: true },
    ],
  },
};

export const ZONING_FLUM_SUMMARY: Layer = {
  id: `${ConstraintsCategory.ZONING}_Summary`,
  layerType: ZoningFlumSummaryLayerType,
  title: "Zoning",
  requiredFeatureAccess: [Feature.insightsSummary],
  requiredBetaAccess: [Feature.usInsightsBeta],
  toggleable: true,
  description: "Zoning map and key development standards",
  hideByDefault: true,
};
