import { useContext, useCallback } from "react";
import { SelectFilterModal, SelectFilterPopover } from "react-migration/components/SelectFilter";
import { useSiteStagesOptions } from "react-migration/domains/sites/hooks/useSiteStages";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { SiteFiltersContext } from "../SiteFilters";

type StagesFilterProps = {
  className?: string;
  type: "mobile" | "desktop";
  openModal?: boolean;
  setOpenModal?: (v: boolean) => void;
};
export const StagesFilter = ({ className, type, openModal, setOpenModal }: StagesFilterProps) => {
  const { t } = useTranslation();
  const { onFilterUpdate, useStageIds, updateStageIds } = useContext(SiteFiltersContext);

  const siteStagesOptions = useSiteStagesOptions();
  const selectedStageIds = useStageIds();

  const onSelectedOptionsUpdate = useCallback(
    (optionIds: string[]) => {
      onFilterUpdate("stageIds");
      updateStageIds(optionIds);
    },
    [onFilterUpdate, updateStageIds]
  );

  if (siteStagesOptions.length > 0) {
    if (type === "desktop") {
      return (
        <SelectFilterPopover
          dataTestId="stages-select-filter"
          label={t("sites.pipeline.filters.stage")}
          contentTitle={t("sites.pipeline.filters.select_stages")}
          options={siteStagesOptions}
          selectedOptionIds={selectedStageIds}
          onSelectedOptionsUpdate={onSelectedOptionsUpdate}
          className={className}
        />
      );
    }
    if (type === "mobile" && openModal && setOpenModal) {
      return (
        <SelectFilterModal
          contentTitle={t("sites.pipeline.filters.select_stages")}
          options={siteStagesOptions}
          selectedOptionIds={selectedStageIds}
          onSelectedOptionsUpdate={onSelectedOptionsUpdate}
          open={openModal}
          setOpen={setOpenModal}
        />
      );
    }
  } else {
    return null;
  }
};
