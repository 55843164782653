import clsx from "clsx";
import { Insight } from "react-migration/components/Insight/Insight";
import { TextWithVariants } from "react-migration/components/TextWithVariants";
import { getCategoryFillColour } from "react-migration/domains/constraints/designation/style/accessors";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { Tooltip } from "react-migration/components/Tooltip";
import { getAreaVariants } from "react-migration/lib/util/getAreaAndPerimeter";
import { userStore } from "src/js/stores/user/store";
import { useSnapshot } from "valtio";
import { Theme } from "react-migration/lib/theme/Theme";

type FloodingLayerSummarySectionProps = {
  percentageCovered: number;
  areaCovered?: number;
  subCategory?: string;
};

export function FloodingLayerSummarySection({
  areaCovered,
  percentageCovered,
  subCategory,
}: FloodingLayerSummarySectionProps) {
  const { user } = useSnapshot(userStore);
  const { t } = useTranslation();
  const heading = subCategory
    ? "workbench.summary.flooding.most_impacted_area.heading"
    : "workbench.summary.flooding.unaffected_area.heading";
  const backgroundColor = subCategory
    ? `rgb(${getCategoryFillColour(subCategory).slice(0, 3).join(",")})`
    : "rgb(160, 172, 188)";
  const areaVariants = getAreaVariants(areaCovered, user.settings.unit);

  return (
    <Insight.Wrapper
      title={
        <div className="atlas-flex atlas-flex-row atlas-items-center atlas-gap-1">
          {subCategory ? (
            <Tooltip.Wrapper>
              <Tooltip.Content size="sm" side="top" align="center" theme={Theme.Light}>
                <Tooltip.Description>
                  {t(`workbench.summary.flooding.risk_level.${subCategory}`)}
                </Tooltip.Description>
              </Tooltip.Content>
              <Tooltip.Trigger>
                <div
                  className={clsx("atlas-rounded-full atlas-h-3 atlas-w-3 atlas-cursor-pointer")}
                  style={{ backgroundColor }}
                />
              </Tooltip.Trigger>
            </Tooltip.Wrapper>
          ) : (
            <div
              className={clsx("atlas-rounded-full atlas-h-3 atlas-w-3")}
              style={{ backgroundColor }}
            />
          )}
          <span className="atlas-flex atlas-gap-0.5">{t(heading)}</span>
        </div>
      }
    >
      <Insight.Body>
        <TextWithVariants variants={areaVariants} />
      </Insight.Body>
      <Insight.Footer
        description={t("workbench.summary.flooding.section_footer.of_selection")}
        descriptionPosition="right"
      >
        {Math.round(percentageCovered) + "%"}
      </Insight.Footer>
    </Insight.Wrapper>
  );
}
