import { PropsWithChildren } from "react";
import { Tooltip } from "react-migration/components/Tooltip";
import { Theme } from "react-migration/lib/theme/Theme";

export const InfoTooltip = ({ children }: PropsWithChildren) => {
  if (!children) return null;

  return (
    <div>
      <Tooltip.Wrapper>
        <Tooltip.Content size="sm" side="top" align="center" theme={Theme.Light}>
          <Tooltip.Description>{children}</Tooltip.Description>
        </Tooltip.Content>
        <Tooltip.Trigger>
          <i className="icon-lt-information-line atlas-text-text-base-secondary-a print:atlas-hidden" />
        </Tooltip.Trigger>
      </Tooltip.Wrapper>
    </div>
  );
};
