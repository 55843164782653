import { useMemo } from "react";
import { sortBy } from "lodash";
import { Section } from "react-migration/components/Section";
import { TextWithVariants } from "react-migration/components/TextWithVariants";
import { getAreaVariants, getLengthVariants } from "react-migration/lib/util/getAreaAndPerimeter";
import { Tag, TagColor } from "react-migration/components/Tag";
import { LayerTypeSelectionDetailViewProps } from "../../../types";
import { usePropertyInformationByUPRN } from "./usePropertyInformationByUPRN";
import { useSnapshot } from "valtio";
import { userStore } from "src/js/stores/user/store";
import { formatToCurrency } from "react-migration/lib/util/numberFormat";
import { EpcTag } from "../UKPropertyInformationSelectionSidebar/UKPropertyInformationSelectionSidebar";
import { addYears } from "date-fns";
import { Insight } from "react-migration/components/Insight/Insight";

const UnknownValue = () => "Unknown";

export function UKPropertyInformationSelectionDetailView({
  selection,
}: LayerTypeSelectionDetailViewProps) {
  const { user } = useSnapshot(userStore);
  const selectedPropertyUPRN = selection.id;

  const { data: { propertyInformationByUPRN } = {}, loading } =
    usePropertyInformationByUPRN(selectedPropertyUPRN);

  const latestValuation = useMemo(() => {
    if (!propertyInformationByUPRN?.address.voa_record?.valuations?.length) return undefined;

    return sortBy(propertyInformationByUPRN.address.voa_record?.valuations, "list_year")[0];
  }, [propertyInformationByUPRN?.address.voa_record?.valuations]);

  if (loading || !propertyInformationByUPRN) return null;

  const { address, building_height, dimensions, epc } = propertyInformationByUPRN;

  return (
    <Section bodyClasses="atlas-flex atlas-flex-col atlas-px-3 atlas-py-2 atlas-gap-4">
      <Insight.Wrapper title="Use class">
        <Insight.Body>
          {address.use_class_description_2020 ? (
            <div className="atlas-inline-block">
              <Tag tag={address.use_class_description_2020} color={TagColor.DEFAULT} size="md" />
            </div>
          ) : (
            <UnknownValue />
          )}
        </Insight.Body>
      </Insight.Wrapper>

      <div className="atlas-grid atlas-grid-cols-2 atlas-gap-x-2 atlas-gap-y-4">
        <Insight.Wrapper title="Highest point">
          <Insight.Body>
            {typeof building_height?.rel_h_max === "number" ? (
              <TextWithVariants
                variants={getLengthVariants(building_height.rel_h_max, user.settings.unit)}
              />
            ) : (
              <UnknownValue />
            )}
          </Insight.Body>
        </Insight.Wrapper>

        <Insight.Wrapper title="Roof height">
          <Insight.Body>
            {typeof building_height?.rel_h_to_roof === "number" ? (
              <TextWithVariants
                variants={getLengthVariants(building_height.rel_h_to_roof, user.settings.unit)}
              />
            ) : (
              <UnknownValue />
            )}
          </Insight.Body>
        </Insight.Wrapper>

        <Insight.Wrapper title="Property type">
          <Insight.Body>
            {(epc?.property_type ?? address.property_class_description) || <UnknownValue />}
          </Insight.Body>
        </Insight.Wrapper>

        <Insight.Wrapper title="Built form">
          <Insight.Body>{epc?.built_form || <UnknownValue />}</Insight.Body>
        </Insight.Wrapper>

        <Insight.Wrapper title="Floor area">
          <Insight.Body>
            {typeof epc?.total_floor_area === "number" ||
            typeof dimensions?.area_floor_total_metric === "number" ? (
              <TextWithVariants
                variants={getAreaVariants(
                  epc?.total_floor_area ?? dimensions?.area_floor_total_metric,
                  user.settings.unit
                )}
              />
            ) : (
              <UnknownValue />
            )}
          </Insight.Body>
        </Insight.Wrapper>

        <Insight.Wrapper title="Floors">
          <Insight.Body>
            {building_height?.no_floors_estimate ? (
              `${building_height.no_floors_estimate} (estimate)`
            ) : (
              <UnknownValue />
            )}
          </Insight.Body>
        </Insight.Wrapper>

        <Insight.Wrapper title="Tenure">
          <Insight.Body>{epc?.tenure || <UnknownValue />}</Insight.Body>
        </Insight.Wrapper>

        <Insight.Wrapper title="State">
          <Insight.Body>{address.state || <UnknownValue />}</Insight.Body>
        </Insight.Wrapper>

        <Insight.Wrapper title="Habitable rooms">
          <Insight.Body>{epc?.number_habitable_rooms ?? <UnknownValue />}</Insight.Body>
        </Insight.Wrapper>

        <Insight.Wrapper title="Roof type">
          <Insight.Body>{epc?.roof_type || <UnknownValue />}</Insight.Body>
        </Insight.Wrapper>

        <Insight.Wrapper title="Construction age">
          <Insight.Body>{epc?.construction_age_band || <UnknownValue />}</Insight.Body>
        </Insight.Wrapper>

        <Insight.Wrapper title="Rateable value">
          <Insight.Body>
            {latestValuation?.total_value ? (
              formatToCurrency({ amount: latestValuation.total_value, currency: "GBP" })
            ) : (
              <UnknownValue />
            )}
          </Insight.Body>
        </Insight.Wrapper>
      </div>

      <h2 className="atlas-text-lg atlas-text-neutral-800 atlas-ml-2">EPC</h2>
      <div className="atlas-grid atlas-grid-cols-2 atlas-gap-x-2 atlas-gap-y-4">
        <Insight.Wrapper title="Current">
          <Insight.Body>
            {epc?.current_energy_rating ? (
              <EpcTag
                rating={epc?.current_energy_rating}
                efficiency={epc?.current_energy_efficiency}
                size="md"
              />
            ) : (
              <UnknownValue />
            )}
          </Insight.Body>
        </Insight.Wrapper>

        <Insight.Wrapper title="Potential">
          <Insight.Body>
            {epc?.potential_energy_rating ? (
              <EpcTag
                rating={epc?.potential_energy_rating}
                efficiency={epc?.potential_energy_efficiency}
                size="md"
              />
            ) : (
              <UnknownValue />
            )}
          </Insight.Body>
        </Insight.Wrapper>

        <Insight.Wrapper title="Inspection Date">
          <Insight.Body>
            {epc?.appraisal_date ? (
              new Date(epc?.appraisal_date).toLocaleDateString()
            ) : (
              <UnknownValue />
            )}
          </Insight.Body>
        </Insight.Wrapper>

        <Insight.Wrapper title="Valid Until">
          <Insight.Body>
            {epc?.appraisal_date ? (
              addYears(new Date(epc?.appraisal_date), 10).toLocaleDateString()
            ) : (
              <UnknownValue />
            )}
          </Insight.Body>
        </Insight.Wrapper>
      </div>
    </Section>
  );
}
