import { BillingAddressModalProps } from "react-migration/domains/nav/components/Modals/BillingAddressModal";
import { proxy } from "valtio/vanilla";

export type NavigationStore = {
  showTopupModal: boolean;
  showUpgradeModal: boolean;
  showTrialEndingModal: boolean;
  showMultiDeviceBlockModal: boolean;
  showOverdueInvoiceModal: boolean;
  showDebugModal: boolean;
  showImpersonationBanner: boolean;
  showOverdueInvoiceBanner: boolean;
  showBillingAddressModal: boolean;
  billingAddressModalProps: Omit<BillingAddressModalProps, "onClose"> | null;
};

const defaultState: NavigationStore = {
  showTopupModal: false,
  showUpgradeModal: false,
  showTrialEndingModal: false,
  showOverdueInvoiceModal: false,
  showDebugModal: false,
  showMultiDeviceBlockModal: false,
  showImpersonationBanner: false,
  showOverdueInvoiceBanner: true,
  showBillingAddressModal: false,
  billingAddressModalProps: {},
};

export const createNavigationStore = () => defaultState;
export const navigationStore = proxy<NavigationStore>(createNavigationStore());
