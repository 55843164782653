import { PlanningApplication, StatusDerived } from "../../types";
import { Nullable } from "src/js/types/Nullable";
import cn from "clsx";
import {
  getAppealColor,
  getStatusColor,
  PLANNING_TEXT_COLOR_MAP,
  PlanningColor,
} from "../../utils/colors";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { formatClassificationValue } from "../../utils/textFormatting";
import { AppealTag } from "../AppealTag";
import { Tag, TagColor } from "react-migration/components/Tag/Tag";

interface PlanningApplicationListItemProps {
  planningApplication: PlanningApplication;
  onClick?: (application: PlanningApplication) => void;
}

export function PlanningApplicationListItem({
  planningApplication,
  onClick,
}: PlanningApplicationListItemProps) {
  const highlightColor =
    getAppealColor(planningApplication.appeal_decision) ||
    getStatusColor(planningApplication.status_derived) ||
    PlanningColor.DEFAULT;

  return (
    <section className="atlas-relative atlas-bg-white atlas-flex hover:atlas-bg-neutral-100 atlas-cursor-pointer">
      <div
        className={cn("atlas-w-full atlas-p-2 border-0 atlas-border-l-4 atlas-border-solid", {
          "atlas-border-green-400": highlightColor === PlanningColor.GREEN,
          "atlas-border-neutral-400": highlightColor === PlanningColor.GRAY,
          "atlas-border-red-500": highlightColor === PlanningColor.RED,
          "atlas-border-amber-500": highlightColor === PlanningColor.ORANGE,
          "atlas-border-b-blue-700": highlightColor === PlanningColor.DEFAULT,
        })}
      >
        <ItemBody planningApplication={planningApplication} />
        <ItemFooter
          reference={planningApplication.ref}
          status_derived={planningApplication.status_derived}
          decisionDate={planningApplication.decision_date}
        />
      </div>
      <button
        className="atlas-absolute atlas-inset-0 atlas-w-full"
        onClick={() => onClick?.(planningApplication)}
        title={planningApplication.title}
      />
    </section>
  );
}

const LINE_CLAMP = 3;

interface ItemBodyProps {
  planningApplication: PlanningApplication;
}

function ItemBody({ planningApplication }: ItemBodyProps) {
  const {
    classification,
    num_dwellings,
    title,
    appeal_decision: appealDecision,
    tags,
  } = planningApplication;

  const { t } = useTranslation();

  return (
    <div>
      <div className="atlas-flex atlas-flex-wrap atlas-gap-1">
        <Tag tag={formatClassificationValue(classification)} color={TagColor.DEFAULT} />
        {appealDecision && <AppealTag appealDecision={appealDecision} />}

        {!!num_dwellings && <Tag tag={t("planning.dwelling_count", { count: num_dwellings })} />}

        {tags?.map((tag) => (
          <Tag key={tag} tag={tag} />
        ))}
      </div>
      <h1
        className={cn(
          "atlas-text-xs atlas-overflow-hidden atlas-text-ellipsis atlas-font-normal atlas-mb-1.5 atlas-mt-1.5",
          /** height = line height (1rem) * line clamp */
          { "atlas-max-h-12 atlas-line-clamp-3": LINE_CLAMP === 3 }
        )}
      >
        {title}
      </h1>
    </div>
  );
}

interface ItemFooterProps {
  reference: string;
  status_derived: Nullable<StatusDerived>;
  decisionDate: Nullable<string>;
}

function ItemFooter({ reference, status_derived, decisionDate }: ItemFooterProps) {
  const statusColor = getStatusColor(status_derived) || PlanningColor.DEFAULT;

  return (
    <div className="atlas-flex atlas-text-xs atlas-text-neutral-500 atlas-gap-x-2">
      <div className={PLANNING_TEXT_COLOR_MAP[statusColor]}>{status_derived}</div>
      <div>{reference}</div>
      {decisionDate && (
        <div className="atlas-ml-auto"> Received {new Date(decisionDate).toLocaleDateString()}</div>
      )}
    </div>
  );
}
