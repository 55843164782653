import { Tooltip } from "react-migration/components/Tooltip";
import { Theme } from "react-migration/lib/theme/Theme";

export const EnvironmentalPermitsInfoTooltip = () => (
  <Tooltip.Wrapper>
    <Tooltip.Content size="sm" side="bottom" align="end" theme={Theme.Light}>
      <Tooltip.Description>
        <p className="atlas-my-2 atlas-text-xs atlas-font-normal">
          All Environmental Resource Permits submitted in the last 2 years from Florida’s five Water
          Management Districts.
        </p>
      </Tooltip.Description>
    </Tooltip.Content>
    <Tooltip.Trigger>
      <i className="icon-lt-information-line atlas-text-text-base-secondary-a print:atlas-hidden" />
    </Tooltip.Trigger>
  </Tooltip.Wrapper>
);
