import { useMemo } from "react";
import { SelectionGeometry } from "src/js/stores/map/store";
import { Nullable } from "src/js/types/Nullable";
import { useDesignationWithLargestOverlapByGeometry } from "react-migration/domains/constraints/designation/hooks/useDesignationWithLargestOverlapByGeometry";
import { SparklineCategory, Sparkline } from "react-migration/components/Sparkline/Sparkline";
import { numberAttributeAccessor } from "react-migration/domains/constraints/designation/util/numberAttributeAccessor";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { InfoTooltip } from "react-migration/components/InfoTooltip";
import { isDefined } from "react-migration/lib/util/isDefined";
import {
  HOUSING_STOCK_DESIGNATION_CATEGORY_ID,
  HOUSING_STOCK_ATTRIBUTE_KEYS_BY_TYPE,
  HOUSING_COLOURS,
  HousingCategory,
} from "./constants";
import { SkeletonLoading } from "react-migration/components/SkeletonLoading";

interface HousingStockSectionProps {
  geometry: Nullable<SelectionGeometry>;
}
export function HousingStockSection({ geometry }: HousingStockSectionProps) {
  const { t } = useTranslation();

  const {
    designation: housingStockDesignation,
    loading,
    error,
  } = useDesignationWithLargestOverlapByGeometry({
    geometry,
    rootCategories: [HOUSING_STOCK_DESIGNATION_CATEGORY_ID],
    attributeKeys: Object.values(HOUSING_STOCK_ATTRIBUTE_KEYS_BY_TYPE),
  });

  const housingStockByTypeSparklineCategories = useMemo<SparklineCategory[]>(() => {
    if (!housingStockDesignation) return [];

    return Object.entries(HOUSING_STOCK_ATTRIBUTE_KEYS_BY_TYPE)
      .map(([housingType, attributeKey]) => {
        const valuePc = numberAttributeAccessor(housingStockDesignation, attributeKey);

        if (valuePc === null) return null;

        return {
          categoryKey: attributeKey,
          name: t(`workbench.summary.local_market_value.type.${housingType}`),
          color: HOUSING_COLOURS[housingType as HousingCategory],
          percentage: valuePc * 100,
        };
      })
      .filter(isDefined);
  }, [housingStockDesignation, t]);

  if (error) {
    // caught by error boundary
    throw error;
  }

  return (
    <section className="atlas-flex atlas-flex-col atlas-gap-y-2">
      <div className="atlas-flex atlas-gap-x-2">
        <h1 className="atlas-text-text-base-primary atlas-text-sm atlas-font-medium">
          Housing stock by type
        </h1>
        <InfoTooltip>
          This represents the distribution of residential properties by type within the surrounding
          area (MSOA) based on the latest available data.
        </InfoTooltip>
      </div>
      {loading ? (
        <div className="atlas-relative atlas-h-[24px]">
          <SkeletonLoading
            cols={1}
            rows={1}
            showPadding={false}
            bgClassName="atlas-bg-transparent"
          />
        </div>
      ) : (
        <Sparkline
          categories={housingStockByTypeSparklineCategories}
          noneLabel="Other"
          noneCategoryPosition="end"
        />
      )}
    </section>
  );
}
