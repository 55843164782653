import Feature from "src/js/stores/user/Feature";
import { ConstraintsCategory } from "../../Constraints/types";
import { SubstationLayerType } from "../layer_types/SubstationLayerType";
import { Layer } from "../types";

export const POWER_SUBSTATIONS: Layer = {
  id: ConstraintsCategory.SUB_STATIONS,
  layerType: SubstationLayerType,
  title: "Power Substations",
  requiredFeatureAccess: [Feature.powerLayer],
  toggleable: true,
  description: "Location of substations",
  layerConfig: {
    minZoom: 7,
    showTitle: true,
    disableHatch: true,
    dedupePoints: false,
    zoomPointScale: {
      pointScale: [0.2, 1],
      zoom: [6, 16],
    },
    rootCategory: ConstraintsCategory.SUB_STATIONS,
    layerRoots: [
      "grid_power_substations",
      "bulk_power_substations",
      "primary_power_substations",
      "hv_power_substations",
    ],
    categorySchema: [
      { key: "grid_power_substations" },
      { key: "bulk_power_substations" },
      { key: "primary_power_substations" },
      { key: "hv_power_substations" },
    ],
    attributeFilters: [
      {
        attribute: "rag_status",
        options: [
          { value: "RED", display_name: "Red" },
          { value: "AMBER", display_name: "Amber" },
          { value: "GREEN", display_name: "Green" },
          { value: "UNKNOWN", display_name: "Unknown" },
        ],
      },
    ],
  },
};
