import { QueryHookOptions, useQuery, gql } from "@apollo/client";
import { SOURCE_STATUSES } from "react-migration/domains/constraints/constants";
import { SingleDesignation } from "react-migration/domains/constraints/typings/applicationTypes/SingleDesignation";
import { Nullable } from "src/js/types/Nullable";
import { cleanTypename } from "react-migration/lib/util/cleanTypename";
import { LandTechEndpoints, routedClient } from "react-migration/lib/persistence/apollo";
import { SelectionGeometry } from "src/js/stores/map/store";
import { useMemo } from "react";

const EMPTY_ARRAY: SingleDesignation[] = [];

type UseDesignationsByGeometryArgs = {
  geometry: Nullable<SelectionGeometry> | null;
  rootCategories: string[];
  attributeKeys?: string[];
  bufferMeters?: number;
  queryOptions?: QueryHookOptions;
  includeArea?: boolean;
  includeDocuments?: boolean;
};

export function useDesignationsByGeometry({
  geometry,
  rootCategories,
  bufferMeters,
  // For the considerations query we have custom code to style by rag_status.
  // TODO: make this dynamic when the bundle concept is app wide.
  attributeKeys = ["rag_status", "voltage_class"],
  includeArea = false,
  includeDocuments = false,
  queryOptions = {},
}: UseDesignationsByGeometryArgs) {
  const cleanGeometry = useMemo(() => cleanTypename(geometry), [geometry]);

  const { data, ...rest } = useQuery<{ designationsByGeometry: SingleDesignation[] }>(
    gql`
      query (
        $geometry: GeoJSONGeometryInput!
        $dataStatuses: [String]
        $sourceStatuses: [String]
        $categories: [String]
        $attributeKeys: [String]
        $bufferMeters: Float
      ) {
        designationsByGeometry(
          geometry: $geometry
          dataStatuses: $dataStatuses
          sourceStatuses: $sourceStatuses
          categories: $categories
          bufferMeters: $bufferMeters
        ) {
          id
          display_name
          key_code
          sub_category_id
          ${
            includeArea
              ? `
        category_geometry
        category_coverage_area
        category_coverage_percentage`
              : ""
          }
          sub_category {
            id
            display_name
            description
          }
          source {
            id
            status
            adoption_date
            documents {
              url
            }
            authority {
              name
            }
          }
          designation_attributes(keys: $attributeKeys) {
            id
            key
            value
            designation_attribute_group {
              id
              code
              display_name
            }
            child_designation_attributes {
              id
              key
              value
            }
          }
          ${
            includeDocuments
              ? `
            designation_document_sections {
              document_section {
                pages
                document {
                  id
                  document_name
                  url
                }
              }
            }
            `
              : ""
          }
        }
      }
    `,
    {
      variables: {
        geometry: cleanGeometry,
        dataStatuses: ["live"],
        attributeKeys: attributeKeys,
        sourceStatuses: SOURCE_STATUSES.map((ss) => ss.id),
        categories: rootCategories,
        bufferMeters,
      },
      client: routedClient,
      context: {
        endpoint: LandTechEndpoints.Constraints,
      },
      skip: !geometry,
      ...queryOptions,
    }
  );

  return { designations: data?.designationsByGeometry || EMPTY_ARRAY, ...rest };
}
