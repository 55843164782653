import { Checkbox, CheckboxState } from "react-migration/components/DeprecatedCheckbox";
import { Column, IRow, TableSize } from "./Table";
import clsx from "clsx";

interface TableHeaderProps<Row extends IRow> {
  columns: Column<Row>[];
  selection?: {
    checkboxState: CheckboxState;
    selectAll: (isSelected: boolean) => void;
  };
  size: TableSize;
  headerCellClassName?: (column: Column<Row>) => string | undefined;
}

export function TableHeader<Row extends IRow>({
  columns,
  selection,
  size,
  headerCellClassName,
}: TableHeaderProps<Row>) {
  const getHeaderClass = (alignment = "left") => {
    return clsx(
      "atlas-sticky",
      "atlas-top-0",
      "atlas-bg-neutral-50",
      "atlas-py-4",
      "atlas-font-semibold",
      "atlas-text-sm",
      "atlas-text-text-base-secondary",
      {
        "atlas-text-right": alignment === "right",
        "atlas-text-left": alignment === "left",
        "atlas-text-center": alignment === "center",
        "atlas-px-3": size === "small",
        "atlas-px-6": size !== "small",
      }
    );
  };

  return (
    <thead
      className={clsx(
        "atlas-min-w-full",
        "atlas-border-b",
        "atlas-border-x-0",
        "atlas-border-t-0",
        "atlas-border-solid",
        "atlas-border-border-action"
      )}
      data-testid="table-head"
    >
      <tr>
        {selection && (
          <th
            className={clsx(
              "atlas-pt-1",
              "atlas-bg-neutral-50",
              "atlas-sticky",
              "atlas-top-0",
              "atlas-pl-4",
              "atlas-text-left"
            )}
          >
            <Checkbox value={selection.checkboxState} onChange={selection.selectAll} />
          </th>
        )}
        {columns.map((column, colIdx) => {
          const { label, align, rowKey, maxWidth, colSpan } = column;
          return (
            <th
              key={`header-col-${(rowKey ?? colIdx).toString()}`}
              scope="col"
              style={{ width: maxWidth }}
              colSpan={colSpan}
              className={clsx(getHeaderClass(align), headerCellClassName?.(column))}
            >
              {label}
            </th>
          );
        })}
      </tr>
    </thead>
  );
}
