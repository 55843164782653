import { useMemo } from "react";
import type { SingleDesignation } from "../../typings/applicationTypes/SingleDesignation";
import { useDesignationsByGeometry } from "./useDesignationsByGeometry";

export function byCoverageDesc(a: SingleDesignation, b: SingleDesignation) {
  return (b.category_coverage_area ?? 0) - (a.category_coverage_area ?? 0);
}

export function useDesignationWithLargestOverlapByGeometry(
  props: Parameters<typeof useDesignationsByGeometry>[0]
) {
  const { designations, ...rest } = useDesignationsByGeometry(props);

  const designation = useMemo(() => {
    if (!designations.length) return null;
    return designations.slice().sort(byCoverageDesc).shift()!;
  }, [designations]);

  return { designation, ...rest };
}
