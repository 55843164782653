import { LayerType } from "../../types";
import { PlanningAuthorityControlPage } from "./ControlPage";
import {
  PLANNING_AUTHORITY_LAYER_APPROVAL_RATE_SUMMARY_TYPE_ID,
  PLANNING_AUTHORITY_LAYER_PIF_SUMMARY_TYPE_ID,
  PLANNING_AUTHORITY_LAYER_TYPE_ID,
} from "./constants";
import { PlanningAuthorityLayerTypeProvider } from "./PlanningAuthorityContext";
import { PlanningAuthorityMapLayer } from "./PlanningAuthorityMapLayer";
import { PlanningAuthoritySelectionSidebar } from "./PlanningAuthoritySelectionSidebar";
import { planningAuthorityClickResolver } from "./planningAuthorityClickResolver";
import { PlanningAuthorityLayerPifSummary } from "./PlanningAuthorityLayerPifSummary";
import { PlanningAuthorityLayerApprovalRateSummary } from "./PlanningAuthorityLayerApprovalRateSummary";

export const PlanningAuthorityLayerType: LayerType = {
  id: PLANNING_AUTHORITY_LAYER_TYPE_ID,
  Provider: PlanningAuthorityLayerTypeProvider,
  MapLayer: PlanningAuthorityMapLayer,
  ControlPage: PlanningAuthorityControlPage,
  SelectionSidebar: PlanningAuthoritySelectionSidebar,
  clickResolver: planningAuthorityClickResolver,
};

export const PlanningAuthorityLayerApprovalRateSummaryType: LayerType = {
  id: PLANNING_AUTHORITY_LAYER_APPROVAL_RATE_SUMMARY_TYPE_ID,
  LayerSummary: PlanningAuthorityLayerApprovalRateSummary,
};

export const PlanningAuthorityLayerPifSummaryType: LayerType = {
  id: PLANNING_AUTHORITY_LAYER_PIF_SUMMARY_TYPE_ID,
  LayerSummary: PlanningAuthorityLayerPifSummary,
};
