import { QueryHookOptions, useQuery } from "@apollo/client";

import {
  LandTechEndpoints,
  routedClient,
} from "react-migration/lib/persistence/apollo/routedClient";

import GET_ACCOUNT from "../apollo/queries/account.gql";
import { AccountResponse } from "../types/Account";

export const getAccount = async () => {
  const res = await routedClient.query({
    query: GET_ACCOUNT,
    context: {
      endpoint: LandTechEndpoints.PrivateClient,
    },
  });

  return res.data;
};

export const useAccountQuery = (opts?: Partial<QueryHookOptions>) => {
  return useQuery<AccountResponse>(GET_ACCOUNT, {
    client: routedClient,
    fetchPolicy: "network-only",
    context: {
      endpoint: LandTechEndpoints.PrivateClient,
    },
    ...opts,
  });
};
