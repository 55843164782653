import clsx from "clsx";
import { Tag, TagColor } from "react-migration/components/Tag";
import { DataPanel } from "react-migration/components/DataPanel";
import { SingleDesignation } from "react-migration/domains/constraints/typings/applicationTypes/SingleDesignation";
import { protectedSmallDateFormatter } from "react-migration/domains/planning/utils/protectedDateFormatter";

function getAttribute(designation: SingleDesignation, key: string) {
  return designation.designation_attributes.find((attr) => attr.key === key)?.value ?? "";
}

export interface EnvironmentPermitDetailProps {
  onTitleClick?: (application: SingleDesignation) => void;
  application: SingleDesignation;
}

const map = {
  environmental_permits_approved: TagColor.GREEN,
  environmental_permits_pending: TagColor.ORANGE,
  environmental_permits_withdrawn: TagColor.ORANGE,
  environmental_permits_rejected: TagColor.RED,
  environmental_permits_unknown: TagColor.DEFAULT,
};

export function EnvironmentPermitDetail({
  onTitleClick,
  application,
}: EnvironmentPermitDetailProps) {
  const ref = getAttribute(application, "application_ref_#");
  const title = application.display_name;
  const date_received = getAttribute(application, "application_submission_date");

  const dateDisplay = protectedSmallDateFormatter(date_received);

  const statusId = application.sub_category!.id as keyof typeof map;
  const statusDisplayName = application.sub_category?.display_name;
  const statusColor = map[statusId];

  return (
    <article
      className="atlas-bg-white atlas-border atlas-border-border-action atlas-rounded-xl atlas-p-4 atlas-flex atlas-flex-col atlas-cursor-default"
      data-testid="planning-application-detail-mini-card"
    >
      <div className="atlas-flex atlas-flex-row atlas-gap-x-2">
        <h1
          onClick={() => onTitleClick?.(application)}
          className={clsx(
            "atlas-text-sm atlas-font-medium atlas-text-text-base-active atlas-truncate",
            {
              "hover:atlas-text-text-base-active-hover atlas-cursor-pointer": !!onTitleClick,
            }
          )}
        >
          {ref}
        </h1>
      </div>
      <div className="atlas-mt-1">
        <p
          title={title}
          className="atlas-text-sm atlas-font-normal atlas-text-black atlas-line-clamp-2 atlas-h-10"
        >
          {title}
        </p>
      </div>
      <DataPanel.Grid columns={2}>
        <DataPanel.Cell title="Status">
          <Tag color={statusColor} title={`status: ${statusDisplayName}`} tag={statusDisplayName} />
        </DataPanel.Cell>
        <DataPanel.Cell title="Submission date">
          <span
            title={`date received: ${dateDisplay}`}
            className="atlas-line-clamp-1 atlas-text-sm"
          >
            {dateDisplay}
          </span>
        </DataPanel.Cell>
      </DataPanel.Grid>
    </article>
  );
}
