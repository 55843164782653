import { useMemo } from "react";
import { userStore } from "src/js/stores/user/store";
import { useSnapshot } from "valtio";
import * as Tabs from "../map/Multilayer/Bundle/Workbench/AssessmentTab/layersByTab";
import Feature from "src/js/stores/user/Feature";
import { Layer } from "../map/Multilayer/types";
import { AssessmentLayerTabUK } from "../map/Multilayer/Bundle/Workbench/AssessmentTab/types";
import {
  UK_ENVIRONMENTAL_SITE_REPORT_LAYERS,
  UK_POLICY_SITE_REPORT_LAYERS,
} from "../map/Multilayer/layers/significantConstraintsLayer";

const EXPANDABLE_TABS = ["details"];

export type AssessmentTab = {
  key: string;
  expandable: boolean;
  layers: Layer[];
};
export type AssessmentTabs = AssessmentTab[];

const SITE_REPORT_LAYERS_BY_TAB = {
  US: Tabs.US_ASSESSMENT_LAYER_BY_TAB,
  UK: {
    ...Tabs.UK_ASSESSMENT_LAYER_BY_TAB,
    [AssessmentLayerTabUK.POLICIES]: [
      // The primary significant constraint layer is hidden from report via
      // `excludeFromReport` config & broken into small layers.
      ...UK_POLICY_SITE_REPORT_LAYERS,
      ...Tabs.UK_ASSESSMENT_LAYER_BY_TAB[AssessmentLayerTabUK.POLICIES],
    ],
    [AssessmentLayerTabUK.ENVIRONMENTAL]: [
      ...UK_ENVIRONMENTAL_SITE_REPORT_LAYERS,
      ...Tabs.UK_ASSESSMENT_LAYER_BY_TAB[AssessmentLayerTabUK.ENVIRONMENTAL],
    ],
  },
};

export function useAssessmentTabs(): AssessmentTabs {
  const { features } = useSnapshot(userStore.user);

  return useMemo(() => {
    const tabs = SITE_REPORT_LAYERS_BY_TAB[features.includes(Feature.usAccess) ? "US" : "UK"];

    const result: AssessmentTabs = Object.entries(tabs)
      .map(([key, layers]) => ({
        key,
        expandable: EXPANDABLE_TABS.includes(key),
        layers: layers.filter((layer) => layer.layerType.Printable && !layer.excludeFromReport),
      }))
      .filter(({ layers }) => !!layers.length);

    return result;
  }, [features]);
}
