import Feature from "src/js/stores/user/Feature";
import { OwnershipLayerType, OwnershipPlotSizeLayerType } from "../layer_types/OwnershipLayerType";
import { Layer } from "../types";

export const OWNERSHIP_LAYER_ID = "ownership";

export const OWNERSHIP_LAYER: Layer = {
  id: OWNERSHIP_LAYER_ID,
  layerType: OwnershipLayerType,
  title: "Ownership Boundaries",
  toggleable: true,
  requiredFeatureAccess: [Feature.ownershipLayer],
  description: "Ownership boundaries",
  layerConfig: {
    minZoom: 13,
  },
};

export const OWNERSHIP_AREA_PERIMETER_SUMMARY_LAYER: Layer = {
  id: `${OWNERSHIP_LAYER_ID}-plot-size-summary`,
  layerType: OwnershipPlotSizeLayerType,
  title: "Plot size",
  requiredFeatureAccess: [Feature.insightsSummary],
  requiredBetaAccess: [Feature.insightsSummaryBeta],
  description: "",
  hideMapLayer: true,
  toggleable: true,
};
