import { useMemo } from "react";
import { useDesignationsByGeometry } from "./useDesignationsByGeometry";
import { SingleDesignation } from "../../typings/applicationTypes/SingleDesignation";

export function useLargestDesignationByGeometry(
  opts: Parameters<typeof useDesignationsByGeometry>[0]
) {
  const { designations, ...rest } = useDesignationsByGeometry({ ...opts, includeArea: true });

  const selectedDesignation = useMemo<SingleDesignation | null>(() => {
    if (!designations?.length) {
      return null;
    }

    if (designations.length === 1) {
      return designations[0];
    }

    return designations?.reduce((max, obj) =>
      obj.category_coverage_percentage! > max.category_coverage_percentage! ? obj : max
    );
  }, [designations]);

  return { designation: selectedDesignation, ...rest };
}
