import { RecordType } from "react-migration/domains/comparables/typings/Record";
import { Translation } from "react-migration/lib/typings";
import { formattedArea } from "react-migration/lib/util/measurements";
import { MeasurementSystem } from "src/js/stores/user/store";

export interface FloorAreaTitleProps {
  t: Translation;
}
export const floorAreaTitle = ({ t }: FloorAreaTitleProps) =>
  t("comparables.map.sidebar.value.record.area.title");

export interface FloorAreaTooltipProps {
  t: Translation;
  record: RecordType;
}
export const floorAreaTooltip = ({ t, record }: FloorAreaTooltipProps) =>
  !record.num_bedrooms && record.est_bedrooms && record.est_bedrooms.length > 0
    ? t("comparables.map.sidebar.value.record.area.tooltip")
    : undefined;

export interface FloorAreaValueProps {
  t: Translation;
  record: RecordType;
  unitPreference: MeasurementSystem;
}

export const floorAreaValue = ({ t, record, unitPreference }: FloorAreaValueProps) => {
  if (!record.total_floor_area) {
    return undefined;
  }
  return formattedArea({ area: record.total_floor_area || 0, unitPreference, t });
};
